import useApi from "utils/Api";
import { Centered } from "components/DesignComponents";
import useAsyncData from "utils/NetworkUtils";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { UserGroupIcon as MyCandidatesIcon } from "@heroicons/react/24/outline";
import { formatDayMonth } from "utils/DateUtils";
import { CandidateStatusBadge } from "components/RatingComponent";
import { useDynamicQuery } from "utils/NetworkUtils";
import { HandleHTTPErrors2 } from "components/ErrorMessages";
import { JobListing } from "apps/ai-debate-trainer/JobListing";

// // Component to create individual cards for each application entry
// function ApplicationCard({ application, setRefreshCallback }) {
//   const { created_at, country, city, job_title, candidate_status, job_uuid } =
//     application;
//
//   // Format the date with moment
//   const createdAtFormatted = formatDayMonth(created_at);
//
//   // Return a card layout for each application
//   return (
//     <Link
//       className="h-full"
//       to={`/apps/ai-recruiter/candidate/view-job/${job_uuid}`}
//     >
//       <div className="h-full hover:bg-dark-300 border border-dark-300 shadow-lg p-5 my-3">
//         <div className="flex items-center justify-between mb-3">
//           <h3 className="font-bold text-lg text-center w-full">{job_title}</h3>
//         </div>
//         <span className="text-light-300">
//           {city} {city && "."} {country}
//         </span>
//         <div className="flex flex-row my-2 text-light-300">
//           <MyCandidatesIcon className="inline-block w-5 h-5 mr-2" />
//           <div className="text-sm text-light-300">{createdAtFormatted}</div>
//         </div>
//         <CandidateStatusBadge status={candidate_status} />
//       </div>
//     </Link>
//   );
// }

// export default function MyApplications() {
//   const [refresh, setRefresh] = useState(false);
//   const { apiAIRecruiterCandidateApplications } = useApi();
//   const data = useAsyncData(apiAIRecruiterCandidateApplications, refresh);
//
//   // This function is passed down to the ApplicationCard to trigger a refresh
//   const handleRefresh = () => {
//     setRefresh((prev) => !prev);
//   };
//
//   if (!data) {
//     return <div>Loading...</div>;
//   }
//   return (
//     <Centered className="container mx-auto">
//       <div className="grid grid-cols-1 md:grid-cols-3 gap-3 items-center justify-center">
//         {data.map((application, index) => (
//           <ApplicationCard
//             key={application.uuid}
//             application={application}
//             index={index}
//             setRefreshCallback={handleRefresh}
//           />
//         ))}
//       </div>
//     </Centered>
//   );
// }

export default function MyApplications() {
  const { apiAIRecruiterCandidateApplications } = useApi();

  const requestsState = useDynamicQuery(
    "candidate-my-applications",
    apiAIRecruiterCandidateApplications
  );
  const data = requestsState.data?.candidates;

  return (
    <Centered className="pb-4">
      <HandleHTTPErrors2 {...requestsState}>
        <JobListing
          jobs={data}
          to={(candidate) =>
            `/apps/ai-recruiter/candidate/view-job/${candidate.job_uuid}`
          }
          isCandidateView
        />
      </HandleHTTPErrors2>
    </Centered>
  );
}
