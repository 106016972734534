import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import App from "./App";
// import { createTheme } from "@mui/material/styles";
// import { ThemeProvider } from "@mui/material/styles";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#120d30", // Replace with your desired primary color
//     },
//     secondary: {
//       main: "#ddd", // Replace with your desired primary color
//     },
//   },
//   typography: {
//     fontFamily: "Merriweather, serif",
//   },
// });

// React.StrictMode is a debug tool.
// React Strict Mode is automatically disabled for production builds.
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   // <ThemeProvider theme={theme}>
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   // </ThemeProvider>
// );
