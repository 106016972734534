export function ToggleButton({ isEnabled, onToggle, label }) {
  return (
    <div className="flex items-center justify-center">
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            className="sr-only"
            checked={isEnabled}
            onChange={onToggle}
          />
          <div
            className={`block w-14 h-8 rounded-full transition-colors ${
              isEnabled ? "bg-green-500" : "bg-dark-300"
            }`}
          ></div>
          <div
            className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition transform ${
              isEnabled ? "translate-x-6" : ""
            }`}
          ></div>
        </div>
        {label && (
          <span className="ml-3 text-light-300 font-medium">{label}</span>
        )}
      </label>
    </div>
  );
}
