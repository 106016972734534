import { useCallback, useContext, useEffect, useState } from "react";
import {
  DesignedLink,
  FullButton,
  FullButtonLink,
  Input,
  Title,
} from "../components/DesignComponents";
import useApi from "../utils/Api";
import ErrorMessages from "../components/ErrorMessages";
import { GameContext } from "../debate/GameContext";
import { useLocation } from "react-router-dom";

const Form = ({ handleSubmit, tmpEmail, setTmpEmail }) => (
  <form className="grid justify-items-center p-4 m-4" onSubmit={handleSubmit}>
    {/* PUT LOGO HERE */}
    <br />
    <Input
      label="Email"
      placeholder="Enter your email"
      value={tmpEmail}
      onChange={setTmpEmail}
    />
    <FullButton type="submit" propagate>
      Login
    </FullButton>
    <DesignedLink to="/register">Register</DesignedLink>
  </form>
);

function SuccessMessage() {
  return (
    <div>
      Your login request was successful !
      <br />
      Please check your emails.
    </div>
  );
}

export function Login() {
  const { email, setEmail } = useContext(GameContext);
  const { apiLoginUser } = useApi();
  const [requestResponse, setRequestResponse] = useState(null);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (email) {
        console.log("Login email", email);
        const { status, message } = await apiLoginUser(email);
        setRequestResponse({ status, message });
      }
    },
    [email, setRequestResponse]
  );

  return (
    <div className="flex items-center justify-center min-h-[50vh]">
      <div className="border p-8">
        <Title className="pb-4">
          <h2>Login</h2>
        </Title>
        <ErrorMessages
          requestResponse={requestResponse}
          successMessage={<SuccessMessage />}
        >
          <Form
            tmpEmail={email}
            setTmpEmail={setEmail}
            handleSubmit={handleSubmit}
          />
        </ErrorMessages>
      </div>
    </div>
  );
}

export function Logout() {
  const { disconnect } = useContext(GameContext);

  useEffect(() => {
    disconnect();
  }, [disconnect]);

  return (
    <div className="font-bold text-center s-text-2xl text-green-500">
      Successfully logged out.
    </div>
  );
}

export function SuccessMessageDesign({ children, ...props }) {
  return (
    <div className="font-bold text-center s-text-2xl text-green-500" {...props}>
      {children}
    </div>
  );
}

export function ErrorMessageDesign({ children, ...props }) {
  return (
    <div className="font-bold text-center s-text-2xl text-red-500" {...props}>
      {children}
    </div>
  );
}

const StatusEnum = {
  INIT: "Init.",
  NO_EMAIL: "No email found.",
  PENDING: "Pending.",
  IS_GUEST_USER: "User created.",
  USER_LOGGED_IN: "Already logged in.",
  WAIT_USER_AUTH: "Login email sent.",
};

export function AuthRequired({ children, ...props }) {
  const location = useLocation();
  const redirect_url = location.pathname + location.search + location.hash;
  const { apiLoginOrRegisterUser } = useApi();
  const [authResult, setAuthResult] = useState(StatusEnum.INIT);
  const { setAuthToken, email, saveEmail, setEmail } = useContext(GameContext);
  const [requestResponse, setRequestResponse] = useState(null);

  useEffect(() => {
    if (email != "") {
      handleSubmit();
    }
  }, [email]);

  const handleSubmit = useCallback(
    async (event) => {
      if (event) {
        event.preventDefault();
      }
      saveEmail();
      if (!email) {
        setAuthResult(StatusEnum.NO_EMAIL);
        return;
      }
      const { status, message, ...response } = await apiLoginOrRegisterUser(
        email,
        redirect_url
      );
      setRequestResponse({ status, message });
      if (status === "ok") {
        setAuthResult(message);
        if (message === StatusEnum.IS_GUEST_USER) {
          setAuthToken(response.auth_token);
        }
      }
    },
    [email, redirect_url, setAuthToken, saveEmail, setEmail]
  );

  return (
    <div {...props}>
      {authResult === StatusEnum.INIT && (
        <div>
          <form className="grid justify-items-center" onSubmit={handleSubmit}>
            <Input
              label="Email"
              name="email"
              value={email}
              onChange={setEmail}
              required
            />
            No password needed.
            <FullButton type="submit" propagate>
              Login
            </FullButton>
          </form>
        </div>
      )}
      {authResult === StatusEnum.PENDING && <div>Loading...</div>}
      {authResult === StatusEnum.NO_EMAIL && (
        <ErrorMessageDesign>
          CRITICAL: No email found.
          <br />
          This is unexpected in AuthRequired!!
        </ErrorMessageDesign>
      )}
      {(authResult === StatusEnum.IS_GUEST_USER ||
        authResult === StatusEnum.USER_LOGGED_IN) && <div>{children}</div>}
      {authResult === StatusEnum.WAIT_USER_AUTH && (
        <div className="font-bold text-center s-text-2xl text-green-500 grid justify-items-center">
          <div>
            Authentication email sent to '{email}'. <br />
            Please check your emails to continue.
          </div>
          <DesignedLink to="/login">Change user</DesignedLink>
        </div>
      )}
      <ErrorMessages
        requestResponse={requestResponse}
        successMessage=""
      ></ErrorMessages>
    </div>
  );
}

// <div className="font-bold text-center s-text-2xl text-green-500">
//   {authResult}
// </div>
