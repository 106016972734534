import React, { createContext, useContext } from "react";
import { useLocalStorageObject } from "utils/StorageUtils";
// import { useSignal } from "@preact/signals-react";

// -----------------------------------------------
//               Viral Tiktok context
// -----------------------------------------------
const ViralTikTokContext = createContext();

export function useViralTikTokContext() {
  return useContext(ViralTikTokContext);
}

export const ViralTikTokContextProvider = (props) => {
  const [backgroundVideo, setBackgroundVideo] =
    useLocalStorageObject("background_video");

  return (
    <ViralTikTokContext.Provider
      value={{
        backgroundVideo,
        setBackgroundVideo,
      }}
    >
      {props.children}
    </ViralTikTokContext.Provider>
  );
};
