import { Link } from "react-router-dom";
import { twJoin, twMerge } from "tailwind-merge";
import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PhoneInput as ReactPhoneInput } from "react-international-phone";

// <td className="px-6 py-3 whitespace-no-wrap" {...props}>
export function MyTD({ children, ...props }) {
  return (
    <td className="px-6 py-1 whitespace-no-wrap" {...props}>
      {children}
    </td>
  );
}

export function Table({
  className,
  props,
  headers,
  data,
  EntryViewer,
  keyName,
}) {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 ${className}`}
      {...props}
    >
      <thead>
        <tr>
          {headers.map((entry, index) => (
            <th
              key={entry}
              className="px-6 py-3 bg-secondary text-left leading-4 font-bold text-primary uppercase tracking-wider"
            >
              {entry}
            </th>
          ))}
        </tr>
      </thead>

      <tbody className="divide-y divide-gray-200">
        {data &&
          data.map((entry, index) => (
            <EntryViewer key={keyName(entry)} index={index + 1} entry={entry} />
          ))}
      </tbody>
    </table>
  );
}

const TitleDesign =
  "text-white px-4 max-w-7xl mx-auto font-black flex justify-center mb-4 md:mb-8";

export const Title1 = ({ children, className = "", ...props }) => {
  return (
    <div className={twMerge(TitleDesign, "s-text-3xl", className)} {...props}>
      {children}
    </div>
  );
};

export const Title = Title1;

export const Title2 = ({ children, className, ...props }) => {
  return (
    <div
      className={twMerge(TitleDesign, "s-text-lg md:text-3xl", className)}
      {...props}
    >
      {children}
    </div>
  );
};

export const Title3 = ({ children, className, ...props }) => {
  return (
    <div className={twMerge(TitleDesign, "s-text-xl", className)} {...props}>
      {children}
    </div>
  );
};

export const Title4 = ({ children, className, ...props }) => {
  return (
    <div className={twMerge(TitleDesign, "s-text-lg", className)} {...props}>
      {children}
    </div>
  );
};

export const Logo = ({ className, ...props }) => {
  return (
    <span id="logo" className={`font-bold ${className}`} {...props}>
      AI Cartel
    </span>
  );
};

// className={`text-4xl sm:text-7xl font-bold ${className}`}

export const Tile = ({ title, children, to, className, ...props }) => {
  const color = to !== "/coming-soon" ? "bg-primary" : "bg-primary_light";
  return (
    <Link
      className={`${color} p-4 m-2 rounded-none shadow-md border-2 hover:bg-secondary hover:text-primary border-secondary ${className}`}
      to={to}
      {...props}
    >
      <h2 className="s-text-xl font-semibold mb-2 flex justify-center">
        {title}
      </h2>
      <p className="s-text-base">{children}</p>
    </Link>
  );
};

export const CenteredIntroductionText = ({ children, className, ...props }) => {
  return (
    <p
      className={`text-last max-w-7xl mx-auto s-text-xl pb-5 ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};

export const Centered = ({ children, className, ...props }) => {
  return (
    <div className={`px-4 max-w-7xl mx-auto ${className}`} {...props}>
      {children}
    </div>
  );
};

export const TextArea = ({
  label,
  placeholder,
  value,
  onChange,
  onBlur = undefined,
  ...props
}) => {
  function handleChange(event) {
    onChange(event.target.value);
  }

  // <div className="relative border-red-600 border-4">
  //   <div className="absolute bg-blue-200 p-4">Content 1</div>
  //   <div className="absolute bg-green-200 p-4 top-10">Content 2</div>
  // </div>

  function onUnfocus(event) {
    onChange(event.target.value);
    onBlur && onBlur(event.target.value);
  }

  return (
    <div className="relative mx-auto w-full max-w-5xl">
      <label className="absolute bg-light rounded px-1 text-md font-bold block text-gray-800 top-0 left-2">
        {label}
      </label>
      <div className="mt-1">
        <textarea
          type="text"
          placeholder={placeholder}
          className="border border-light focus:outline-blue-400 rounded w-full h-64 h-auto p-3 pt-7 s-text-sm text-black resize-none"
          value={value}
          onChange={handleChange}
          onBlur={onUnfocus}
          {...props}
        />
      </div>
    </div>
  );
};

export const Input = ({
  label,
  placeholder,
  value,
  onChange,
  onBlur = undefined,
  ...props
}) => {
  function handleChange(event) {
    var value = event.target.value;
    onChange(value);
  }
  function onUnfocus(event) {
    var value = event.target.value;
    onChange(value);
    onBlur && onBlur(value);
  }

  // <div className="relative mx-auto w-full max-w-xs m-3">
  return (
    <div className="relative m-3 w-full max-w-xs">
      <label className="absolute bg-light rounded px-1 text-md font-bold block text-gray-800 bottom-7 left-2">
        {label}
      </label>
      <div className="mt-1">
        <input
          placeholder={placeholder}
          className="border border-light focus:outline-blue-400 rounded w-full h-10 p-3 pt-5 text-sm text-black"
          value={value}
          onChange={handleChange}
          onBlur={onUnfocus}
          {...props}
        />
      </div>
    </div>
  );
};

export const PhoneInput = (props) => {
  return (
    <div className="relative m-3 w-full max-w-xs">
      <label className="absolute bg-light rounded px-1 text-md font-bold block text-gray-800 bottom-7 left-2 z-50">
        Phone
      </label>
      <div className="mt-1">
        <ReactPhoneInput {...props} />
      </div>
    </div>
  );
};

const FullButtonDesign =
  "p-1 m-1 md:p-3 md:m-3 s-text-sd bg-secondary text-primary hover:text-secondary hover:bg-primary border inline-flex items-center border-dark-300 font-bold";

// const BorderButtonDesign =
//   "font-extrabold border-secondary border-2 hover:text-primary hover:bg-secondary text-xl p-2 m-1 text-center w-full inline-flex items-center";
const BorderButtonDesign =
  "p-1 m-1 md:p-3 md:m-3 s-text-sd bg-primary text-secondary hover:text-primary hover:bg-secondary border inline-flex items-center";

function customButtonOnClick(
  event,
  propagate,
  isDisabled,
  setIsDisabled,
  onClick
) {
  if (!propagate) {
    event.preventDefault();
    event.stopPropagation();
  }
  if (isDisabled) {
    toast.warn("isDisabled");
    return;
  }
  setIsDisabled(true);
  onClick(event);
  setIsDisabled(false);
}

export function FullButton({
  className,
  propagate = false,
  onClick = () => {},
  children,
  ...props
}) {
  const realClassName = twMerge(FullButtonDesign, className ?? "");
  const [isDisabled, setIsDisabled] = useState(false);

  const realOnClick = useCallback(
    (event) =>
      customButtonOnClick(event, propagate, isDisabled, setIsDisabled, onClick),
    [onClick, propagate, isDisabled, setIsDisabled]
  );

  return (
    <button
      className={realClassName}
      onClick={realOnClick}
      {...props}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}

export function BorderButton({
  className,
  propagate = false,
  onClick = () => {},
  children,
  ...props
}) {
  const realClassName = `${BorderButtonDesign} ${className ?? ""}`;
  const [isDisabled, setIsDisabled] = useState(false);
  const realOnClick = useCallback(
    (event) =>
      customButtonOnClick(event, propagate, isDisabled, setIsDisabled, onClick),
    [onClick, propagate, isDisabled, setIsDisabled]
  );

  return (
    <button className={realClassName} onClick={realOnClick} {...props}>
      {children}
    </button>
  );
}

// <button className="h-full w-full border border-red-600">
export function FullButtonLink({ className, to, children, ...props }) {
  return (
    <Link to={to} {...props} className={twMerge(FullButtonDesign, className)}>
      {children}
    </Link>
  );
}

export function BorderButtonLink({ className, to, children, ...props }) {
  return (
    <Link to={to} {...props} className={twMerge(BorderButtonDesign, className)}>
      {children}
    </Link>
  );
}

export function Section({ children, className, ...props }) {
  return (
    <section
      className={`border-t border-gray-800 py-4 md:py-10 ${className}`}
      {...props}
    >
      {children}
    </section>
  );
}

// export function BottomBanner({ children }) {
//   return (
//     <div className="fixed bottom-0 left-0 z-50 flex justify-between w-full p-4 border-t border-light-300 bg-dark-100">
//       <div className="flex items-center mx-auto">{children}</div>
//     </div>
//   );
// }
//
// useEffect(() => {
//   const handleScroll = () => {
//     // Check if user has scrolled more than 50% of the first screen
//     if (window.scrollY > window.innerHeight / 8) {
//       setIsVisible(true);
//     } else {
//       setIsVisible(false);
//     }
//   };
//
//   window.addEventListener("scroll", handleScroll);
//
//   // Cleanup the event listener when the component is unmounted
//   return () => {
//     window.removeEventListener("scroll", handleScroll);
//   };
// }, []);

export function BottomBanner({ neighborID, children }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const neighbor = document.getElementById(neighborID);
      if (neighbor) {
        const rect = neighbor.getBoundingClientRect();
        if (rect.bottom < 0 || rect.top > window.innerHeight) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!isVisible) {
    return null; // Don't render the banner if it's not visible
  }

  return (
    <div className="fixed bottom-0 left-0 z-50 flex justify-between w-full p-4 bg-cta">
      <div className="flex items-center mx-auto">{children}</div>
    </div>
  );
}

export function Accordeon({ data }) {
  const [openIndex, setOpenIndex] = useState(null);

  const toggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      {data.map((item, index) => (
        <div
          key={index}
          className="bg-dark-200 my-2 shadow-lg text-light-100 max-w-screen-md mx-auto overflow-hidden"
        >
          <h3
            onClick={() => toggle(index)}
            className="flex flex-row justify-between items-center font-semibold p-3 cursor-pointer"
          >
            {item.question}
            <span>{openIndex === index ? "-" : "+"}</span>
          </h3>
          {openIndex === index && (
            <p className="p-3 text-light-300 bg-dark">{item.answer}</p>
          )}
        </div>
      ))}
    </>
  );
}

export function Checkbox({ value, setValue, label, ...props }) {
  return (
    <label>
      {label}
      <input
        className="mx-10"
        type="checkbox"
        checked={value}
        onChange={(e) => setValue(e.target.checked)}
        {...props}
      />
    </label>
  );
}

export function CheckboxLeft({ value, setValue, label, ...props }) {
  return (
    <label>
      <input
        className="mx-3"
        type="checkbox"
        checked={value}
        onChange={(e) => setValue(e.target.checked)}
        {...props}
      />
      {label}
    </label>
  );
}

export function GoBack() {
  let navigate = useNavigate();

  function goBack() {
    navigate(-1); // This will take you to the previous page
  }

  return <FullButton onClick={goBack}>Back</FullButton>;
}

export function Gradient({ children }) {
  return <span className="text-color-primary-gradient">{children}</span>;
}

export function DesignedLink({ to, children, className = "" }) {
  return (
    <a
      href={to}
      className={twMerge(
        "font-bold text-blue-500 underline hover:text-blue-700 break-all",
        className
      )}
    >
      {children}
    </a>
  );
}
