import React from "react";
import { twMerge } from "tailwind-merge";

export function HeaderShortMessages({ children, className, ...props }) {
  return (
    <div className={twMerge("flex justify-center my-4", className)} {...props}>
      <div className="grid grid-cols-2 py-8 px-2 sm:border-y border-dark-200 sm:max-w-[70%]">
        {children}
      </div>
    </div>
  );
}

export const HeaderShortMessagesItem = ({ title, children }) => (
  <div className="text-left border sm:border-none border-gray-800 p-3 m-1">
    <div className="s-text-lg font-semibold text-secondary">{title}</div>
    <p className="s-text-sm mt-2 text-light-300">{children}</p>
  </div>
);

export function HeaderDesign({ children, ...props }) {
  return (
    <div className="relative mx-2 flex justify-center" id="home" {...props}>
      <div
        aria-hidden="true"
        className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
      >
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-500"></div>
      </div>
      <div className="container">
        <div className="relative pt-36 ml-auto">
          <div className="lg:w-2/3 text-center mx-auto">{children}</div>
        </div>
      </div>
    </div>
  );
}

export function HeaderDesignV2({ children, ...props }) {
  return (
    <div className="relative mx-2 flex justify-center" id="home" {...props}>
      <div
        aria-hidden="true"
        className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
      >
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-500"></div>
      </div>
      <div className="container">
        <div className="relative ml-auto">{children}</div>
      </div>
    </div>
  );
}

export function HeroMessage({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const title = childrenArray[0];
  const subtitle = childrenArray[1];

  return (
    <>
      <h1 className="font-bold text-4xl md:text-6xl xl:text-7xl">{title}</h1>
      <p className="mt-8 s-text-lg">{subtitle}</p>
    </>
  );
}
