import { toast } from "react-toastify";
import useApi from "../utils/Api";
import {
  Centered,
  Title,
  MyTD,
  Table,
  FullButton,
} from "../components/DesignComponents";
import useAsyncData from "../utils/NetworkUtils";
import { useState } from "react";
import ConfirmedEmail from "../features/ConfirmedEmail";
import { formatDayMonth } from "../utils/DateUtils";
import { DeleteButton } from "components/IconButtons";
import XS from "components/XS";

function TableEntryWithCallback(setRefreshCallback) {
  const TableEntry = ({ entry, index, ...props }) => {
    const { apiDeleteUser, apiIncreaseUserCredit } = useApi();
    const {
      created_at,
      last_seen,
      mail_confirmed,
      email,
      phone_number,
      referral_code,
      referrer_email,
      language,
    } = entry;

    const doDelete = async () => {
      const { status } = await apiDeleteUser(email);
      if (status === "ok") {
        toast.success(`Successfully deleted ${email}`);
        setRefreshCallback((prev) => !prev);
      }
    };
    const doIncreaseUserCredit = async () => {
      const { status } = await apiIncreaseUserCredit(email);
      if (status === "ok") {
        toast.success(`Successfully increased max user credit`);
        setRefreshCallback((prev) => !prev);
      }
    };

    return (
      <tr {...props}>
        <MyTD>{index}</MyTD>
        <MyTD>
          <span className="text-xs">
            {formatDayMonth(created_at)} - {formatDayMonth(last_seen)}
          </span>
        </MyTD>
        <MyTD>
          {entry.ai_price} / {entry.max_ai_price}
        </MyTD>
        <MyTD>
          <ConfirmedEmail email={email} mail_confirmed={mail_confirmed} />
          <XS k="Phone">{phone_number}</XS>
          <br />
          <XS k="RC">{referral_code}</XS>
          <XS k="RC">{referrer_email}</XS>
        </MyTD>
        <MyTD>{language}</MyTD>
        <MyTD>
          <DeleteButton onClick={doDelete} />
          <FullButton onClick={doIncreaseUserCredit}>+Credit</FullButton>
        </MyTD>
      </tr>
    );
  };
  return TableEntry;
}

export default function AdminUsers() {
  const [refresh, setRefresh] = useState(true);
  const { apiGetAllUsers } = useApi();
  const users = useAsyncData(apiGetAllUsers, refresh);
  const TableEntry = TableEntryWithCallback(setRefresh);

  const headers = ["N.", "Date", "$", "Email", "Language", ""];

  if (!users) {
    return <div>Loading...</div>;
  }
  return (
    <section className="border-t border-gray-800 py-10">
      <Centered>
        <Title>
          <h3>Admin Users</h3>
        </Title>
        <Table
          headers={headers}
          data={users}
          EntryViewer={TableEntry}
          keyName={(x) => x["email"]}
        />
      </Centered>
    </section>
  );
}
