// Put children in success message
// submitSuccess is true when the API call is successful

import PropTypes from "prop-types";
import React from "react";
import { Loading } from "utils/NetworkUtils";

export function ErrorMessageDisplay({ message }) {
  return (
    <pre className="text-red-500 border p-1 overflow-x-auto whitespace-pre-wrap">
      {message}
    </pre>
  );
}

// errorMessage is the error message from the API call
// First parameter is the success message
// Second parameter is the default display
export default function ErrorMessages({
  requestResponse,
  successMessage,
  children,
}) {
  // const childrenArray = React.Children.toArray(children);
  // const childrenArray = React.Children.toArray(children);
  return (
    <>
      <div className="font-bold text-center s-text-2xl">
        {requestResponse && requestResponse.status === "ok" && (
          <div className="text-green-500">{successMessage}</div>
        )}
        {requestResponse &&
          requestResponse.status !== "ok" &&
          requestResponse.message !== undefined && (
            <pre className="text-red-500 border p-1 overflow-x-auto whitespace-pre-wrap">
              {requestResponse.message}
            </pre>
          )}
      </div>
      {(requestResponse && requestResponse.status === "ok") || children}
    </>
  );
}

export function HandleHTTPErrors({
  loadingMessage = "Loading...",
  children,
  isLoading,
  isError,
  error,
}) {
  if (isLoading) return <Loading loadingMessage={loadingMessage} />;

  if (isError) {
    return (
      <pre className="font-bold text-center text-red-500 p-1 border border-dotted">
        {error.message}
      </pre>
    );
  }
  return <>{children}</>;
}

export function HandleHTTPErrors2({
  data,
  children,
  loadingMessage = "Loading...",
  ...props
}) {
  return (
    <HandleHTTPErrors loadingMessage={loadingMessage} {...props}>
      {data && <>{children}</>}
    </HandleHTTPErrors>
  );
}

// HandleHTTPErrors.propTypes = {
//   children: PropTypes.node,
//   isLoading: PropTypes.bool,
//   isError: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
// };
