import {
  Centered,
  FullButton,
  Title,
  Title2,
  Title3,
  Logo,
  Title1,
} from "./DesignComponents";

import { ArrowLeftOnRectangleIcon as LoginIcon } from "@heroicons/react/24/outline";
import { MicrophoneIcon } from "@heroicons/react/24/outline";

const colorMap = [
  "dark",
  "dark-200",
  "dark-300",
  "light",
  "light-200",
  "light-100",
];

const unused = [
  "dark",
  "dark-100",
  "dark-200",
  "dark-300",
  "light",
  "light-100",
  "light-200",
  "light-100",
];

const unused2 = [
  "text-xs text-sm text-base text-lg text-xl text-2xl text-3xl text-4xl text-5xl text-6xl text-7xl text-8xl text-9xl",
  "md:text-xs md:text-sm md:text-base md:text-lg md:text-xl md:text-2xl md:text-3xl md:text-4xl md:text-5xl md:text-6xl md:text-7xl md:text-8xl md:text-9xl",
];

function ColoredDiv({ fg, bg }) {
  return (
    <div className={`bg-${bg} text-${fg} border-2 border-${fg}`}>
      text-{fg} <div className="text-2xl font-bold">O</div>
    </div>
  );
}

export function Bold({ children }) {
  return <span className="font-bold">{children}</span>;
}

function TextSize() {
  return (
    <div className="border-8">
      <Title>Text Size</Title>
      <div className="text-xs">text-xs</div>
      <div className="text-sm">text-sm</div>
      <div className="text-base">text-base</div>
      <div className="text-lg">text-lg</div>
      <div className="text-xl">text-xl</div>
      <div className="text-2xl">text-2xl</div>
      <div className="text-3xl">text-3xl</div>
      <div className="text-4xl">text-4xl</div>
      <div className="text-5xl">text-5xl</div>
      <div className="text-6xl">text-6xl</div>
      <div className="text-7xl">text-7xl</div>
      <div className="text-8xl">text-8xl</div>
      <div className="text-9xl">text-9xl</div>
      <div className="s-text-xs">s-text-xs</div>
      <div className="s-text-sm">s-text-sm</div>
      <div className="s-text-base">s-text-base</div>
      <div className="s-text-lg">s-text-lg</div>
      <div className="s-text-xl">s-text-xl</div>
      <div className="s-text-2xl">s-text-2xl</div>
      <div className="s-text-3xl">s-text-3xl</div>
      <div className="s-text-4xl">s-text-4xl</div>
      <div className="s-text-5xl">s-text-5xl</div>
      <div className="s-text-6xl">s-text-6xl</div>
      <div className="s-text-7xl">s-text-7xl</div>
      <div className="s-text-8xl">s-text-8xl</div>
      <div className="s-text-9xl">s-text-9xl</div>
    </div>
  );
}

function Colors() {
  // <div className="font-bold">
  return (
    <div className="border-8">
      <Title>Colors</Title>
      <div className="flex flex-row gap-3">
        <div className="text-dark">text-dark</div>
        <div className="text-dark-200">text-dark-200</div>
        <div className="text-dark-300">text-dark-300</div>
        <div className="text-light-300">text-light-100</div>
        <div className="text-light-200">text-light-200</div>
        <div className="text-light-100">text-light-300</div>
      </div>

      <div className="grid grid-cols-6 place-content-stretch h-30 text-center">
        {colorMap.map((color) => (
          <div key={color} className="font-bold text-xl">
            bg-{color}
          </div>
        ))}
        {colorMap.map((color1) =>
          colorMap.map((color2) => (
            <ColoredDiv key={`${color1} ${color2}`} fg={color1} bg={color2} />
          ))
        )}
      </div>
    </div>
  );
}

function ABC() {
  return (
    <>
      <div className="border text-lg font-bold border-red-600 text-red-600">
        <div className="m-1 border border-dotted border-red-300 max-w-[50%]">
          A
        </div>
      </div>
      <div className="border text-lg font-bold border-green-600 text-green-600">
        <div className="m-1 border border-dotted bborder-green-300 max-w-[50%]">
          BB
        </div>
      </div>
      <div className="border text-lg font-bold border-blue-600 text-blue-600">
        <div className="m-1 border border-dotted border-blue-300 max-w-[50%]">
          CCC
        </div>
      </div>
    </>
  );
}

function ShowGridUtil({ className }) {
  return (
    <div className="border m-4 p-2">
      <Title3 className="mb-2">{className}</Title3>
      <div className={className}>
        <ABC />
      </div>
    </div>
  );
}

function ShowGrid() {
  return (
    <div className="border-8">
      <Title>ShowGrid</Title>
      <ShowGridUtil className="flex grid-3" />
      <ShowGridUtil className="flex grid-3 place-content-center" />
      <ShowGridUtil className="flex justify-center" />
      <ShowGridUtil className="flex grid-3 place-content-center gap-3" />
      <ShowGridUtil className="flex grid-3 justify-evenly" />
      <ShowGridUtil className="grid grid-cols-3" />
      <ShowGridUtil className="grid grid-cols-3 gap-4 place-content-stretch h-20 text-center" />
      <ShowGridUtil className="flex flex-col" />
      <ShowGridUtil className="flex flex-col" />
      <ShowGridUtil className="flex flex-col text-center" />
      // <ShowGridUtil className="" />
      <ShowGridUtil className="grid justify-items-center" />
      <ShowGridUtil className="flex items-center min-h-screen" />
      <ShowGridUtil className="flex items-center justify-center min-h-screen" />
      <ShowGridUtil className="flex items-center justify-center min-h-[50vh]" />
      <div className="relative overflow-auto">
        <div className="absolute bg-blue-200 p-4">Content 1</div>
        <div className="absolute bg-green-200 p-4">Content 2</div>
      </div>
    </div>
  );
}

function Buttons() {
  return (
    <>
      <div className="border m-4 p-2">
        <Title3>FullButton</Title3>
        <FullButton className="inline-flex items-center">
          <span className="sr-only">Login</span>
          <LoginIcon className="h-6 w-6" aria-hidden="true" /> Login
        </FullButton>
      </div>
      <div className="border m-4 p-2">
        <Title3>
          w-16 h-16 bg-dark-300 rounded-full hover:bg-light-100
          hover:text-dark-300 flex items-center justify-center
        </Title3>
        <div className="w-16 h-16 bg-dark-300 rounded-full hover:bg-light-100 hover:text-dark-300 flex items-center justify-center">
          <MicrophoneIcon className="block h-12 w-12" aria-hidden="true" />
        </div>
      </div>
    </>
  );
}
export default function DeisgnHelper() {
  return (
    <Centered>
      <Title className="text-red-600">Debug Design Helper</Title>
      <Buttons />
      <Colors />
      <ShowGrid />
      <TextSize />
    </Centered>
  );
}
