// vim: set noautoindent nosmartindent formatoptions=croql :
// https://webflow.com/made-in-webflow/website/social-media-links-package

import { Link } from "react-router-dom";
import { Centered, Title2 } from "../components/DesignComponents";

function SocialNetworkIcon({ href, src, alt, ...props }) {
  return (
    <a href={href}>
      <img className="w-24" src={src} alt={alt} {...props} />
    </a>
  );
}

export function SocialNetworkIconWithText({ href, src, alt, text }) {
  return (
    <a href={href} className="inline-flex items-center md:p-2 md:m-1">
      {text} <img className="h-7 w-7" src={src} alt={alt} />
    </a>
  );
}

export function DiscordWithText({ text }) {
  return (
    <SocialNetworkIconWithText
      href="https://discord.gg/TBmbD3AP7n"
      src="/assets/social_network_icons/discord.svg"
      alt="Discord"
      text={text}
    />
  );
}

function Discord(props) {
  return (
    <SocialNetworkIcon
      {...props}
      href="https://discord.gg/TBmbD3AP7n"
      src="/assets/social_network_icons/discord.svg"
      alt="Discord"
    />
  );
}

function Twitter() {
  return (
    <SocialNetworkIcon
      href="https://twitter.com/AI_Cartel"
      src="/assets/social_network_icons/twitter.svg"
      alt="Twitter"
    />
  );
}

function LinkedIn() {
  return (
    <SocialNetworkIcon
      href="https://www.linkedin.com/company/ai-cartel/"
      src="/assets/social_network_icons/linkedin.svg"
      alt="LinkedIn"
    />
  );
}

function Email() {
  return (
    <Link
      className="flex justify-center s-text-xl pt-4"
      to="mailto:business@ai-cartel.com"
    >
      business@ai-cartel.com
    </Link>
  );
}

function ContactInfo() {
  return (
    <>
      <Email />
      <div className="grid grid-cols-3 gap-4 justify-items-center p-4">
        <Discord />
        <Twitter />
        <LinkedIn />
      </div>
    </>
  );
}

function Contact() {
  return (
    <>
      <Centered className="text-light-300 max-w-screen-sm">
        <Title2>
          <h3># Contact</h3>
        </Title2>
        To contact us for any question, or business inquiry contact us by email,
        Twitter, LinkedIn or Discord.
        <ContactInfo />
      </Centered>
    </>
  );
}

export default Contact;
