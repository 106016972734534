import { twMerge, twJoin } from "tailwind-merge";

export function TitleTD({ children, className = "" }) {
  return (
    <td
      className={twMerge(
        "font-bold s-text-xl p-2 border border-dark-300", // border-dark text-dark bg-light",
        className
      )}
    >
      {children}
    </td>
  );
}

export function CellTD({ children, className = "" }) {
  return (
    <td className={twJoin("p-2 border border-dark-300", className)}>
      {children}
    </td>
  );
}
