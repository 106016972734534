import { Disclosure } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowRightOnRectangleIcon as LogoutIcon,
  ArrowLeftOnRectangleIcon as LoginIcon,
} from "@heroicons/react/24/outline";
// https://heroicons.com/
import { BorderButtonLink, Logo } from "./DesignComponents.js";
import { useGameContext } from "../debate/GameContext.js";
import { useSearchParamsBoolRO } from "utils/StorageUtils.js";

// const navigation = [
//   { name: "Home", href: "/", current: false },
//   { name: "AI Recruiter", href: "/ai-recruiter/", current: false },
//   { name: "About us", href: "/#about", current: false },
//   { name: "Contact", href: "/#contact", current: false },
// ];
//
const navigation = [
  { name: "Pricing", href: "/ai-recruiter/#pricing" },
  // { name: "Speed Recruiting", href: "/virtual-speed-recruiting" },
  { name: "About us", href: "/ai-recruiter/#about" },
  { name: "Contact", href: "/ai-recruiter/#contact" },
  // { name: "PitchMe", href: "/pitchme" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
// https://tailwindui.com/components/application-ui/navigation/navbars
// className={classNames(
//   "font-extrabold border-secondary border-2 rounded-none hover:text-primary hover:bg-secondary text-lg md:text-xl",
//   "rounded-md px-3 py-2 text-sm font-medium"
// )}

function NavBarOnNormalScreen(props) {
  return (
    <div {...props}>
      <a className="flex flex-shrink-0 items-center font-bold m-3" href="/">
        <Logo className="s-text-xl" />
      </a>
      <div className="hidden md:ml-5 sm:block">
        <div className="flex space-x-4">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={classNames(
                "font-extrabold border-2 rounded-none hover:text-primary hover:bg-secondary s-text-lg",
                "rounded-md px-3 py-2 font-medium"
              )}
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

function ClosedNavBarOnMobileScreen({ open, ...props }) {
  return (
    <div {...props}>
      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
        <span className="sr-only">Open main menu</span>
        {open ? (
          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
        ) : (
          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
        )}
      </Disclosure.Button>
    </div>
  );
}

function OpenedNavBarOnMobileScreen() {
  return (
    <Disclosure.Panel className="sm:hidden">
      <div className="space-y-1 px-2 pb-3 pt-2">
        {navigation.map((item) => (
          <Disclosure.Button
            key={item.name}
            as="a"
            href={item.href}
            className={classNames(
              item.current
                ? "bg-dark-300 text-light"
                : "text-light-300 hover:bg-dark-300 hover:text-light-100 font-bold",
              "block rounded-md px-3 py-2 text-base font-medium border-y border-dark-300 text-center w-full"
            )}
            aria-current={item.current ? "page" : undefined}
          >
            {item.name}
          </Disclosure.Button>
        ))}
      </div>
    </Disclosure.Panel>
  );
}

function ProfileDropdown() {
  return <></>;
  // return (
  //   <Menu as="div" className="relative ml-3">
  //     <div>
  //       <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
  //         <span className="sr-only">Open user menu</span>
  //         <img
  //           className="h-8 w-8 rounded-full"
  //           src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
  //           alt=""
  //         />
  //       </Menu.Button>
  //     </div>
  //     <Transition
  //       as={Fragment}
  //       enter="transition ease-out duration-100"
  //       enterFrom="transform opacity-0 scale-95"
  //       enterTo="transform opacity-100 scale-100"
  //       leave="transition ease-in duration-75"
  //       leaveFrom="transform opacity-100 scale-100"
  //       leaveTo="transform opacity-0 scale-95"
  //     >
  //       <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
  //         <Menu.Item>
  //           {({ active }) => (
  //             <a
  //               href="#"
  //               className={classNames(
  //                 active ? "bg-gray-100" : "",
  //                 "block px-4 py-2 text-sm text-gray-700"
  //               )}
  //             >
  //               Your Profile
  //             </a>
  //           )}
  //         </Menu.Item>
  //         <Menu.Item>
  //           {({ active }) => (
  //             <a
  //               href="#"
  //               className={classNames(
  //                 active ? "bg-gray-100" : "",
  //                 "block px-4 py-2 text-sm text-gray-700"
  //               )}
  //             >
  //               Settings
  //             </a>
  //           )}
  //         </Menu.Item>
  //         <Menu.Item>
  //           {({ active }) => (
  //             <a
  //               href="#"
  //               className={classNames(
  //                 active ? "bg-gray-100" : "",
  //                 "block px-4 py-2 text-sm text-gray-700"
  //               )}
  //             >
  //               Sign out
  //             </a>
  //           )}
  //         </Menu.Item>
  //       </Menu.Items>
  //     </Transition>
  //   </Menu>
  // );
}

function LoginButton() {
  return (
    <BorderButtonLink className="inline-flex items-center" to="/login">
      <span className="sr-only">Login</span>
      <LoginIcon className="h-7 w-7" aria-hidden="true" />
      Login
    </BorderButtonLink>
  );
}

function RegisterButton() {
  return (
    <BorderButtonLink className="inline-flex items-center" to="/register">
      <span className="sr-only">Register</span>
      <LoginIcon className="h-7 w-7" aria-hidden="true" />
      Register
    </BorderButtonLink>
  );
}

function LogoutButton() {
  return (
    <BorderButtonLink className="inline-flex items-center" to="/logout">
      <span className="sr-only">Logout</span>
      <LogoutIcon className="h-7 w-7" aria-hidden="true" />
      Logout
    </BorderButtonLink>
  );
}

function NavLinkButton({ to, name }) {
  return (
    <BorderButtonLink className="inline-flex items-center" to={to}>
      <span className="sr-only">{name}</span>
      {name}
    </BorderButtonLink>
  );
}

function RighSideOfNavBar() {
  const { email, isAdmin } = useGameContext();
  const { pathname } = useLocation();
  const isOnAppPage = pathname.startsWith("/apps/");

  const isConnected = email.value.length > 0;

  return (
    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
      {isAdmin && <NavLinkButton to="/admin" name="Admin" />}
      {isConnected && (
        <NavLinkButton to="/apps/ai-recruiter/candidate/" name="Apps" />
      )}
      {isConnected && <LogoutButton />}
      {isOnAppPage && !isConnected && <LoginButton />}
      {isOnAppPage && !isConnected && <RegisterButton />}
      {/* Profile dropdown */}
      <ProfileDropdown />
    </div>
  );
}

const useIsNavbarEnabled = () => {
  const location = useLocation();
  const disabledUrls = ["/pitchme", "/apps/pitchme", "/dreams"];

  for (const disabledUrl of disabledUrls) {
    if (location.pathname.startsWith(disabledUrl)) {
      return false; // Disable the navbar if the URL starts with a disabled URL
    }
  }

  return true; // Enable the navbar for all other URLs
};

export default function NavBar() {
  // check if URI path contains "?navbar=false"
  const navbar = useSearchParamsBoolRO("navbar", true);
  const isNavbarEnabled = useIsNavbarEnabled();

  if (!navbar || !isNavbarEnabled) {
    return null;
  }
  return (
    <Disclosure
      as="nav"
      className="fixed z-40 bg-dark w-full border-b border-dark-200 left-0 py-4 top-0"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <ClosedNavBarOnMobileScreen
                open={open}
                className="absolute inset-y-0 left-0 flex items-center sm:hidden"
              />
              <NavBarOnNormalScreen className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start" />
              <RighSideOfNavBar />
            </div>
          </div>

          <OpenedNavBarOnMobileScreen />
        </>
      )}
    </Disclosure>
  );
}
