import { Routes, Route, Navigate } from "react-router-dom";

import { toast } from "react-toastify";
import React, { useState } from "react";
import {
  CheckboxLeft,
  FullButton,
  Centered,
  Title3,
} from "components/DesignComponents";

import AICartelPlayer from "../../AICartelPlayer";
import {
  ThisContextProvider,
  useThisContext,
} from "./context/GenericDiscussionContext";
import PrivacyCheckbox from "components/PrivacyCheckbox";

function LoadedDiscussion({ title, minimumMinutes = 2, requiredArgs = {} }) {
  const { currentStep, setCurrentStep } = useThisContext();
  const [AiAnswer, setAiAnswer] = useState(null);
  const [UserAnswer, setUserAnswer] = useState(null);

  function onAIAnswerFinished(ai_answer, user_answer) {
    setAiAnswer(ai_answer);
    setUserAnswer(user_answer);
  }

  const onAbortDiscussion = async (totalMinutes) => {
    if (totalMinutes < minimumMinutes) {
      toast.warning(
        `Please speak for at least ${minimumMinutes} minute${
          minimumMinutes > 1 ? "s" : ""
        }`
      );
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onDiscussionFinished = async () => {
    setCurrentStep(currentStep + 1);
    toast.success("Discussion finished");
  };

  return (
    <Centered>
      <div>
        <Title3>{title}</Title3>
        <AICartelPlayer
          end_button_text="Abort interview"
          applicationName={"GenericDiscussion"}
          onAbortDiscussion={onAbortDiscussion}
          onDiscussionFinished={onDiscussionFinished}
          onAIAnswerFinished={onAIAnswerFinished}
          requiredArgs={requiredArgs}
          reuseGameFromStorage={true}
        ></AICartelPlayer>
      </div>
    </Centered>
  );
}

function Step1() {
  const { currentStep, setCurrentStep } = useThisContext();

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setCurrentStep(currentStep + 1);
  }

  return (
    <>
      <Title3>AIStote professor</Title3>
      <p className="m-3">
        This is optimized to be used on a computer, avoid smartphones.
      </p>
      <form className="grid mx-auto place-items-center" onSubmit={handleSubmit}>
        <div className="grid">
          <CheckboxLeft
            label="You are in a quiet place?"
            setValue={() => {}}
            required
          />
          <CheckboxLeft
            label="Your microphone is enabled?"
            setValue={() => {}}
          />
          <PrivacyCheckbox />
        </div>
        <div className="flex justify-center">
          <FullButton className="text-center" type="submit" propagate>
            Start professor
          </FullButton>
        </div>
      </form>
    </>
  );
}

function Step2() {
  return (
    <LoadedDiscussion
      title="Professor AIStote"
      requiredArgs={{}}
      minimumMinutes={1}
    />
  );
}

function Step3() {
  return (
    <div className="grid mx-auto place-items-center text-green-600 text-bold s-text-xl">
      Discussion finished.
    </div>
  );
}

function WithContext() {
  const { currentStep } = useThisContext();

  return (
    <div className="flex flex-col place-content-center">
      {currentStep == 1 && <Step1 />}
      {currentStep == 2 && <Step2 />}
      {currentStep == 3 && <Step3 />}
    </div>
  );
}

export function DoDiscussion() {
  return (
    <ThisContextProvider>
      <WithContext />
    </ThisContextProvider>
  );
}

export function GenericDiscussionRoutes() {
  return (
    <Routes>
      <Route path="/" element={<DoDiscussion />}>
        <Route path="dodiscussion" element={<DoDiscussion />} />
        <Route path="" element={<Navigate to="./dodiscussion" replace />} />
        <Route path="*" element={<Navigate to="./dodiscussion" replace />} />
      </Route>
    </Routes>
  );
}
