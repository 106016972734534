import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function HiddenRequiredInput({ selectedValue, required }) {
  return (
    <input
      name="dropdown_hidden"
      defaultValue={selectedValue}
      required={required}
      id="required-hidden-input"
    />
  );
}

export function Dropdown({
  options,
  onSelect,
  value,
  label,
  required = false,
}) {
  const [selectedValue, setSelectedValue] = useState(value);
  // options ? (options.includes(value) ? value : options[0]) : undefined
  // );

  // alert(options ? (options.includes(value) ? value : options[0]) : undefined);

  useEffect(() => {
    // console.log(selectedValue);
    if (selectedValue && options?.includes(selectedValue)) {
      onSelect(selectedValue);
    }
  }, [options, selectedValue]);

  const handleSelect = (e) => {
    const value = e;
    setSelectedValue(value);
  };

  const handleClick = (e) => {
    // Prevent form submission when clicking on the Dropdown
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    // Prevent form submission when Enter key is pressed
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  if (!options) {
    return <div>{label}: Loading...</div>;
  }

  return (
    <div className="flex flex-row gap-2 m-2">
      <label>{label}</label>
      <Menu
        name="dropdown"
        as="div"
        className="relative inline-block text-left"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {selectedValue || "Not selected"}
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {Array.isArray(options) &&
                options.map((option) => (
                  <Menu.Item key={option}>
                    {({ active }) => (
                      <button
                        onClick={() => handleSelect(option)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm w-full text-left"
                        )}
                      >
                        {option}
                      </button>
                    )}
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <HiddenRequiredInput selectedValue={selectedValue} required={required} />
    </div>
  );
}

// Pull the data from a HTTP API
// And then render the dropdown
export function DropdownFromHTTP({ api, onSelect, label }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (options.length > 0) {
        return;
      }
      const data = await api();
      setOptions(data);
      onSelect(data[0]);
    }
    fetchData();
  }, []);

  return (
    <>
      {options && (
        <Dropdown
          label={label}
          options={options}
          onSelect={onSelect}
          className="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        />
      )}
      {!options && <div>Loading...</div>}
    </>
  );
}

export function BoolDropdown({ value, setValue, label }) {
  const [displayedValue, setDisplayedValue] = useState("All");
  const options = ["True", "False", "All"];

  useEffect(() => {
    if (displayedValue === "True") {
      setValue(true);
    } else if (displayedValue === "False") {
      setValue(false);
    } else {
      setValue(undefined);
    }
  }, [displayedValue, setValue]);

  return (
    <label className="block mt-4">
      <span className="px-10">{label}</span>
      <Dropdown
        label="Language"
        options={options}
        onSelect={setDisplayedValue}
        value={displayedValue}
        className="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
      />
    </label>
  );
}
