import { useRef, useState } from "react";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import { toast } from "react-toastify";

export const useRecordRTC = (onDataAvailableCallback) => {
  const recorderRef = useRef(null);
  const [hasMicPermission, setHasMicPermission] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const createRecorder = async () => {
    // console.log("useRecordRTC startRecording");
    try {
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { echoCancellation: true },
      });

      recorderRef.current = new RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/webm;codecs=pcm", // endpoint requires 16bit PCM audio
        recorderType: StereoAudioRecorder,
        timeSlice: 500, // set 250 ms intervals of data that sends to AAI
        desiredSampRate: 16000,
        numberOfAudioChannels: 1, // real-time requires only one channel
        bufferSize: 16384,
        audioBitsPerSecond: 128000,
        ondataavailable: onDataAvailableCallback,
        disableLogs: true,
      });

      recorderRef.current.startRecording();
      setHasMicPermission(true);
      setIsRecording(true);
    } catch (err) {
      toast.error("Please allow microphone access");
      console.error(err);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     console.log("useRecordRTC useEffect");
  //     await createRecorder();
  //   })();
  // }, []);

  const startRecording = async () => {
    if (recorderRef.current) {
      recorderRef.current.resumeRecording();
      setIsRecording(true);
    } else {
      await createRecorder();
    }
  };

  const stopRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.pauseRecording();
      // recorderRef.current = null;
      setIsRecording(false);
    }
  };

  return {
    startRecording,
    stopRecording,
    isRecording,
    hasMicPermission,
  };
};
