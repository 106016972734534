import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import ReactAudioPlayer from "react-audio-player";
import useApi from "utils/Api";
import { Centered, Title3 } from "components/DesignComponents";
import useAsyncData from "utils/NetworkUtils";
import { formatDayMonth } from "utils/DateUtils";
import ConfirmedEmail from "features/ConfirmedEmail";
import { AdminButton } from "components/Buttons";
import { DeleteButton, PlayButton } from "components/IconButtons";
import { Link } from "react-router-dom";
import XS from "components/XS";

const JobCard = ({
  entry,
  index,
  setRefreshCallback,
  playAudio,
  changeVoice,
}) => {
  const { apiAIRecruiterDeleteJob, apiAIRecruiterToggleJob } = useApi();
  const {
    created_at,
    last_seen,
    mail_confirmed,
    email,
    phone_number,
    referral_code,
    referrer_email,
    language,
    uuid,
    ai_price,
    job_title,
    discussion_duration,
    is_available,
  } = entry;
  const forced_by_admin = true;

  const doDelete = async () => {
    const { status } = await apiAIRecruiterDeleteJob(uuid);
    if (status === "ok") {
      toast.success(`Successfully deleted ${uuid}`);
      setRefreshCallback((prev) => !prev);
    }
  };

  // const save = async () => {
  //   const { status } = await apiAIRecruiterSaveJob(uuid, forced_by_admin);
  //   if (status === "ok") {
  //     toast.success(`Successfully updated`);
  //     setRefreshCallback((prev) => !prev);
  //   }
  // };

  const toggle = async () => {
    const { status } = await apiAIRecruiterToggleJob(uuid, forced_by_admin);
    if (status === "ok") {
      toast.success(`Successfully toggled`);
      setRefreshCallback((prev) => !prev);
    }
  };

  return (
    <Link to={`/apps/ai-recruiter/company/my-jobs/view-job/${uuid}`}>
      <div className="bg-dark hover:bg-dark-200 border border-dark-200 text-secondary p-4 relative overflow-hidden my-2">
        <div className="flex justify-between">
          <div>
            <h2 className={`font-bold text-light-100`}>
              {index + 1}. {job_title}
            </h2>
            <div className="text-sm text-light-300">
              {formatDayMonth(created_at)} · Last seen: {last_seen} ·
              <span className="text-xs"> ({discussion_duration} min)</span>
            </div>
            <div className="text-sm text-light-300 mt-2">
              <ConfirmedEmail email={email} mail_confirmed={mail_confirmed} />
              <XS k="Phone">{phone_number}</XS>
              <br />
              <XS k="RC">{referral_code}</XS>
              <XS k="RC">{referrer_email}</XS>
            </div>
          </div>
          <div className="flex flex-row justify-end items-center gap-2 mt-4">
            <div className="text-right">
              <div className="text-sm text-light-300">{ai_price} ¢</div>
              <div className="text-xs text-light-300 mt-1">{language}</div>

              {is_available ? (
                <div className="text-sm text-green-600 border border-green-600 p-1">
                  Enabled
                </div>
              ) : (
                <div className="text-sm text-orange-600 border border-orange-600 p-1">
                  Disabled
                </div>
              )}
            </div>
            <AdminButton onClick={() => changeVoice(uuid)}>
              Change Voice
            </AdminButton>
            <AdminButton onClick={() => toggle(uuid)}>Toggle</AdminButton>
            <PlayButton onClick={() => playAudio(uuid)} />
            <DeleteButton onClick={doDelete} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default function AdminJobs() {
  const [refresh, setRefresh] = useState(true);
  const { apiAIRecruiterAllJobsAdmin, apiGetAudio, apiChangeAIVoice } =
    useApi();
  const jobs = useAsyncData(apiAIRecruiterAllJobsAdmin, refresh);
  const [currentPlayedAudioUrl, setCurrentPlayedAudioUrl] = useState(null);

  const playAudio = useCallback(
    async (uuid) => {
      try {
        const mp3Blob = await apiGetAudio(uuid);
        const audioUrl = URL.createObjectURL(mp3Blob);
        setCurrentPlayedAudioUrl(audioUrl);
      } catch (error) {
        console.log(error);
        toast.error("Error playing audio");
      }
    },
    [apiGetAudio]
  );

  const changeVoice = useCallback(
    async (uuid) => {
      await apiChangeAIVoice(uuid);
      toast.success("Voice changed");
    },
    [apiChangeAIVoice]
  );

  if (!jobs) {
    return <div>Loading...</div>;
  }
  return (
    <section className="border-t border-gray-800 py-1">
      <Title3>Admin Jobs</Title3>
      <Centered>
        <ReactAudioPlayer
          id="audio"
          src={currentPlayedAudioUrl}
          autoPlay
          controls
          className="py-4"
        />
        <div className="space-y-4">
          {jobs.map((entry, index) => (
            <JobCard
              key={entry.uuid}
              entry={entry}
              index={index}
              setRefreshCallback={setRefresh}
              playAudio={playAudio}
              changeVoice={changeVoice}
            />
          ))}
        </div>
      </Centered>
    </section>
  );
}
