import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { useGameContext } from "../debate/GameContext";

function OneTab({ icon, name, to, isFocused }) {
  // const tabClasses = `p-2 md:p-4 inline-flex items-center justify-center  font-bold border-b-2 rounded-t-lg ${
  const tabClasses = `p-2 md:p-4 inline-flex items-center justify-center  font-bold border-b-2 ${
    isFocused
      ? "text-light-100 border-light-100 bg-dark-200"
      : "text-light-300 border-transparent hover:text-gray-600 hover:border-gray-300"
  }`;
  return (
    <Link to={to} className={tabClasses}>
      <div className="w-7 h-7">{icon}</div>
      {name}
    </Link>
  );
}

export default function Tab({ tabs, className = "text-2xl" }) {
  const { pathname } = useLocation();
  const { isConnected } = useGameContext();
  return (
    <div className={twJoin("border-b border-dark-300", className)}>
      <div className="flex flex-wrap -mb-px text-center text-gray-500 dark:text-gray-400">
        {tabs.map(
          (tab, index) =>
            (!tab.auth_required || isConnected) && (
              <OneTab
                key={index}
                {...tab}
                isFocused={pathname.startsWith(tab.to)}
              />
            )
        )}
      </div>
    </div>
  );
}
