import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactAudioPlayer from "react-audio-player";
import useApi from "../utils/Api";
import { Centered } from "../components/DesignComponents";
import useAsyncData from "../utils/NetworkUtils";
import { formatDayMonth } from "../utils/DateUtils";
import ConfirmedEmail from "../features/ConfirmedEmail";
import useApiCallback from "../utils/useApiCallback";
import { AdminButton } from "components/Buttons";
import { DeleteButton, PlayButton } from "components/IconButtons";
import { Link } from "react-router-dom";
import XS from "components/XS";

const CandidateCard = ({
  entry,
  index,
  playAudio,
  changeVoice,
  doDelete,
  doSave,
}) => {
  const {
    created_at,
    email,
    phone_number,
    referral_code,
    referrer_email,
    username,
    ai_price,
    candidate_status,
    mail_confirmed,
    language,
    discussion_duration,
  } = entry;

  return (
    <Link to={`/apps/ai-recruiter/company/view-candidate/${entry.uuid}`}>
      <div className="bg-dark hover:bg-dark-200 border border-dark-200 text-secondary p-4 relative overflow-hidden my-2">
        <div className="flex justify-between">
          <div>
            <h2 className="font-bold text-light-100">
              {index + 1}. {username} . {entry.job_title}
            </h2>
            <div className="text-sm text-light-300">
              {formatDayMonth(created_at)} ·
              <span className="text-xs"> ({discussion_duration} min)</span>
            </div>
            <div className="text-sm text-light-300 mt-2">
              <ConfirmedEmail email={email} mail_confirmed={mail_confirmed} />
              <XS k="Phone">{phone_number}</XS>
              <br />
              <XS k="RC">{referral_code}</XS>
              <XS k="RC">{referrer_email}</XS>
            </div>
          </div>
          <div className="text-right">
            <div className="text-sm text-light-300">{ai_price}</div>
            <div className="text-xs text-light-300 mt-1">
              {candidate_status} · {language}
            </div>
          </div>
          <div className="flex flex-row justify-end items-center gap-2 mt-4">
            <AdminButton onClick={() => changeVoice(entry.uuid)}>
              Change Voice
            </AdminButton>
            <PlayButton onClick={() => playAudio(entry.uuid)} />
            <DeleteButton onClick={() => doDelete(entry.uuid)} />
            <AdminButton onClick={() => doSave(entry.uuid, true)}>
              save
            </AdminButton>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default function AdminCandidates() {
  const [refresh, setRefresh] = useState(false);
  const {
    apiAIRecruiterAllCandidatesAdmin,
    apiGetAudio,
    apiChangeAIVoice,
    apiAIRecruiterDeleteCandidateAdmin,
    apiAIRecruiterSaveCandidate,
  } = useApi();

  const candidates = useAsyncData(
    () => apiAIRecruiterAllCandidatesAdmin(),
    refresh
  );
  const [currentPlayedAudioUrl, setCurrentPlayedAudioUrl] = useState(null);

  const playAudio = useCallback(
    async (uuid) => {
      try {
        const mp3Blob = await apiGetAudio(uuid);
        const audioUrl = URL.createObjectURL(mp3Blob);
        setCurrentPlayedAudioUrl(audioUrl);
      } catch (error) {
        console.error(error);
        toast.error("Error playing audio");
      }
    },
    [apiGetAudio]
  );

  const doRefresh = useCallback(() => {
    setRefresh((r) => !r);
  }, [setRefresh]);

  const changeVoice = useApiCallback(
    apiChangeAIVoice,
    doRefresh,
    "Voice changed",
    "Failed to change voice"
  );

  const doDelete = useApiCallback(
    apiAIRecruiterDeleteCandidateAdmin,
    doRefresh,
    "Successfully deleted candidate",
    "Failed to delete candidate"
  );

  const doSave = useApiCallback(
    apiAIRecruiterSaveCandidate,
    doRefresh,
    "Successfully saved candidate",
    "Failed to save candidate"
  );

  return (
    <section className="border-t border-gray-800 py-10">
      <Centered>
        <ReactAudioPlayer
          src={currentPlayedAudioUrl}
          autoPlay
          controls
          className="py-4"
        />
        <div className="space-y-4">
          {candidates.map((entry, index) => (
            <CandidateCard
              key={entry.uuid}
              entry={entry}
              index={index}
              playAudio={playAudio}
              changeVoice={changeVoice}
              doDelete={doDelete}
              doSave={doSave}
            />
          ))}
        </div>
      </Centered>
    </section>
  );
}
