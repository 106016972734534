import { useGameContext } from "debate/GameContext";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

export const useBaseURL = () => {
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;
  return baseURL;
};

// This is a custom hook that fetches data from an async API method
export default function useAsyncData(asyncApiMethod, refresh = undefined) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const fetchedData = await asyncApiMethod();
      setData(fetchedData);
    }
    fetchData();
  }, [refresh]);

  return data;
}

export function Loading({ loadingMessage = "Loading..." }) {
  return <div className="font-bold s-text-2xl p-8">{loadingMessage}</div>;
}

export function useDynamicQuery(key, func, params = {}, options = {}) {
  const { enableAdmin } = useGameContext();
  const queryFn = async () => {
    // values = Object.values(params);
    // return await func(...Object.values(params));
    return await func(params);
  };

  // Default options for the query
  const defaultOptions = {
    staleTime: 10000, // 50 seconds
    retry: false,
    refetchOnWindowFocus: false,
    ...options, // Spread additional options if provided
  };

  // Use the useQuery hook with dynamic parameters
  const query = useQuery(key + enableAdmin, queryFn, defaultOptions);

  return query;
}
