import React from "react";
import PropTypes from "prop-types";
import "./Tooltip.css"; // Make sure to create this CSS file
import { twMerge } from "tailwind-merge";

// <div className={twMerge(className, "relative flex items-center")}>
function Tooltip({ text, children, className }) {
  return (
    <div className={className}>
      <div className="relative flex w-full max-w-full">
        <div className="group w-full max-w-full flex">
          {children}
          <span className="absolute top-full right-0 mb-2 hidden group-hover:inline-block bg-dark text-white s-text-base font-bold px-3 py-1 max-w-screen-md z-40 border">
            {text}
          </span>
        </div>
      </div>
    </div>
  );
}

//

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
