import React, { createContext, useContext, useState } from "react";
import {
  useLocalStorageString,
  useSearchParamsInteger,
} from "utils/StorageUtils";
import { useGameContext } from "debate/GameContext";
import { useSignal } from "@preact/signals-react";

// -----------------------------------------------
//               Post job context
// -----------------------------------------------
const PostJobContext = createContext();

export function usePostJobContext() {
  return useContext(PostJobContext);
}

export const PostJobContextProvider = (props) => {
  // const [currentStep, setCurrentStep] = useState(1);
  const [jobDescription, setJobDescription] =
    useLocalStorageString("job_description");
  const {
    gameUUID,
    setGameUUID,
    email,
    setEmail,
    saveEmail,
    companyName,
    setCompanyName,
  } = useGameContext();
  // const [currentStep, setCurrentStep] = useState(1);
  const [currentStep, setCurrentStep] = useSearchParamsInteger(
    "step",
    1,
    (v) => {
      if (v < 1) return 1;
      if (v > 4) return 4;
      return v;
    }
  );
  const [speedRecruiting, setSpeedRecruiting] = useState("");
  const referralBounty = useSignal(0);

  const setReferralBountySafe = (value) => {
    let tmp = parseInt(value);
    let minimum = 0;
    if (tmp < 0) tmp = 0;
    referralBounty.value = tmp;
  };

  return (
    <PostJobContext.Provider
      value={{
        jobUUID: gameUUID,
        setJobUUID: setGameUUID,
        currentStep,
        setCurrentStep,
        jobDescription,
        setJobDescription,
        companyName,
        setCompanyName,
        email,
        setEmail,
        saveEmail,
        speedRecruiting,
        setSpeedRecruiting,
        referralBounty,
        setReferralBounty: setReferralBountySafe,
      }}
    >
      {props.children}
    </PostJobContext.Provider>
  );
};
