import {
  Centered,
  FullButton,
  Input,
  TextArea,
  Title,
  Title3,
} from "components/DesignComponents";
import ErrorMessages, { HandleHTTPErrors2 } from "components/ErrorMessages";
import { useCallback, useState } from "react";
import useViralTiktokApi from "./ViralTikTokApi";
import notifications from "utils/Notifications";
import VideoPlayer from "components/VideoPlayer";
import { useViralTikTokContext } from "./ViralTiktokContext";
import { ShowTikTok } from "./ReuseVideo";
import { useCallbackOnceAtATime } from "utils/useCallbackOnceAtATime";
import ProgressBar from "components/output/ProgressBar";

export function QuizVideo() {
  return (
    <Centered>
      <CreateQuizVideo />
      <ListTikTok />
    </Centered>
  );
}

function TikTokCard({ tiktok, createTikTok }) {
  console.log(`/app/apps/viral-tiktok/download/quiz_videos-${tiktok}`);

  return (
    <div className="grid justify-items-center">
      <VideoPlayer
        src={`/api/apps/viral-tiktok/download/quiz_videos-${tiktok}`}
      ></VideoPlayer>
      <FullButton onClick={() => createTikTok(tiktok)}>
        <span>Post</span>
      </FullButton>
    </div>
  );

  // Post <span className="s-text-xs">{tiktok}</span>
}

export function ListTikTok() {
  const { useListQuizVideo, apiUploadTiktok } = useViralTiktokApi();
  const requestsState = useListQuizVideo();
  const tiktoks = requestsState.data?.output;
  const [requestResponse, setRequestResponse] = useState(null);
  const [currentTikTok, setCurrentTikTok] = useState(null);

  // const createTikTok = useCallback(
  //   async (tiktok) => {
  //     console.log("processTikTok", tiktok);
  //     const { status, ...remaining } = await apiCreateViralTikTok(tiktok);
  //     setRequestResponse({ status, ...remaining });
  //     setCurrentTikTok(tiktok);
  //     if (status === "ok") {
  //       notifications.success("TikTok was created");
  //     }
  //   },
  //   [apiCreateViralTikTok, setRequestResponse, setCurrentTikTok]
  // );

  const postTikTok = useCallback(
    async (filename) => {
      console.log("processTikTok", filename);
      const { status, ...remaining } = await apiUploadTiktok(filename);
      setRequestResponse({ status, ...remaining });
      if (status === "ok") {
        notifications.success("TikTok was created");
      }
    },
    [apiUploadTiktok, setRequestResponse]
  );

  return (
    <div className="border p-2 border-dark-200">
      <HandleHTTPErrors2 {...requestsState}>
        <Title3>Quiz Videos</Title3>
        <div className="grid grid-cols-3 gap-3">
          {tiktoks &&
            tiktoks.map((tiktok) => (
              <TikTokCard
                key={tiktok}
                tiktok={tiktok}
                createTikTok={postTikTok}
              />
            ))}
        </div>
        <ErrorMessages
          requestResponse={requestResponse}
          successMessage="TikTok was created"
        ></ErrorMessages>
        {requestResponse && (
          <div>
            <Title3>{currentTikTok}</Title3>
            <div>AI Output:{requestResponse.ai_output}</div>
            <Title3>Before</Title3>
            <pre className="border p-2">{requestResponse.before}</pre>
            <Title3>After</Title3>
            <pre className="border p-2">{requestResponse.after}</pre>
          </div>
        )}
      </HandleHTTPErrors2>
    </div>
  );
}

function CreateQuizVideo() {
  const { apiCreateQuizVideo, apiCreateBulkQuizVideo } = useViralTiktokApi();
  const [requestResponse, setRequestResponse] = useState(null);
  const [quizTheme, setQuizTheme] = useState("");
  const [nbrQuiz, setNbrQuiz] = useState(4);
  const [output, setOutput] = useState(null);
  const { backgroundVideo } = useViralTikTokContext();
  const [taskId, setTaskId] = useState(null);

  const [handleSubmit, isProcessing] = useCallbackOnceAtATime(
    async (event) => {
      if (!quizTheme) {
        notifications.error("Search Param is required");
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      const full_video_export =
        event.nativeEvent.submitter.textContent === "Full video";
      const export_image_only =
        event.nativeEvent.submitter.textContent === "Image only";
      const create_bulk =
        event.nativeEvent.submitter.textContent === "Create Bulk";

      if (!create_bulk) {
        const { status, message, output } = await apiCreateQuizVideo(
          nbrQuiz,
          export_image_only,
          full_video_export,
          quizTheme,
          backgroundVideo.value.tiktok_url
        );
        setRequestResponse({ status });
        if (status === "ok") {
          if (output.frames) {
            output.frames = output.frames.map((frame) => {
              return `${frame}?${new Date().getTime()}`;
            });
          }
          output.quizVideo = `${output.quizVideo}?${new Date().getTime()}`;
          setOutput(output);
          console.log("AI output");
          console.log(output);
        } else {
          console.log("createDiscussion error", message);
        }
      } else {
        const { status, task_id } = await apiCreateBulkQuizVideo(
          nbrQuiz,
          quizTheme,
          backgroundVideo.value.tiktok_url
        );
        if (status === "ok") {
          notifications.success("Task created");
          setTaskId(task_id);
        }
      }
    },
    [
      quizTheme,
      apiCreateQuizVideo,
      apiCreateBulkQuizVideo,
      setRequestResponse,
      setOutput,
    ]
  );

  return (
    <div>
      <Centered>
        <Title>
          <h1>Quiz Video Creator</h1>
        </Title>
        <div className="pb-3">This creates viral TikTok videos.</div>
        {taskId && <ProgressBar taskId={taskId} />}
        {isProcessing ? (
          <div className="text-4xl text-orange-600 p-4 text-center">
            Processing...
          </div>
        ) : (
          <form
            className="grid mx-auto place-items-center"
            onSubmit={handleSubmit}
          >
            <TextArea
              label="Quiz theme"
              name="quiztheme"
              value={quizTheme}
              onChange={setQuizTheme}
            />
            <Input
              label="Number of questions"
              type="number"
              name="nbrQuiz"
              value={nbrQuiz}
              onChange={(x) => setNbrQuiz(parseInt(x))}
              placeholder="Number of questions"
            />
            <div className="flex justify-center border">
              <div className="flex justify-center s-text-2xl font-bold p-2">
                Export
              </div>
              <FullButton className="text-center" type="submit" propagate>
                Image only
              </FullButton>
              <FullButton className="text-center" type="submit" propagate>
                Fast video
              </FullButton>
              <FullButton className="text-center" type="submit" propagate>
                Full video
              </FullButton>
              <FullButton className="text-center" type="submit" propagate>
                Create Bulk
              </FullButton>
            </div>
          </form>
        )}
      </Centered>
      <div className="grid grid-cols-4 gap-3">
        {backgroundVideo.value && (
          <div>
            <Title3>Background</Title3>
            <ShowTikTok tiktok={backgroundVideo.value} />
          </div>
        )}
        {output && (
          <>
            {output.frames &&
              output.frames.map((image, index) => (
                <img
                  key={index}
                  src={`/api/apps/viral-tiktok/download/${image}`}
                  alt="quiz"
                />
              ))}
            {output.quizVideo && (
              <VideoPlayer
                src={`/api/apps/viral-tiktok/download/${output.quizVideo}`}
              ></VideoPlayer>
            )}
          </>
        )}
      </div>
    </div>
  );
}

// <ErrorMessages
//   requestResponse={requestResponse}
//   successMessage="TikTok were processed"
// >
//
// </ErrorMessages>
