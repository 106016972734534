import {
  TrashIcon,
  EyeIcon,
  PlayIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { FullButton, FullButtonLink } from "./DesignComponents";
import { useCallback } from "react";
import { ClipboardDocumentIcon as ClipboardIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { BorderButton } from "components/DesignComponents";

export function DeleteButton({ ...props }) {
  return (
    <FullButton className="inline-flex items-center md:p-2 md:m-1" {...props}>
      <span className="sr-only">Delete</span>
      <TrashIcon className="h-7 w-7" aria-hidden="true" />
    </FullButton>
  );
}

export function EditButton({ ...props }) {
  return (
    <FullButton className="inline-flex items-center md:p-2 md:m-1" {...props}>
      <span className="sr-only">Edit</span>
      <PencilSquareIcon className="h-7 w-7" aria-hidden="true" />
    </FullButton>
  );
}

export function ViewButtonLink({ ...props }) {
  return (
    <FullButtonLink
      className="inline-flex items-center md:p-2 md:m-1"
      {...props}
    >
      <span className="sr-only">View</span>
      <EyeIcon className="h-7 w-7" aria-hidden="true" />
    </FullButtonLink>
  );
}

export function PlayButtonLink({ ...props }) {
  return (
    <FullButtonLink
      className="inline-flex items-center md:p-2 md:m-1"
      {...props}
    >
      <span className="sr-only">Play</span>
      <PlayIcon className="h-7 w-7" aria-hidden="true" />
    </FullButtonLink>
  );
}

export function PlayButton({ ...props }) {
  return (
    <FullButton className="inline-flex items-center md:p-2 md:m-1" {...props}>
      <span className="sr-only">Play</span>
      <PlayIcon className="h-7 w-7" aria-hidden="true" />
    </FullButton>
  );
}

// Icon SVG
function SocAnalyst(props) {
  // Define pastel colors for the SVG elements
  const pastelStyles = {
    shield: { fill: "#a8e6cf" }, // Soft green
    eye: { fill: "#ffd3b6" }, // Soft orange
    pupil: { fill: "#ff9a8d" }, // Soft red
  };

  return (
    <svg
      // Here the width and height are defined as 64px (which is equivalent to w-16 h-16 in Tailwind)
      width="64"
      height="64"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64" // This is what maintains aspect ratio
      {...props} // We spread any other props passed to the component
    >
      <g id="SocAnalyst_bgCarrier"></g>
      <g id="SocAnalyst_iconCarrier">
        {/* Shield Background */}
        <path
          style={pastelStyles.shield}
          d="M32,5c-13.255,0-24,10.745-24,24v1c0,13.255,10.745,24,24,24s24-10.745,24-24v-1C56,15.745,45.255,5,32,5z"
        />
        {/* Eye - Representing monitoring */}
        <path
          style={pastelStyles.eye}
          d="M32,20c-7.732,0-14,6.268-14,14s6.268,14,14,14s14-6.268,14-14S39.732,20,32,20z m0,22c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S36.418,42,32,42z"
        />
        {/* Pupil - Representing focus */}
        <circle cx="32" cy="34" r="4" style={pastelStyles.pupil} />
      </g>
    </svg>
  );
}

function CaregiverIcon(props) {
  // Define pastel colors for the SVG elements
  const pastelStyles = {
    heart: { fill: "#ffd3b6" }, // Soft orange for the heart
    figure: { fill: "#a8e6cf" }, // Soft green for the caregiver figure
  };

  return (
    <svg
      width="64" // Fixed width as requested (Tailwind's w-16 equivalent)
      height="64" // Fixed height as requested (Tailwind's h-16 equivalent)
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props} // Spread additional props passed to the component
    >
      {/* Heart shape */}
      <path
        style={pastelStyles.heart}
        d="M47.5,8c-5.1,0-9.8,3.9-11.5,7.4C34.3,11.9,29.6,8,24.5,8C17.6,8,12,13.6,12,20.5
          c0,11.4,11.7,19.8,19,27c1.7,1.7,3.3,3.2,4.7,4.5c0.7,0.6,1.8,0.6,2.5,0c1.4-1.3,3-2.8,4.7-4.5c7.3-7.2,19-15.6,19-27
          C58,13.6,52.4,8,47.5,8z"
      />
      {/* Caregiver figure */}
      <path
        style={pastelStyles.figure}
        d="M32,34c-7.7,0-14,6.3-14,14v1c0,0.6,0.4,1,1,1h26c0.6,0,1-0.4,1-1v-1C46,40.3,39.7,34,32,34z"
      />
      <circle cx="32" cy="24" r="6" style={pastelStyles.figure} />
    </svg>
  );
}

function MechanicIcon(props) {
  // Define pastel colors for the SVG elements
  const pastelStyles = {
    gear: { fill: "#ffd3b6" }, // Soft orange for the gear
    wrench: { fill: "#a8e6cf" }, // Soft green for the wrench
  };

  return (
    <svg
      width="64" // Fixed width as requested (Tailwind's w-16 equivalent)
      height="64" // Fixed height as requested (Tailwind's h-16 equivalent)
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props} // Spread additional props passed to the component
    >
      {/* Gear shape */}
      <path
        style={pastelStyles.gear}
        d="M32,20c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S38.6,20,32,20z M32,40c-4.4,0-8-3.6-8-8
        s3.6-8,8-8s8,3.6,8,8S36.4,40,32,40z"
      />
      <circle cx="32" cy="32" r="4" style={pastelStyles.gear} />
      {/* Wrench shape */}
      <path
        style={pastelStyles.wrench}
        d="M52.7,11.3c-3.1-3.1-8.2-3.1-11.3,0l-2.1,2.1l4.2,4.2l-2.8,2.8l-4.2-4.2l-2.1,2.1
        c-0.2,0.2-0.3,0.4-0.4,0.7l-6.7,13.4c-0.6,1.2-0.2,2.6,0.9,3.7c1.1,1.1,2.5,1.5,3.7,0.9l13.4-6.7c0.2-0.1,0.5-0.2,0.7-0.4l2.1-2.1
        l4.2,4.2l2.8-2.8l-4.2-4.2l2.1-2.1C55.8,19.5,55.8,14.4,52.7,11.3z"
      />
    </svg>
  );
}

const orange = "#ffd3b6"; // Soft orange for the wheel

function GaragistIcon(props) {
  // Define pastel colors for the SVG elements

  return (
    <svg
      width="64" // Fixed width as requested (Tailwind's w-16 equivalent)
      height="64" // Fixed height as requested (Tailwind's h-16 equivalent)
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props} // Spread additional props passed to the component
    >
      {/* Car wheel */}
      <circle cx="32" cy="32" r="20" fill={orange} />
      <circle cx="32" cy="32" r="16" fill="white" />
      <circle cx="32" cy="32" r="12" fill={orange} />
      {/* Wheel spokes */}
      <path
        fill={orange}
        d="M32 20v-8c-1.1 0-2 .9-2 2v6h-6c-1.1 0-2 .9-2 2h8z"
      />
      <path fill={orange} d="M32 44v8c1.1 0 2-.9 2-2v-6h6c1.1 0 2-.9 2-2h-8z" />
      <path fill={orange} d="M20 32h-8c0 1.1.9 2 2 2h6v6c0 1.1.9 2 2 2v-8z" />
      <path fill={orange} d="M44 32h8c0-1.1-.9-2-2-2h-6v-6c0-1.1-.9-2-2-2v8z" />
      {/* Central hub */}
      <circle cx="32" cy="32" r="4" fill="white" />
    </svg>
  );
}

function ProfessionIcons() {
  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <SocAnalyst className="h-16 w-16" />
        <span className="text-lg font-medium">SOC Analyst</span>
      </div>
      <div className="flex items-center space-x-2">
        <CaregiverIcon className="h-16 w-16" />
        <span className="text-lg font-medium">Caregiver</span>
      </div>
      <div className="flex items-center space-x-2">
        <MechanicIcon className="h-16 w-16" />
        <span className="text-lg font-medium">Mechanic</span>
      </div>
      <div className="flex items-center space-x-2">
        <GaragistIcon className="h-16 w-16" />
        <span className="text-lg font-medium">Garagist</span>
      </div>
    </div>
  );
}

export function CopyToClipboardButton({ text }) {
  const copyLinkClicked = useCallback(async () => {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  }, [text]);

  return (
    <>
      {text && (
        <button
          className="m-1 s-text-sd font-bold text-blue-900 bg-primary text-secondary hover:text-primary hover:bg-secondary inline-flex items-center"
          onClick={copyLinkClicked}
        >
          <ClipboardIcon className="h-7 w-7 hover:text-dark-300" />
          Copy
        </button>
      )}
    </>
  );
}
