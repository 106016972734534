import { useSignal } from "@preact/signals-react";
import { useCallback } from "react";

export function useCallbackOnceAtATime(callback, dependencies = []) {
  const isProcessing = useSignal(false);

  // Use useCallback to memoize wrappedCallback
  const wrappedCallback = useCallback(
    async (...args) => {
      if (isProcessing.value) return; // Prevent multiple calls
      isProcessing.value = true; // Indicate that processing is starting
      try {
        await callback(...args);
      } finally {
        isProcessing.value = false; // Reset the processing state after completion
      }
    },
    [isProcessing, ...dependencies]
  );
  // Include dependencies to ensure useCallback updates the function when they change

  return [wrappedCallback, isProcessing.value];
}
