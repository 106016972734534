import React from "react";

function Role({ role, bgColor }) {
  return (
    <div className={`font-bold inline-flex items-center m-2 h-full`}>
      <div className={`border border-dark-300 rounded-lg p-2 ${bgColor}`}>
        {role}
      </div>
    </div>
  );
}

function Msg({ index, role, content }) {
  const [justify, alignText, bgColor] =
    role === "assistant"
      ? ["justify-start", "text-left", "bg-dark-200"]
      : ["justify-end", "text-right", "bg-dark-300"];

  return (
    <div key={index} className={`flex ${justify} items-start`}>
      {role === "assistant" && <Role role={role} bgColor={bgColor} />}

      <div className="flex-grow">
        <div className={`${justify} flex`}>
          <div
            className={`inline-block m-2 p-2 rounded-lg border border-dark-300 ${alignText}`}
          >
            {content}
          </div>
        </div>
      </div>

      {role !== "assistant" && <Role role={role} bgColor={bgColor} />}
    </div>
  );
}

function DiscussionHistory({ job }) {
  return (
    <div className="p-4 w-full">
      {job &&
        job.discussion_history &&
        job.discussion_history.map((item, index) => (
          <Msg key={index} role={item.role} content={item.content} />
        ))}
    </div>
  );
}

export default DiscussionHistory;
