import React, { useEffect } from "react";
import About from "./About";
import Contact from "./Contact";
import VideoPlayer from "../components/VideoPlayer.js";
import {
  FullButtonLink,
  BorderButtonLink,
  BottomBanner,
  Section,
  Gradient,
  Title2,
} from "../components/DesignComponents.js";
import {
  HeaderDesignV2,
  HeaderShortMessages,
  HeaderShortMessagesItem,
} from "../components/HeaderDesigner.js";
import FAQ from "../pages/FAQ";
import useTwitterPixel from "utils/useTwitterPixel";
import Pricing from "./Pricing";
import StepCards from "components/StepCard";
import Statistics from "features/Statistics";
import { ReferencesLogo } from "./References";

const stepsData = [
  {
    step: "",
    title: "Jobs locations",
    description: (
      <div>
        <div>Remote in Canada</div>
        <ul>
          <li>Montreal</li>
          <li>Toronto</li>
          <li>Vancouver</li>
        </ul>
      </div>
    ),
  },
  {
    step: "",
    title: "Roles",
    description: (
      <div>
        <ul>
          <li>Engineering</li>
          <li>Marketing</li>
          <li>Customer Success</li>
          <li>HR / Recruitment</li>
        </ul>
      </div>
    ),
    success: true,
  },
];

const GlobalInfo = () => {
  return (
    <StepCards
      title="Are you hiring for these roles in these locations?"
      description=<div>
        Don't miss out and join our virtual speed recruiting event before{" "}
        <EventDate />.
      </div>
      stepsData={stepsData}
    />
  );
};

function Header(props) {
  return (
    <div
      className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      {...props}
    >
      <div className="mb-16 md:mb-0 md:max-w-xl sm:mx-auto md:text-center">
        <VideoPlayer
          src="/assets/video/ai_recruiter_demo_07_11_2023_en.mp4"
          poster="/assets/img/ai_recruiter_2.webp"
        ></VideoPlayer>
        <HeaderDesignV2>
          <h1 className="mb-5 font-sans s-text-4xl font-bold tracking-tight text-light-100 sm:leading-none">
            Virtual
            <Gradient> Speed </Gradient>
            Recruiting
          </h1>
          <h2 className="mb-5 text-light-300 s-text-base">
            Referral-based recruitment platform, powered by AI and Humans. Tired
            of traditional recruiting methods? Dive into our groundbreaking
            Speed Recruiting Event, merging the efficiency of AI with the
            strength of referrals to streamline your hiring process.
          </h2>
        </HeaderDesignV2>
        <CallToAction />
      </div>
    </div>
  );
}

// <span className="text-xs -translate-y-3"> Free</span>
function CallToAction({ className = "", reverse = false }) {
  const ButtonPostJob = reverse ? BorderButtonLink : FullButtonLink;
  const ButtonFindJob = reverse ? FullButtonLink : BorderButtonLink;

  return (
    <div className={`flex place-content-center gap-3 ${className}`}>
      <ButtonPostJob
        to="/apps/ai-recruiter/company/virtual-speed-recruiting"
        className="flex-1 py-3 my-3 px-3 mx-0 md:p-3 md:m-3"
      >
        <h2>Post a job</h2>
      </ButtonPostJob>
      <ButtonFindJob
        to="/#candidate"
        className="flex-1 py-3 my-3 px-3 mx-0 md:p-3 md:m-3 text-bold"
      >
        <h2>Find a job</h2>
      </ButtonFindJob>
      <br />
    </div>
  );
}

function ForWho() {
  return (
    <div>
      <div class="p-8 rounded-lg shadow-lg">
        <h2 class="text-2xl font-bold mb-4">For Job Seekers:</h2>
        <ul class="list-disc list-inside mb-4">
          <li>Experience swift AI-driven interviews.</li>
          <li>Receive instant feedback to enhance your approach.</li>
          <li>Explore diverse job opportunities with leading companies.</li>
        </ul>
      </div>

      <div class="p-8 rounded-lg shadow-lg mt-8">
        <h2 class="text-2xl font-bold mb-4">For Employers:</h2>
        <ul class="list-disc list-inside mb-4">
          <li>Post your job in minutes, powered by intelligent AI.</li>
          <li>Streamline your hiring process with efficient AI interviews.</li>
          <li>Access voice recordings for a firsthand assessment.</li>
        </ul>
      </div>

      <div class="p-8 rounded-lg shadow-lg mt-8">
        <h2 class="text-2xl font-bold mb-4">For Referrers:</h2>
        <ul class="list-disc list-inside mb-4">
          <li>
            Earn bounties for successful placements within our collaborative
            ecosystem.
          </li>
          <li>
            Leverage your network and contribute to the future of recruitment.
          </li>
        </ul>
      </div>
    </div>
  );
}

function EventDate() {
  return <span className="font-bold">January 25, 2024</span>;
}

const howItWorksStepsCompanies = [
  {
    step: "01",
    title: "Post your job",
    description: (
      <div>
        No signup and zero configuration. Just talk for 5 minutes with our
        AI-voice to describe your tasks and ideal candidate.
      </div>
    ),
  },
  {
    step: "02",
    description:
      "Receive a list of AI-interviewed candidates with their rankings and the full audio interview transcript.",
    title: "Receive candidates",
    success: true,
  },
  // {
  //   step: "02",
  //   title: "Virtual Speed Recruiting",
  //   description:
  //     "During our Virtual Speed Recruiting event, our AI-voice will find and interview candidates on your behalf.",
  // },
  // {
  //   step: "03",
  //   description:
  //     "Receive a list of AI-interviewed candidates with their rankings and full interview.",
  //   title: "Receive candidates",
  //   success: true,
  // },
];

const HowItWorksCompanies = () => {
  return (
    <div>
      <StepCards
        title="How it works for companies?"
        description=""
        stepsData={howItWorksStepsCompanies}
      />
      Additionnaly you can define a referral bounty for each job you post. We
      recommend a 1000€ bounty but this depends on your industry. Our team of
      headhunters will focus on finding candidates for jobs with the highest
      referral bounties.
    </div>
  );
};

const howItWorksStepsCandidates = [
  {
    step: "01",
    title: "Submit your email",
    description: (
      <div>
        Do a pre-interview with our AI voice to describe your ideal job before{" "}
        <EventDate />.
        <FullButtonLink to="https://ai-cartel.com/apps/ai-recruiter/candidate/view-job/616b58c1f7f742f1aef9836a20941398">
          Join the event
        </FullButtonLink>
      </div>
    ),
  },
  {
    step: "02",
    title: "Virtual Speed Recruiting",
    description: (
      <div>
        On <EventDate />, you will receive an email listing available jobs.
        Please select your preferred roles for an interview with our AI-voice
        system.
      </div>
    ),
  },
  {
    step: "03",
    description:
      "Interested companies will receive your AI-conducted interview and will contact you directly.",
    title: "Receive job offers",
    success: true,
  },
];

const HowItWorksCandidates = () => {
  return (
    <div>
      <StepCards
        title="How it works for candidates?"
        description=""
        stepsData={howItWorksStepsCandidates}
      />
      Additionally, you will receive AI-provided feedback on your job interview.
    </div>
  );
};

const howItWorksStepsReferrers = [
  {
    step: "01",
    title: "Create a referrer account",
    description: <div>TODO Click here to create a referrer account.</div>,
  },
  {
    step: "02",
    title: "Get your referral link",
    description: (
      <div>
        In your profile, you will find a referral link. Share this link with
        your network.
      </div>
    ),
  },
  {
    step: "03",
    description:
      "As soon as the company requests contact with your candidate, you will get paid.",
    title: "Get paid",
    success: true,
  },
];

const HowItWorksReferrers = () => {
  return (
    <div>
      <StepCards
        title="How it works for referrers?"
        description="You have a network of one ore more candidates looking for a job? You can get paid up to 4000€ for referring them to our Virtual Speed Recruiting event."
        stepsData={howItWorksStepsReferrers}
      />
      Additionally, you can also refer companies to our Virtual Speed
      Recruiting. You will receive a 50% referral bounty for each successful
      referral. (eg. 2000€ for a 4000€ bounty).
    </div>
  );
};

const WhyUsStatsData = [
  { title: "Decrease in time to hire", number: "3 weeks" },
  { title: "Increase in new hire diversity", number: "16%" },
  { title: "Return on investment", number: "131%" },
  { title: "Candidate chat conversations", number: "150k" },
];

const StatisticsAIRecruiting = ({ stats }) => {
  return (
    <div className="p-6 bg-dark-100 shadow-lg m-2 border border-dark-200">
      <h2 className="text-2xl md:text-3xl font-bold text-light-100 mb-4 text-center">
        Key Statistics
      </h2>
      <Statistics stats={stats} />
    </div>
  );
};

const WhyUs = () => {
  return (
    <>
      <Title2>
        <h3>Why Us? Save Time, Hire Better</h3>
      </Title2>
      <div>
        Seize the opportunity to connect with an abundance of top-tier talent
        swiftly. By participating in our Speed Recruiting Event, you gain access
        to AICartel – the only platform enabling you to interview up to 100
        candidates in just one day through advanced AI. Experience a recruitment
        revolution with our faster, smarter, and more efficient hiring process.
        Leverage the combined strength of human and AI-driven referrals to boost
        your job posting's reach, ensuring you attract and engage the highest
        quality candidates efficiently.
      </div>
      <StatisticsAIRecruiting stats={WhyUsStatsData} />
      <ReferencesLogo />
    </>
  );
};

// export const HowItWorks = () => {
//   return (
//     <>
//       <Section children={<HowItWorksCompanies />} />
//       <Section id="candidate" children={<HowItWorksCandidates />} />
//       <Section children={<HowItWorksReferrers />} />
//     </>
//   );
// };

export const HowItWorks = () => {
  return (
    <>
      <Section children={<HowItWorksCompanies />} />
    </>
  );
};

export default function VirtualSpeedRecruitingLandingPage() {
  return (
    <div className="max-w-screen-2xl mx-auto px-3">
      <Header id="ai-recruiter-landing-page-header" />
      <Section children={<GlobalInfo />} />
      <Section children={<ForWho />} />
      <HowItWorks />
      <Section id="pricing" children={<Pricing />} />
      <Section id="whyus" children={<WhyUs />} />
      <Section id="faq" children={<FAQ />} />
      <Section id="about" children={<About />} />
      <Section id="contact" children={<Contact />} />
      <div className="pb-24 sm:pb-64"></div>
      <BottomBanner neighborID="ai-recruiter-landing-page-header">
        <CallToAction className="text-dark-300" reverse />
      </BottomBanner>
    </div>
  );
}
