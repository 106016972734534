import React from "react";
import About from "pages/About";
import Contact from "pages/Contact";
import {
  FullButtonLink,
  BorderButtonLink,
  BottomBanner,
  Title2,
  Section,
} from "components/DesignComponents.js";
import {
  HeaderDesignV2,
  HeaderShortMessages,
  HeaderShortMessagesItem,
} from "components/HeaderDesigner.js";
// import FAQ from "../pages/FAQ";
// import Pricing from "./Pricing";
import PitchMeProcess from "./PitchMeProcess";
import Investors from "./landingpage/Investors";

function Header(props) {
  return (
    <div
      className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      {...props}
    >
      <div className="mb-16 md:mb-0 md:max-w-xl sm:mx-auto md:text-center">
        <HeaderDesignV2>
          <h1 className="mb-5 font-sans s-text-4xl font-bold tracking-tight text-light-100 sm:leading-none">
            PitchMe{" "}
            <span className="text-color-primary-gradient">connects</span>
            <br />
            founders and investors
          </h1>
          <h2 className="mb-5 text-light-300 s-text-base">
            Stop wasting time pitching investors one by one – PitchMe once and
            investors come to you. <br />
          </h2>
        </HeaderDesignV2>
        <CallToAction />
      </div>
    </div>
  );
}

function ShortHeader3(props) {
  return (
    <HeaderShortMessages {...props}>
      <HeaderShortMessagesItem title="For founders">
        Simplify your search for investors and get your startup funded
        efficiently.
      </HeaderShortMessagesItem>

      <HeaderShortMessagesItem title="For investors">
        No more useless long presentations. Just listen the 30 seconds pitch and
        ask the key questions.
      </HeaderShortMessagesItem>
    </HeaderShortMessages>
  );
}

function CallToAction({ className = "", reverse = false }) {
  const ButtonPostPitch = reverse ? BorderButtonLink : FullButtonLink;
  const ButtonFindPitch = reverse ? FullButtonLink : BorderButtonLink;

  return (
    <div className={`flex place-content-center gap-3 ${className}`}>
      <ButtonPostPitch
        to="/apps/pitchme/founder/pitch"
        className="flex-1 py-3 my-3 px-3 mx-0 md:p-3 md:m-3"
      >
        <h2>PitchMe</h2>
      </ButtonPostPitch>
      <ButtonFindPitch
        to="/apps/pitchme/investor/find-pitch"
        className="flex-1 py-3 my-3 px-3 mx-0 md:p-3 md:m-3 text-bold"
      >
        <h2>Find startups</h2>
      </ButtonFindPitch>
      <br />
    </div>
  );
}

const WhoAreTheInvestors = () => {
  return (
    <div className="text-light p-6 md:p-12">
      <h1 className="text-2xl md:text-4xl font-bold" id="gt">
        Who are the investors?
      </h1>
      <div>
        It's a very small groups of investors that I know personally and very
        few investors that subscribed to this plateform. The more you share this
        platform the more investors will subscribe and the more chances you have
        to get funded.
      </div>
    </div>
  );
};

export default function PitchMeLandingPage() {
  return (
    <div className="max-w-screen-2xl mx-auto px-3">
      <Header id="pitchme-landing-page-header" />
      <Section children={<PitchMeProcess />} />
      <Section children={<ShortHeader3 />} />
      <Section children={<WhoAreTheInvestors />} />
      <Section children={<Investors />} />
      <Section id="about" children={<About />} />
      <Section id="contact" children={<Contact />} />
      <div className="pb-24 sm:pb-64"></div>
      <BottomBanner neighborID="pitchme-landing-page-header">
        <CallToAction className="text-dark-300" reverse />
      </BottomBanner>
    </div>
  );
}
