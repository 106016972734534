import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import ReactAudioPlayer from "react-audio-player";
import useApi from "utils/Api";
import { Centered, Title3 } from "components/DesignComponents";
import useAsyncData from "utils/NetworkUtils";
import { formatDayMonth } from "utils/DateUtils";
import { AdminButton } from "components/Buttons";
import { DeleteButton, PlayButton } from "components/IconButtons";
import { Link } from "react-router-dom";
import ConfirmedEmail from "features/ConfirmedEmail";

const PitchCard = ({ pitch, index, setRefreshCallback, playAudio }) => {
  const { apiPitchMeAdminDeletePitch, apiPitchMeSavePitch } = useApi();
  const {
    created_at,
    email,
    one_line,
    founder_name,
    status,
    company_name,
    uuid,
    link,
    discussion_duration,
    mail_confirmed,
  } = pitch;

  const doDelete = async () => {
    const { status } = await apiPitchMeAdminDeletePitch(uuid);
    if (status === "ok") {
      toast.success(`Successfully deleted pitch for ${email}`);
      setRefreshCallback((prev) => !prev);
    }
  };

  const save = async () => {
    const { status } = await apiPitchMeSavePitch(uuid);
    if (status === "ok") {
      toast.success(`Successfully updated`);
      setRefreshCallback((prev) => !prev);
    }
  };

  return (
    <Link to={`/admin/pitches/view-pitch/${uuid}`}>
      <div className="bg-dark hover:bg-dark-200 border border-dark-200 text-secondary p-4 relative overflow-hidden my-2">
        <div className="flex justify-between">
          <div>
            <h2 className="font-bold text-light-100">
              {index + 1}. {company_name} by {founder_name}
            </h2>
            <div className="text-sm text-light-300">
              <ConfirmedEmail email={email} mail_confirmed={mail_confirmed} />
              {formatDayMonth(created_at)} ·{status} ·
              <span className="text-xs"> ({discussion_duration} min)</span>
            </div>
            <div>{one_line}</div>
            <div>{link}</div>
          </div>

          <div className="flex flex-row justify-end items-center gap-2 mt-4">
            <AdminButton onClick={() => save(uuid)}>Save Changes</AdminButton>
            <PlayButton onClick={() => playAudio(uuid)} />
            <DeleteButton onClick={doDelete} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default function AdminPitches() {
  const [refresh, setRefresh] = useState(true);
  const { apiPitchMeAdminAllPitches, apiGetAudio } = useApi();
  const pitches = useAsyncData(apiPitchMeAdminAllPitches, refresh);
  const [currentPlayedAudioUrl, setCurrentPlayedAudioUrl] = useState(null);

  const playAudio = useCallback(
    async (uuid) => {
      try {
        const mp3Blob = await apiGetAudio(uuid);
        const audioUrl = URL.createObjectURL(mp3Blob);
        setCurrentPlayedAudioUrl(audioUrl);
      } catch (error) {
        console.log(error);
        toast.error("Error playing audio");
      }
    },
    [apiGetAudio]
  );

  if (!pitches) {
    return <div>Loading...</div>;
  }
  return (
    <section className="border-t border-gray-800 py-1">
      <Title3>Admin Pitches</Title3>
      <Centered>
        <ReactAudioPlayer
          id="audio"
          src={currentPlayedAudioUrl}
          autoPlay
          controls
          className="py-4"
        />
        <div className="space-y-4">
          {pitches.map((pitch, index) => (
            <PitchCard
              key={pitch.uuid}
              pitch={pitch}
              index={index}
              setRefreshCallback={setRefresh}
              playAudio={playAudio}
            />
          ))}
        </div>
      </Centered>
    </section>
  );
}
