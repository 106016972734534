import { useState } from "react";
import useApi from "../utils/Api";
import { Title } from "../components/DesignComponents";
import { Dropdown } from "../components/Dropdown";
import { Input } from "../components/DesignComponents";
import ErrorMessages from "../components/ErrorMessages";

function WaitingListForm({ setRequestResponse, ...props }) {
  const { apiJoinWaitlist } = useApi();
  const applications = [
    "AI Marketplace",
    "AI Project Manager",
    "AI Recruiter",
    "PitchMe",
  ];
  const [applicationName, setApplication] = useState("");
  const [email, setEmail] = useState("");

  const handleJoinWaitlist = async (event) => {
    event.preventDefault();
    const { status, message } = await apiJoinWaitlist(email, applicationName);
    setRequestResponse({ status, message });
  };

  return (
    <div {...props}>
      <div className="p-3">
        Join the waitlist to access this application before the public release.
      </div>
      <form
        className="flex flex-col items-center"
        onSubmit={handleJoinWaitlist}
      >
        <Input
          label="Email"
          placeholder="Enter your email"
          value={email}
          onChange={setEmail}
        />
        <label className="block mt-4">
          <span className="px-10">Wait list for</span>
          <Dropdown
            label="Application"
            options={applications}
            onSelect={setApplication}
            value={applicationName}
            className="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          />
        </label>

        <label className="block mt-4">
          <input
            type="checkbox"
            name="accept_terms"
            required
            className="mr-2"
          />
          {" I accept the "}
          <a
            href="/assets/service_policy_en.html"
            target="_blank"
            className="text-indigo-500"
          >
            Privacy Policy and Terms of Use
          </a>
        </label>

        <button
          type="submit"
          className="m-2 p-2 font-extrabold border-secondary border-2 rounded-none hover:text-primary hover:bg-secondary"
        >
          Join waitlist
        </button>
      </form>
    </div>
  );
}

function SuccessMessage() {
  return (
    <div>
      Your waitlist request was successful !
      <br />
      Please check your email for further instructions.
    </div>
  );
}

export default function WaitlistForm(props) {
  const [requestResponse, setRequestResponse] = useState(null);

  return (
    <>
      <Title className={"pb-8"}>
        <h1>Coming soon... Join the waitlist!</h1>
      </Title>
      <ErrorMessages
        requestResponse={requestResponse}
        successMessage={<SuccessMessage />}
      >
        <WaitingListForm setRequestResponse={setRequestResponse} />
      </ErrorMessages>
    </>
  );
}
