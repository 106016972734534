import { QueryAndShowJobs } from "apps/ai-debate-trainer/JobListing";
import { ReferrerExplanation } from "./ReferrerStats";

export default function ReferrerFindBounty() {
  return (
    <>
      <ReferrerExplanation />
      <QueryAndShowJobs show_bounty_only={true} isReferrerView />
    </>
  );
}
