import React, { useCallback, useState } from "react";
import { AboutSanson } from "./About";
import Contact, { SocialNetworkIconWithText } from "./Contact";
import {
  Title2,
  Section,
  Gradient,
  Input,
  FullButton,
} from "../components/DesignComponents.js";
import { HeaderDesignV2 } from "../components/HeaderDesigner.js";
import { useGameContext } from "debate/GameContext";
import ErrorMessages from "components/ErrorMessages";
import PrivacyCheckbox from "components/PrivacyCheckbox";
import useMotivatorApi from "apps/motivator/MotivatorApi";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import CollapsibleDiv from "features/CollapsibleDiv";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

// //You are charged the referral bounty only when you accept a candidate. Set to 0€ for free candidates. Increase the bounty for more human recruiter assistance.
//
// used template
// https://www.tailwindawesome.com/resources/astrolus/demo
//
//
// <VideoPlayer
//   src="/assets/video/ai_recruiter_demo_07_11_2023_en.mp4"
//   poster="/assets/img/ai_recruiter_2.webp"
// ></VideoPlayer>

// <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
function Header(props) {
  return (
    <div
      className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      {...props}
    >
      <div className="mb-16 md:mb-0 md:max-w-xl sm:mx-auto md:text-center">
        <HeaderDesignV2>
          <h1 className="my-5 font-sans s-text-4xl font-bold tracking-tight text-light-100 sm:leading-none">
            Realize your <Gradient>dreams</Gradient>
            <br />
            with AI motivator
          </h1>
          <h2 className="mb-5 text-light-300 s-text-base">
            Luna calls you for 5 minutes each week to motivate you toward your
            life purpose. <br />
            Be your best self. For free!
          </h2>
        </HeaderDesignV2>

        <CollapsibleDiv isCollapsed={true} title="Start Demo Call">
          <StartTestForm />
        </CollapsibleDiv>
      </div>
    </div>
  );
}

// Button receive a demo call now

function StartTestForm() {
  const { apiCreateMotivator } = useMotivatorApi();
  const [captcha, setCaptcha] = useState(null);
  const {
    email,
    setEmail,
    saveEmail,
    phoneNumber,
    setPhoneNumber,
    playerName,
    setPlayerName,
  } = useGameContext();
  const [requestResponse, setRequestResponse] = useState(null);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!captcha) {
        setRequestResponse({
          status: "error",
          message: "Please complete the captcha.",
        });
        return;
      }
      saveEmail();

      const { status, message } = await apiCreateMotivator(
        playerName,
        phoneNumber,
        email,
        captcha
      );

      setRequestResponse({ status, message });
    },
    [email, playerName, setRequestResponse, phoneNumber, captcha, saveEmail]
  );

  return (
    <ErrorMessages
      requestResponse={requestResponse}
      successMessage=<div>
        You will receive a call in a few seconds.{" "}
        <div className="text-red-600 text-base">
          If you missed the call, do it again!{" "}
          <SocialNetworkIconWithText
            href="https://discord.gg/ZGTMRyU2JR"
            src="/assets/social_network_icons/discord.svg"
            alt="Discord"
            text=" For bugs or feedback, please contact us on Discord."
          />
        </div>
      </div>
    >
      <form
        className="grid justify-items-center p-4 border"
        onSubmit={handleSubmit}
      >
        <Input
          label="Firstname"
          name="Firstname"
          value={playerName}
          onChange={setPlayerName}
          required
        />
        <PhoneInput
          country={"us"}
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
        <Input
          label="Email"
          name="email"
          value={email}
          onChange={setEmail}
          required
        />
        <PrivacyCheckbox />
        <div className="m-4">
          <ul>
            <GreenBullet>No Signup.</GreenBullet>
            <GreenBullet>No Card.</GreenBullet>
            <GreenBullet>No Config.</GreenBullet>
          </ul>
        </div>

        <ReCAPTCHA
          sitekey="6LffKdYpAAAAADpO-b53XttWWS-EJlbQbTjp21Sh"
          theme="light"
          type="image"
          size="normal"
          tabIndex={0}
          onChange={setCaptcha}
        />
        <FullButton type="submit" propagate>
          Start Demo Call Now
        </FullButton>
      </form>
    </ErrorMessages>
  );
}

function GreenBullet({ children }) {
  return (
    <li lassName="flex items-center">
      <span className="text-green-500 mr-2">✔</span>
      {children}
    </li>
  );
}

function RedBullet({ children }) {
  return (
    <li lassName="flex items-center">
      <span className="text-red-500 mr-2">✔</span>
      {children}
    </li>
  );
}

function PainPoint() {
  return (
    <div className={`p-4 shadow-md`}>
      <Title2>
        <h2 className="s-text-xl font-semibold">Pain point</h2>
      </Title2>
      This project that you never finished. This promise to yourself that you
      forgot. This dream that ...
      <br />
      No AI, no one will realize your dreams for you. But what if there was a
      way to remind and motivate the future version of yourself?
      <br />
      Luna helps you reach your goals, like losing weight, starting your own
      business, having better grades or writing a book... and more.
    </div>
  );
}

function HowItWorks() {
  return (
    <div className={`p-4 shadow-md`}>
      <Title2>
        <h2 className="s-text-xl font-semibold">How it works?</h2>
      </Title2>
      Just fill in your details above, click start, and wait for your first
      call. It's easy! Don't worry, we keep your information private. We only
      share anonymous data about satisfaction.
      <br />
      If you want to stop, just tell Luna 'stop calling me'.
      <br />
      Luna will ask you about your preferred day and time for the next call.
    </div>
  );
}

function Psychology() {
  return (
    <div className={`p-4 shadow-md`}>
      <Title2>
        <h2 className="s-text-xl font-semibold">Psychology</h2>
      </Title2>
      The first goal of this social experiment is to explore the psychological
      principles that can be leveraged by AI. AI utilizes a combination of
      psychological principles remind individuals of their self-defined
      objectives:
      <ul>
        <GreenBullet>
          Priming: This involves the subconscious activation of relevant mental
          associations.
        </GreenBullet>

        <GreenBullet>
          Behavioral Conditioning / Reward System Activation: AI encourages
          behaviors aligned with the pursuit of one's goals, thereby
          strengthening the likelihood of their achievement.
        </GreenBullet>

        <GreenBullet>
          External Stimuli: By introducing external cues, AI influences
          individuals' cognitive processes to increase commitment to their
          goals.
        </GreenBullet>

        <GreenBullet>
          Positive Affirmation / Manifestation: AI delivers supportive messages
          that boost individuals' confidence.
        </GreenBullet>
      </ul>
      <br />
      To contribute to this study, we introduce three novel concepts within
      psychology:
      <ul>
        <GreenBullet>
          Artificial Positivity Contagion: Examining the dissemination of
          positivity from AI to humans.
        </GreenBullet>
        <GreenBullet>
          Artificial Social Support: Investigating the provision of social
          support by AI.
        </GreenBullet>
        <GreenBullet>
          Artificial Social Validation: Exploring the phenomenon of individuals
          seeking validation from an AI.
        </GreenBullet>
      </ul>
    </div>
  );
}

// //
function Why() {
  return (
    <div className={`p-4 shadow-md`}>
      <Title2>
        <h2 className="s-text-xl font-semibold">Why?</h2>
      </Title2>
      As you know, a lot of creators are imaginative and talented, but we are
      also procrastinators.
      <br />
      Using AI phone call as an external stimulus to guide us towards our life
      purpose is a new concept, similar to having a second brain for motivation.
      This social experiment will measure how much positive effect a 5-minute
      weekly AI call can have on a person's life. And it's free as long as I can
      afford the AI expenses! Please don't abuse it.
    </div>
  );
}

function Details() {
  return (
    <div className={`p-4 shadow-md`}>
      <Title2>
        <h2 className="s-text-xl font-semibold">Details</h2>
      </Title2>
      <ul>
        <RedBullet>
          I'm not a coach. I'm not a therapist. I'm not a mentor.
        </RedBullet>
        <RedBullet>
          If you have mental health issues, please consult a professional. This
          is mainly a social experiment.
        </RedBullet>
        <RedBullet>Calls canno't exceed 5 minutes.</RedBullet>
        <GreenBullet>
          You can ask Carlson to remind you of something: For example, remind me
          that I need to buy a gift for my father in December.
        </GreenBullet>
        <GreenBullet>
          At the end of the month, you will receive an email about your progress
          to keep you motivated.
        </GreenBullet>
        <GreenBullet>
          You can ask us to connect you with like-minded people who have the
          same goals as you, to boost your motivation.
        </GreenBullet>
      </ul>
    </div>
  );
}

export default function PurposeLandingPage() {
  return (
    <div className="max-w-screen-2xl mx-auto px-3">
      <Helmet>
        <title>AI Motivator: Realize your dreams with AI support.</title>
      </Helmet>
      <Header id="ai-recruiter-landing-page-header" />
      <Section children={<PainPoint />} />
      <Section children={<Why />} />
      <Section children={<HowItWorks />} />
      <Section children={<Psychology />} />
      <Section children={<Details />} />
      <Section id="about" children={<AboutSanson />} />
      <Section id="contact" children={<Contact />} />
      <div className="pb-24 sm:pb-64"></div>
    </div>
  );
}

//
// This is a new form of positive
//   affirmation to condition your brain to achieve your goal. In psychology,
//   there is a concept called 'priming.' Essentially, when a thought is
//   brought to mind, the brain becomes more likely to take action on that
//   thought.
//   <br />
//   If there are weeks where you feel stuck, that's okay. Carlson will be
//   there to give you positive energy and motivation. Whatever happens, you
//   will be proud of yourself in 1 year. This social experiment project was
//   built in 24 hours for fun during a deep work session.
// I will not force you

// <br />
// The AI does priming, just by reminding you of the goals that you set to
// yourself. The AI does behavioral conditioning by giving you positive
// feedback on your progress. The AI gives you a positive perspective about
// whatever it is you did or did not do. It will do its best to motivate you.
// If you feel stuck or down, it's okay to feel that way; acknowledging and
// accepting your emotions is an important step towards growth and
// self-awareness.

//       'priming' and 'behavioral conditioning'. Just fill in your details above,
//       click start, and wait for your first call. It's easy! Don't worry, we keep
//       your information private. We only share anonymous data about satisfaction.
//       <br />
//       If you want to stop, just tell Carlson 'stop calling me'.
//       <br />
//       Carlson will ask you about your preferred day and time for the next call.
//       <br />
//       The AI does priming, just by reminding you of the goals that you set to
//       yourself. The AI does behavioral conditioning by giving you positive
//       feedback on your progress. The AI gives you a positive perspective about
//       whatever it is you did or did not do. It will do its best to motivate you.
//       If you feel stuck or down, it's okay to feel that way; acknowledging and
//       accepting your emotions is an important step towards growth and
//       self-awareness.
//     </div>
//   );
// }

// <br />
// AI does nothing, you are doing everything, you already know what to do.
// And you are using this AI to remind the next version of yourself what is
// important to you, to keep you on track.
