import useApi from "utils/Api";
import { Centered } from "components/DesignComponents";
import { useDynamicQuery } from "utils/NetworkUtils";
import { JobListing } from "apps/ai-debate-trainer/JobListing";
import { HandleHTTPErrors2 } from "components/ErrorMessages";

export default function CompanyMyJobs() {
  const { apiAIRecruiterMyJobs } = useApi();

  const requestsState = useDynamicQuery(
    "company-my-jobs",
    apiAIRecruiterMyJobs
  );
  const data = requestsState.data?.jobs;

  return (
    <Centered className="pb-4">
      <HandleHTTPErrors2 {...requestsState}>
        <JobListing
          jobs={data}
          to={(job) => `view-job/${job.uuid}`}
          isCompanyView
          setRefreshCallback={requestsState.refetch}
        />
      </HandleHTTPErrors2>
    </Centered>
  );
}
