import React from "react";

const StepCard = ({ step, title, description }) => {
  return (
    <div className="text-dark flex-1 bg-white w-full h-full rounded-lg">
      <div className="p-4 lg:p-8 w-full h-full">
        <div className="text-sm lg:text-xl">{step}</div>
        <div className="text-3xl my-6 lg:text-4xl xl:text-5xl lg:mt-8" id="gt">
          {title}
        </div>
        <div className="text-sm lg:text-xl mt-1">{description}</div>
      </div>
    </div>
  );
};

const stepsData = [
  {
    step: "01",
    title: "Far away from home, yet close to the heart.",
    description:
      "Imagine the story of a 20-year-old kid in a faraway country. He is smart and trying to change the world in a positive way. But he cannot travel and he doesn't speak your language. PitchMe will bring this kid to you using AI.",
  },
  {
    step: "02",
    title: "Your lifetime is precious.",
    description:
      "Imagine taking a 4-hour plane ride to meet 10 founders who are not interesting. With Pitch Me, you can skip an investor within 3 seconds, and you can review 10 founders within 30 minutes directly from home.",
  },
  {
    step: "03",
    title: "The roots of morale exist in philosophy.",
    description: "Are we a part of the universe experiencing itself?",
  },
];

const Investors = () => {
  return (
    <div className="text-light p-6 md:p-12">
      <h1 className="text-2xl md:text-4xl font-bold" id="gt">
        Why I created PitchMe?
      </h1>
      <div className="mt-6 md:mt-12 grid md:grid-cols-3 justify-evently items-center gap-8">
        {stepsData.map((step, index) => (
          <StepCard
            key={index}
            step={step.step}
            title={step.title}
            description={step.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Investors;
