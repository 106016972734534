import { Centered } from "../components/DesignComponents";
import WaitlistForm from "./WaitlistForm";

export default function ComingSoon() {
  return (
    <>
      <Centered>
        <WaitlistForm className="mt-16 border p-10" />
      </Centered>
    </>
  );
}
