import React, { useCallback, useEffect, useState } from "react";
import useApi from "utils/Api";
import {
  CheckboxLeft,
  FullButton,
  Input,
  PhoneInput,
  TextArea,
} from "components/DesignComponents";

import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { InputSignal } from "components/InputSignal";
import { useGameContext } from "debate/GameContext"; // THIS
import { Title } from "components/DesignComponents";
import PrivacyCheckbox from "components/PrivacyCheckbox";
// import { GenericViewJob } from "../ViewJob";
import {
  PostJobContextProvider,
  usePostJobContext,
} from "../context/PostJobContext";
import { toast } from "react-toastify";
import "style.css";
import useTwitterPixel from "utils/useTwitterPixel";
import { Dropdown } from "components/Dropdown";
import ErrorMessages from "components/ErrorMessages";
import { useLocation } from "react-router-dom";
import { AuthRequired } from "auth/Login";
import { useSpeedRecruitingList } from "../candidate/VirtualSpeedRecruiting";
import Tooltip from "components/output/Tooltip";
import ReCAPTCHA from "react-google-recaptcha";

function Step1_PostJobDescription({ isSpeedRecruiting }) {
  const location = useLocation();
  const { apiAIRecruiterCreateJob } = useApi();
  const {
    email,
    setEmail,
    saveEmail,
    setJobUUID,
    jobDescription,
    setJobDescription,
    companyName,
    setCompanyName,
    currentStep,
    setCurrentStep,
    speedRecruiting,
    setSpeedRecruiting,
    referralBounty,
    setReferralBounty,
  } = usePostJobContext();
  const { phoneNumber, setPhoneNumber } = useGameContext();
  // const languageList = useAsyncData(apiGetLanguageList);
  const speedRecruitingList = useSpeedRecruitingList();
  const [requestResponse, setRequestResponse] = useState(null);
  const [captcha, setCaptcha] = useState(null);
  const captchaEnabled = false;

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!captcha && captchaEnabled) {
        setRequestResponse({
          status: "error",
          message: "Please complete the captcha.",
        });
        return;
      }
      saveEmail();
      const applicationName = "AIRecruiter - post a job";
      const language = "English (US)";
      const is_public = true;
      const requiredArgs = {
        company_name: companyName,
        job_description: jobDescription,
        bounty: referralBounty,
        phone_number: phoneNumber,
      };
      if (phoneNumber.length < 4) {
        toast.error("Please enter a valid phone number");
        return;
      }

      const {
        status,
        game_uuid: uuid,
        message,
      } = await apiAIRecruiterCreateJob(
        applicationName,
        email,
        language,
        is_public,
        captcha,
        requiredArgs
      );
      setRequestResponse({ status, message });
      if (status === "ok") {
        setJobUUID(uuid);
        setCurrentStep(currentStep + 1);
      } else {
        console.log("createDiscussion error", message);
      }
    },
    [
      setCurrentStep,
      setJobUUID,
      currentStep,
      email,
      companyName,
      jobDescription,
      setRequestResponse,
      speedRecruiting,
      referralBounty,
      phoneNumber,
      captcha,
    ]
  );

  return (
    <ErrorMessages
      requestResponse={requestResponse}
      successMessage="Continue to the next step"
    >
      <form className="grid justify-items-center" onSubmit={handleSubmit}>
        <Input
          label="Company name"
          name="CompanyName"
          value={companyName}
          onChange={setCompanyName}
          required
        />
        <PhoneInput
          country={"us"}
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
        {isSpeedRecruiting &&
          (speedRecruitingList ? (
            <Dropdown
              label="Virtual Speed Recruiting event :"
              options={speedRecruitingList}
              onSelect={setSpeedRecruiting}
              value={speedRecruiting}
              required
            />
          ) : (
            <div>loading...</div>
          ))}
        <Tooltip
          text="You are charged the referral bounty only when you accept a candidate. The higher the bounty, the more head hunters you will attract."
          className="w-full max-w-xs flex m-3"
        >
          <InputSignal
            label="Referral Bounty €"
            name="bounty"
            value={referralBounty}
            onBlur={setReferralBounty}
            required
          />
          <div className="flex items-center">
            <InformationCircleIcon className="h-7 w-7" />
          </div>
        </Tooltip>
        <TextArea
          name="jobDescription"
          label="Job description"
          value={jobDescription}
          onChange={setJobDescription}
        />
        <PrivacyCheckbox />
        <CheckboxLeft
          name="accept_call"
          label="I accept to receive a call from AI Cartel to discuss the job description."
          setValue={() => {}}
          required
        />
        <div className="m-4">
          Post your job and let AI connect you with the best candidates for your
          company. <br />
        </div>

        {captchaEnabled ?? (
          <ReCAPTCHA
            sitekey="6LffKdYpAAAAADpO-b53XttWWS-EJlbQbTjp21Sh"
            theme="light"
            type="image"
            size="normal"
            tabIndex={0}
            onChange={setCaptcha}
          />
        )}
        <FullButton type="submit" propagate>
          Post job
          <span className="text-xs -translate-y-3"> Free</span>
        </FullButton>
      </form>
    </ErrorMessages>
  );
}

// function Step2_AnswerAIRecruiter() {
//   const [AiAnswer, setAiAnswer] = useState(null);
//   const [UserAnswer, setUserAnswer] = useState(null);
//
//   const { currentStep, setCurrentStep } = usePostJobContext();
//
//   const onAbortDiscussion = async (totalMinutes) => {
//     if (false) {
//       // totalMinutes < 2) {
//       toast.warning(`Please answer at least 2 minutes of questions`);
//     } else {
//       setCurrentStep(currentStep + 1);
//     }
//   };
//
//   const onDiscussionFinished = async () => {
//     // stopGame();
//     setCurrentStep(currentStep + 1);
//   };
//
//   function onAIAnswerFinished(ai_answer, user_answer) {
//     setAiAnswer(ai_answer);
//     setUserAnswer(user_answer);
//   }
//
//   return (
//     <>
//       <AICartelPlayer
//         end_button_text="Post the job"
//         applicationName={"AIRecruiter - post a job"}
//         onAbortDiscussion={onAbortDiscussion}
//         onDiscussionFinished={onDiscussionFinished}
//         onAIAnswerFinished={onAIAnswerFinished}
//         instruction="Provide detailed information about the position to the AI Recruiter."
//       ></AICartelPlayer>
//       <ShowDiscussion
//         ai_answer={AiAnswer}
//         user_answer={UserAnswer}
//         playerName="You"
//       />
//     </>
//   );
// }

// function Step3_HandlePayment() {
//   const { speedRecruiting, setCurrentStep, currentStep, jobUUID } =
//     usePostJobContext();
//   const { apiAIRecruiterJobPay } = useApi();
//   const requestsState = useDynamicQuery("pay-job", apiAIRecruiterJobPay, {
//     uuid: jobUUID,
//   });
//
//   const checkoutUrl = requestsState.data?.url;
//
//   useEffect(() => {
//     if (checkoutUrl == "no payment required") {
//       setCurrentStep(currentStep + 1);
//     }
//   }, [checkoutUrl, setCurrentStep, currentStep]);
//
//   return (
//     <HandleHTTPErrors2 {...requestsState}>
//       <div className="grid justify-items-center">
//         <h2>
//           <Title2>Virtual Speed Recruiting: {speedRecruiting}</Title2>
//         </h2>
//         <div>
//           To complete the job posting process, please submit the fixed fee of{" "}
//           <Bold>{VSR_job_posting_price} €</Bold>. Once you request candidates'
//           contact information, you will be charged for the referral bounty fee,
//           which will be shared with the headhunters/referrers.
//         </div>
//         {checkoutUrl && (
//           <FullButtonLink to={checkoutUrl}>Continue</FullButtonLink>
//         )}
//       </div>
//     </HandleHTTPErrors2>
//   );
// }

function Step4_GetAIRecruiterLink() {
  return (
    <div className="grid justify-items-center">
      <Title className="text-green-600">
        SUCCESS: You will receive a call within 5 minutes from our AI Recruiter.
      </Title>
    </div>
  );
}

function PostJobMain({ isSpeedRecruiting }) {
  const { currentStep } = usePostJobContext();
  const { sendTwitterConversion } = useTwitterPixel();

  useEffect(() => {
    sendTwitterConversion();
  }, []);

  return (
    <div className="flex flex-col place-content-center">
      {currentStep == 1 && (
        <Step1_PostJobDescription isSpeedRecruiting={isSpeedRecruiting} />
      )}
      {currentStep == 2 && <Step4_GetAIRecruiterLink />}
    </div>
  );
}

// {currentStep == 2 && <Step2_AnswerAIRecruiter />}
// {currentStep == 3 && <Step3_HandlePayment />}

export function PostAJob({ isSpeedRecruiting = false }) {
  return (
    <AuthRequired>
      <PostJobContextProvider>
        <PostJobMain isSpeedRecruiting={isSpeedRecruiting} />
      </PostJobContextProvider>
    </AuthRequired>
  );
}
