import { useGameContext } from "debate/GameContext";
import { ToggleButton } from "components/input/ToggleButton";

export function ToggleAdminButton() {
  const { enableAdmin, setEnableAdmin, adminSecret } = useGameContext();

  const toggleAdmin = () => {
    setEnableAdmin(!enableAdmin);
  };

  if (adminSecret === "") {
    return null;
  }
  return (
    <ToggleButton
      isEnabled={enableAdmin}
      onToggle={toggleAdmin}
      label="Admin"
    />
  );
}

// <div className="flex items-center justify-center">
//   <label htmlFor="toggle" className="flex items-center cursor-pointer">
//     <div className="relative">
//       <input
//         type="checkbox"
//         id="toggle"
//         className="sr-only"
//         checked={enableAdmin}
//         onChange={toggleAdmin}
//       />
//       <div
//         className={`block w-14 h-8 rounded-full transition-colors ${
//           enableAdmin ? "bg-green-500" : "bg-dark-300"
//         }`}
//       ></div>
//       <div
//         className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition transform ${
//           enableAdmin ? "translate-x-6" : ""
//         }`}
//       ></div>
//     </div>
//     <span className="ml-3 text-light-300 font-medium">Admin</span>
//   </label>
// </div>
