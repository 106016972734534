import useAuthenticatedApi from "utils/AuthenticatedApi.js";

export default function useReferrerApi() {
  const { useAxiosQuery, axiosPostSilence } = useAuthenticatedApi();
  function useApiGetCode() {
    return useAxiosQuery(
      "referrer-get-code",
      "/api/apps/ai-recruiter/referrer/get-code",
      {}
    );
  }

  function apiEnableReferrer() {
    return axiosPostSilence("/api/apps/ai-recruiter/referrer/enable", {});
  }

  function apiToogleNewBountyEmails(refetch = () => {}) {
    const result = axiosPostSilence(
      "/api/apps/ai-recruiter/referrer/toogle-new-bounty-emails",
      {}
    );
    refetch();
    return result;
  }
  return { useApiGetCode, apiEnableReferrer, apiToogleNewBountyEmails };
}
