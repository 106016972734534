import React, { Component, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GameContextProvider } from "./debate/GameContext";

import {
  BrowserRouter as Router,
  Outlet,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// import Debate from "./debate/Debate";
// import GameView from "./debate/GameView";
// import Settings from "./debate/Settings";
// import Play from "./debate/Play";
// import Scoreboard from "./debate/Scoreboard";

import "./App.css";
import NavBar from "./components/NavBar";

import { AIRecruiterRoutes } from "./apps/ai-recruiter/AIRecruiter";
import ComingSoon from "./pages/ComingSoon";
import MagicLinkRoutes from "./pages/MagicLink";
import AdminRoutes from "./admin/Admin";
import AIRecruiterLandingPage from "./pages/AIRecruiterLandingPage";
import MotivatorLandingPage from "pages/MotivatorLandingPage";
import PitchMeLandingPage from "./apps/pitch-me/PitchMeLandingPage";
import { Login, Logout } from "./auth/Login";
import { Register } from "./auth/Register";
import DeisgnHelper from "./components/DesignHelper";
import useApi from "./utils/Api";
import Demo from "./Demo2";
// import TwitterContest from "TwitterContest";
// import { PitchMeRoutes } from "apps/pitch-me/PitchMe";
import StripeSuccess from "components/StripeSuccess";
import VirtualSpeedRecruitingLandingPage from "pages/VirtualSpeedRecruitingLandingPage";
import { GenericDiscussionRoutes } from "apps/generic-discussion/DoDiscussion";
import { ToggleAdminButton } from "admin/ToggleAdmin";
import { ViralTikTokRoutes } from "apps/viral-tiktok/ViralTikTok";

function NoRouteMatch() {
  return <div>404: No route match</div>;
}

// <Route path="/" element={<LandingPage />} />
function DebugFlush() {
  const { apiDebugFlush } = useApi();
  useEffect(() => {
    apiDebugFlush();
  }, []);
  return <div>Flushed</div>;
}

const queryClient = new QueryClient();

class App extends Component {
  render() {
    return (
      <div className="text-light-100 bg-dark s-text-base pb-32">
        <QueryClientProvider client={queryClient}>
          <Router>
            <GameContextProvider>
              <NavBar />
              <div className="pt-32" />
              <ToggleAdminButton />
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/ai-recruiter" replace />}
                />

                <Route
                  path="/ai-recruiter"
                  element={<AIRecruiterLandingPage />}
                />

                <Route path="/dreams" element={<MotivatorLandingPage />} />
                <Route path="/pitchme" element={<PitchMeLandingPage />} />

                <Route path="/demo" element={<Demo />} />

                <Route path="/debug/flush" element={<DebugFlush />} />

                <Route path="/magic_link/*" element={<MagicLinkRoutes />} />
                <Route path="/admin/*" element={<AdminRoutes />} />
                <Route path="/design/*" element={<DeisgnHelper />} />

                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />

                <Route path="/stripe-success" element={<StripeSuccess />} />

                <Route
                  path="virtual-speed-recruiting"
                  element={<VirtualSpeedRecruitingLandingPage />}
                />
                <Route path="apps">
                  <Route
                    path=""
                    element={<Navigate to="/apps/ai-recruiter/" replace />}
                  />
                  <Route
                    path="ai-recruiter/*"
                    element={<AIRecruiterRoutes />}
                  />
                  <Route
                    path="viral-tiktok/*"
                    element={<ViralTikTokRoutes />}
                  />
                  <Route path="gd/*" element={<GenericDiscussionRoutes />} />
                </Route>

                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="*" element={<NoRouteMatch />} />
              </Routes>
            </GameContextProvider>
          </Router>

          <ToastContainer style={{ whiteSpace: "pre-line" }} />

          <Outlet />
        </QueryClientProvider>
      </div>
    );
  }
}

// <Route path="pitchme/*" element={<PitchMeRoutes />} />

// <Route
//   path="/event/websummit_2023"
//   element={<WebSummitEvent />}
// />
//
// <Route path="/ws" element={<WebSummit />} />;
// <Route path="/twitter-contest-1" element={<TwitterContest />} />

export default App;
