// vim: set noautoindent nosmartindent formatoptions=croql :
import { DesignedLink, Title2, Title3 } from "../components/DesignComponents";

function TextPart({ title, children }) {
  return (
    <div className="md:pr-12">
      {title && (
        <h3 className="s-text-3xl mb-2 font-semibold leading-normal text-center sm:text-left">
          {title}
        </h3>
      )}
      <p className="s-text-base leading-relaxed mt-4 mb-4 text-last">
        {children}
      </p>
    </div>
  );
}

const ImagePart = (props) => (
  <div className="relative flex flex-col min-w-0 w-full mb-6 mt-0 sm:mt-48 md:mt-0 ">
    <div className="overflow-hidden rounded-full">
      <img
        alt="CEO picture"
        className="w-full h-full object-cover"
        src={props.img}
      />
    </div>
  </div>
);

export function TextImage({ title, img, children, className, ...props }) {
  return (
    <div className="flex flex-wrap items-center sm:border-none border-dark-200 place-content-center">
      <div className="w-3/12 md:w-2/12 py-4 px-2">
        <ImagePart img={img}></ImagePart>
      </div>
      <div className="w-9/12 md:w-9/12 py-4 px-2">
        <TextPart title={title} children={children} />
      </div>
    </div>
  );
}

function AboutMarie() {
  return (
    <>
      <TextImage title="" img="/assets/img/marie.jpeg">
        🇫🇷 🇨🇦
        <br /> I'm Marie Braud, co-founder and Head of Talent at AI Cartel. With
        15 years of experience as a Head Hunter/Talent Partner/Employer
        Branding/DEI for remote companies and scale-ups in the gaming, VFX, and
        technology industries. Since relocating from Montreal to Lisbon in 2019,
        I've been working remotely. Along the way, I've had the opportunity to
        explore 68 countries and witness six of the world's wonders.
      </TextImage>
    </>
  );
}

// function AboutSanson() {
//   return (
//     <>
//       <TextImage title="" img="/assets/img/ceo_photo_1.jpeg">
//         I'm Sanson Chaignon, the CEO of AI Cartel. With about a decade of
//         experience working with CAC40 companies and small businesses, I've
//         witnessed the profound impact of hiring the best candidates. At AI
//         Cartel, our mission is to simplify the integration of artificial
//         intelligence into your hiring process and business operations. Based in
//         Toulouse, France, I have been passionate about artificial intelligence
//         since 2013.
//       </TextImage>
//     </>
//   );
// }

// function AboutSanson() {
//   return (
//     <>
//       <TextImage title="" img="/assets/img/ceo_photo_1.jpeg">
//         🇫🇷 🇲🇬 I'm Sanson Chaignon, the CEO & founder of AI Cartel. I worked for
//         about a decade as a cybersecurity consultant in Europe, Africa, America,
//         and Asia to protect governments, defense, hospitals, and CAC40
//         companies. I served more than 200 companies, but I always wanted to have
//         my own company. My first SaaS, was early acquired by Offensive360 in
//         2023. Now I'm focused on AI Cartel, my vision is to facilitate
//         solo-founders' hiring pain points. I have been passionate about
//         artificial intelligence since 2013.
//       </TextImage>
//     </>
//   );
// }

export function AboutSanson() {
  return (
    <>
      <TextImage title="" img="/assets/img/ceo_photo_1.jpeg">
        🇫🇷 🇲🇬
        <br />
        I'm Sanson Chaignon, the CEO & founder of AI Cartel. Since 2013, I have
        been passionate about artificial intelligence. For about a decade, I've
        had the chance to travel the world as a cybersecurity consultant to
        protect governments, defense, hospitals, and CAC40 companies. I helped
        more than 200 companies, but I always dreamed of starting my own
        company. In 2022, finally I left consulting and freelancing. In 2023, my
        first AI SaaS was acquired by Offensive360.
        <br />
        But who am I? A soul wandering through perceptions of the universe :)
      </TextImage>
    </>
  );
}

function AboutUs() {
  return (
    <div className="bg-light-100 py-10">
      <div className="container mx-auto px-4">
        <Title3 className="text-3xl font-semibold text-dark-300 mb-6">
          <h3>We look like a job board, but we are not!</h3>
        </Title3>
        <p className="text-dark-300 mb-6">
          We are a fully automated AI-assisted recruiting process.
        </p>
        <ul className="list-disc list-inside text-dark-300 ml-6 mb-6">
          <li>We perform AI-conducted job interviews with our AI-voice;</li>
          <li>We cross-post your job to multiple job boards for you;</li>
          <li>
            Our AI-enabled query system allows you to filter candidates 10 times
            faster.
          </li>
          <li>AI sourcing of candidates;</li>
          <li>
            We provide on-demand dedicated social network content:
            Instagram/TikTok/Twitter/Reddit/Telegram/Discord to find candidates;
          </li>
          <li>
            We present you with pre-interviewed candidates who are ready to be
            hired;
          </li>
          <li>
            We work with a network of 100+ recruiters working on referral
            bounties;
          </li>
          <li>
            On-demand email outreach to 20 000+ candidates in our database;
          </li>
          <li>
            We organize speed recruiting events to attract the best candidates;{" "}
            <DesignedLink to="/virtual-speed-recruiting ">
              see our last event
            </DesignedLink>
            ;
          </li>
          <li className="text-green-600 font-bold">
            100% refund of service fee guaranteed if not satisfied.
          </li>
        </ul>
        <p className="text-gray-600">
          We don't believe in "do-it-yourself job boards"; we believe in
          automating all boring tasks to allow you to focus on your business
          goals.
        </p>
      </div>
    </div>
  );
}

// <AboutUs />
function About() {
  return (
    <>
      <Title2>
        <h3># About us</h3>
      </Title2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <AboutSanson />
        <AboutMarie />
      </div>
      Long story short, Sanson and Marie met at Web Summit in Lisbon 2023. With
      his expertise in technology and her knowledge of the industry, they
      decided to join forces.
    </>
  );
}

export default About;
