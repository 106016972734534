import { formatDayMonth } from "utils/DateUtils";
import { Link } from "react-router-dom";
import { CandidateStatusBadge } from "components/RatingComponent";
import { DeleteButton } from "components/IconButtons";
import useApi from "utils/Api";
import { toast } from "react-toastify";
import XS from "components/XS";
import ConfirmedEmail from "features/ConfirmedEmail";
import { useGameContext } from "debate/GameContext";
import { useSpeedRecruitingList } from "apps/ai-recruiter/candidate/VirtualSpeedRecruiting";
import { useState } from "react";
import { Dropdown } from "components/Dropdown";
import { Centered } from "components/DesignComponents";
import { useDynamicQuery } from "utils/NetworkUtils";
import { HandleHTTPErrors2 } from "components/ErrorMessages";
import Tooltip from "components/output/Tooltip";

// full time, remote, contract, part time, internship => color
//
//

function PinkTag({ children }) {
  return (
    <div className="absolute right-0 top-0 p-2 bg-pink-600 rounded-bl-lg s-text-sm hidden md:block">
      {children}
    </div>
  );
}

function BountyTag({ children }) {
  console.log("BountyTag", children);
  return (
    <div className="absolute right-0 top-0 p-2 bg-pink-600 rounded-bl-lg s-text-xl md:block">
      {children}
    </div>
  );
}

const FeaturedJobCard = ({
  job,
  to,
  isFindJobView,
  isCompanyView,
  isReferrerView,
  isCandidateView,
  setRefreshCallback,
}) => {
  const { isAdmin } = useGameContext();
  const tags = ["blockchain", "crypto", "solidity", "smart contract"];
  let bgColor = "bg-dark-100";

  if (isFindJobView) {
    if (job.already_applied) {
      bgColor = "bg-dark-300";
    } else {
      bgColor = "bg-dark-100";
    }
  } else if (isCompanyView) {
    if (job.is_available) {
      bgColor = "bg-dark-100";
    } else {
      bgColor = "bg-dark-300";
    }
  }
  const { apiAIRecruiterToggleJob, apiAIRecruiterDeleteJob } = useApi();

  const doDelete = async () => {
    const { status } = await apiAIRecruiterDeleteJob(job.uuid);
    if (status === "ok") {
      toast.success(`Successfully deleted ${job.job_title}`);
      setRefreshCallback();
    }
  };

  const doToggle = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { status } = await apiAIRecruiterToggleJob(job.uuid);
    if (status === "ok") {
      toast.success(`Successfully changed job availability`);
      setRefreshCallback();
    }
  };
  console.log("isReferrerView", isReferrerView);
  return (
    <div
      className={`${bgColor} hover:bg-dark-200 border border-dark-200 text-secondary p-4 relative overflow-hidden`}
    >
      <Link to={to(job)}>
        {isFindJobView && <PinkTag>Fast Apply</PinkTag>}
        {isReferrerView && job.bounty ? (
          <Tooltip text="You will be rewarded for the referral bounty only when the client deems your candidate suitable.">
            <BountyTag>Bounty: {job.bounty}€</BountyTag>
          </Tooltip>
        ) : null}
        <h2 className="font-bold text-light-100">{job.job_title}</h2>
        <div className="text-sm text-light-300">
          {job.country} {job.city && "· "}
          {job.city} {job.city && "· "}
          {job.remote} {job.remote && "· "}
          {job.contract_type}
        </div>
        <div className="flex justify-between items-center mt-4">
          <span className="text-xs text-light-300">
            {formatDayMonth(job.created_at)}
          </span>
          <span className="text-xs text-light-300">{job.max_salary}</span>
        </div>
        {job.candidate_status && (
          <CandidateStatusBadge status={job.candidate_status} />
        )}
        {isCompanyView && (
          <div className="flex justify-end space-x-2">
            <button
              onClick={doToggle}
              className={`font-bold text-xs p-2 ${
                job.is_available ? "bg-red-500" : "bg-green-500"
              } text-white rounded`}
            >
              {job.is_available ? "Disable" : "Enable"}
            </button>
            <DeleteButton onClick={doDelete} />
          </div>
        )}
        <div className="flex">
          {job.email && (
            <XS k="Email">
              <ConfirmedEmail
                email={job.email}
                mail_confirmed={job.mail_confirmed}
              />
            </XS>
          )}
          {job.referral_code && <XS k="Referral code">{job.referral_code}</XS>}
        </div>
      </Link>
    </div>
  );
};

// <Link to={`../view-job/${job.uuid}`}>

// <div className="flex flex-wrap gap-2 mt-2">
//   {tags.map((tag, index) => (
//     <span key={index} className="bg-dark-300 text-xs rounded px-2 py-1">
//       {tag}
//     </span>
//   ))}
// </div>

export function JobListing({
  jobs,
  to,
  setRefreshCallback = () => {},
  isFindJobView = false,
  isCompanyView = false,
  isReferrerView = false,
  isCandidateView = false,
}) {
  return (
    <div className="space-y-4">
      {jobs.map((job) => (
        <FeaturedJobCard
          key={job.uuid}
          job={job}
          to={to}
          isFindJobView={isFindJobView}
          isCompanyView={isCompanyView}
          isReferrerView={isReferrerView}
          isCandidateView={isCandidateView}
          setRefreshCallback={setRefreshCallback}
        />
      ))}
    </div>
  );
}

export function QueryAndShowJobs({ show_bounty_only = false, ...props }) {
  const { apiAIRecruiterAllJobs } = useApi();
  const speedRecruitingList = useSpeedRecruitingList();
  const [speedRecruiting, setSpeedRecruiting] = useState("");

  const requestsState = useDynamicQuery(
    "candidate-all-jobs" + speedRecruiting + show_bounty_only,
    apiAIRecruiterAllJobs,
    {
      show_bounty_only,
      virtual_speed_recruiting: speedRecruiting,
    }
  );
  const data = requestsState.data?.jobs;

  return (
    <Centered className="pb-4">
      <Dropdown
        label="Virtual Speed Recruiting event :"
        options={speedRecruitingList}
        onSelect={setSpeedRecruiting}
        value={speedRecruiting}
        required
      />
      <HandleHTTPErrors2 {...requestsState}>
        <JobListing
          jobs={data}
          to={(job) => `../view-job/${job.uuid}`}
          {...props}
        />
      </HandleHTTPErrors2>
    </Centered>
  );
}
