import React, { useEffect, useState, useContext, useCallback } from "react";
import useApi from "./utils/Api";
import ReactAudioPlayer from "react-audio-player";
import { FullButton } from "./components/DesignComponents";
import { GameContext } from "./debate/GameContext"; // THIS
import { AuthRequired } from "./auth/Login";
import useSpeechToTextAssemblyAI from "./components/audio/SpeechToTextAssemblyAI";
import ErrorMessages, { ErrorMessageDisplay } from "./components/ErrorMessages";
import {
  AudioMotionContainers,
  useAudioMotionAnalyzer,
} from "./utils/AudioVizualizer";
import { useMyStopWatch } from "./utils/DateUtils";
import { LanguageSelector } from "./components/LanguageSelector";

// {user_answer && (
//   <li>
//     <p>
//       {playerName} : {user_answer}
//     </p>
//   </li>
// )}
export function ShowDiscussion({ ai_answer, user_answer, playerName }) {
  return (
    <ul>
      {ai_answer && (
        <li>
          <p>AI : {ai_answer}</p>
        </li>
      )}
    </ul>
  );
}

function RealAICartelPlayer({
  end_button_text = "Next",
  applicationName,
  onError = () => {},
  onAbortDiscussion,
  onDiscussionFinished,
  onAIAnswerFinished,
  reuseGameFromStorage = false,
  instruction = "",
}) {
  const audioMotionAI = useAudioMotionAnalyzer(false); // don't delete
  const audioMotionHuman = useAudioMotionAnalyzer(true); // don't delete
  const stopWatch = useMyStopWatch();
  const [requestResponse, setRequestResponse] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [currentPlayedAudioUrl, setCurrentPlayedAudioUrl] = useState(
    "/assets/audio/elevator_speech.mp3"
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [liveTranscript, setLiveTranscript] = useState("");
  const { email, language, gameUUID, setGameUUID, authToken } =
    useContext(GameContext);
  const {
    apiSendAudioData,
    apiSendAudioBeforeEnd,
    apiContinueReceiveAIAudio,
    apiStartDiscussion,
  } = useApi();

  const onLiveTranscript = useCallback(
    (transcript) => {
      setLiveTranscript(transcript);
    },
    [setLiveTranscript]
  );

  const onBeforeSpeaked = useCallback((userAnswer) => {
    // console.log("AICartelPlayer: onBeforeSpeaked");
    var gameUUID = window.globalUUID;
    var email = window.email;
    var authToken = window.authToken;
    if (isBusy === false) {
      const audioBlob = null;
      sendAudioDataBeforeEnd(userAnswer, audioBlob, gameUUID, email, authToken);
    } else {
      console.log("SKIP: AICartelPlayer: onBeforeSpeaked because isBusy");
    }
  }, []);

  const onSpeaked = useCallback((userAnswer) => {
    // console.log("AICartelPlayer: onSpeaked", userAnswer);
    var gameUUID = window.globalUUID;
    var email = window.email;
    var authToken = window.authToken;
    if (isBusy === false) {
      const audioBlob = null;
      sendAudioData(userAnswer, audioBlob, gameUUID, email, authToken);
    } else {
      console.log("SKIP: AICartelPlayer: onSpeaked because isBusy");
    }
  }, []);

  const { stopRecording, startRecording, hasMicPermission, isRecording } =
    useSpeechToTextAssemblyAI({
      onLiveTranscript,
      onBeforeSpeaked,
      onSpeaked,
    });

  useEffect(() => {
    (async () => {
      // TODO reuseGameFromStorage is not required anymore because of startDiscussion
      if (reuseGameFromStorage && gameUUID && email) {
        continueReceiveAIAudio(gameUUID, email, authToken);
      } else {
        await startDiscussion(gameUUID);
      }
    })();
  }, [gameUUID]);

  useEffect(() => {
    // Giving access to busy State to the Transcriber
    // We don't transcribe while the AI speaks
    Object.assign(window, { AISpeaking: isBusy });
  }, [isBusy]);

  useEffect(() => {
    setIsPlaying(gameUUID && email && authToken);
    Object.assign(window, { userSpeaking: false });
    Object.assign(window, { globalUUID: gameUUID });
    Object.assign(window, { authToken: authToken });
    Object.assign(window, { email: email });
  }, [gameUUID, email, authToken]);

  const startDiscussion = async (uuid) => {
    const { status, message } = await apiStartDiscussion(uuid);
    if (status === "ok") {
      continueReceiveAIAudio(uuid, email, authToken);
    } else {
      onError(message);
    }
  };

  function playAudio(audioBlob, onEnded) {
    // stopRecording();
    const audioUrl = URL.createObjectURL(audioBlob);
    const audio = document.getElementById("audio");
    audio.pause();
    audio.onended = onEnded;
    audio.currentTime = 0;
    setCurrentPlayedAudioUrl(audioUrl);
    audio.muted = true;
    audio.addEventListener("canplaythrough", function onCanPlay() {
      audio.muted = false;
      audio.play();
      audio.removeEventListener("canplaythrough", onCanPlay);
    });
  }

  function onAudioPlayEnded() {
    continueReceiveAIAudio(gameUUID, email, authToken);
  }

  function handleSpeakResponse(response) {
    const {
      status,
      message,
      mp3Blob,
      user_answer,
      ai_answer,
      is_discussion_finished,
    } = response;
    setRequestResponse({ status, message });

    if (mp3Blob === undefined) {
      console.log("AI Answer:", ai_answer, "user Answer:", user_answer);
      setIsBusy(false);
      startRecording();
      onAIAnswerFinished(ai_answer, user_answer);
      if (is_discussion_finished) {
        onDiscussionFinished();
      }
    } else {
      playAudio(mp3Blob);
    }
  }

  async function continueReceiveAIAudio(gameUUID, email, authToken) {
    setIsBusy(true);

    try {
      const response = await apiContinueReceiveAIAudio(
        gameUUID,
        email,
        authToken
      );
      handleSpeakResponse(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function sendAudioData(audioData, gameUUID, email, authToken) {
    setIsBusy(true);
    try {
      const response = await apiSendAudioData(
        audioData,
        gameUUID,
        email,
        authToken
      );
      handleSpeakResponse(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function sendAudioDataBeforeEnd(
    userAnswer,
    audioData,
    gameUUID,
    email,
    authToken
  ) {
    // setIsBusy(true);
    try {
      Object.assign(window, { streamAudioNow: false });
      await apiSendAudioBeforeEnd(
        userAnswer,
        audioData,
        gameUUID,
        email,
        authToken
      );
      // setIsBusy(false);
    } catch (error) {
      console.log(error);
    }
  }

  // isHumanSpeaking={isRecording && window.userSpeaking}
  // console.log("AICartelPlayer render");
  return (
    <div>
      <div className="text-orange-600 font-bold s-text-xs text-center pb-3">
        {instruction}
      </div>

      <AudioMotionContainers
        isAISpeaking={isBusy}
        isHumanSpeaking={isRecording && window.userSpeaking}
        isRecording={isRecording}
      />
      <div className="recording-component">
        <ErrorMessages requestResponse={requestResponse}>
          <audio
            id="audio"
            src={currentPlayedAudioUrl}
            onEnded={onAudioPlayEnded}
            crossOrigin="anonymous"
          />
          {isPlaying && !isBusy && !hasMicPermission && (
            <div className="s-text-xl bg-cta text-dark-300">
              Please enable your microphone to speak!
            </div>
          )}
          {isPlaying && (
            <>
              <div className="italic">{liveTranscript}</div>
              {stopWatch.display}
              {!isBusy && (
                <FullButton
                  onClick={() => onAbortDiscussion(stopWatch.totalMinutes)}
                >
                  {end_button_text}
                </FullButton>
              )}
            </>
          )}
        </ErrorMessages>
      </div>
    </div>
  );
}

export default function AICartelPlayer({ onError = () => {}, ...props }) {
  const { email } = useContext(GameContext);
  const [errorMessage, setErrorMessage] = useState(null);

  const onErrorProxy = useCallback(
    (message) => {
      setErrorMessage(message);
      onError(message);
    },
    [setErrorMessage]
  );

  return (
    <AuthRequired email={email}>
      <ErrorMessageDisplay message={errorMessage} />
      <RealAICartelPlayer onError={onErrorProxy} {...props} />
    </AuthRequired>
  );
}

// <LanguageSelector>
// </LanguageSelector>
