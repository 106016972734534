import React from "react";
import { Centered, Title3 } from "components/DesignComponents";
import useAdminApi from "utils/useAdminApi";
import { HandleHTTPErrors2 } from "components/ErrorMessages";
import { CellTD, TitleTD } from "components/TableComponents";

const TableRow = ({ payment }) => {
  return (
    <tr className="bg-dark border-b">
      <CellTD>{payment.created_at}</CellTD>
      <CellTD>
        {payment.paid_amount} {payment.currency}
      </CellTD>
      <CellTD>{payment.paid_item}</CellTD>
      <CellTD>{payment.username}</CellTD>
      <CellTD>{payment.email}</CellTD>
    </tr>
  );
};

const Table = ({ payments }) => {
  return (
    <table className="min-w-full table-auto">
      <thead className="bg-dark-200">
        <tr>
          <TitleTD>Date</TitleTD>
          <TitleTD>Price</TitleTD>
          <TitleTD>Item</TitleTD>
          <TitleTD>Email</TitleTD>
          <TitleTD>Username</TitleTD>
        </tr>
      </thead>
      <tbody>
        {payments.map((payment) => (
          <TableRow key={payment.id} payment={payment} />
        ))}
      </tbody>
    </table>
  );
};

export default function AdminPayments() {
  const { useApiAllPayment } = useAdminApi();
  const requestsState = useApiAllPayment();
  const payments = requestsState.data?.payments || [];

  console.log("payments", payments);

  return (
    <section className="border-t border-gray-800 py-1">
      <Title3>Admin Payments</Title3>
      <HandleHTTPErrors2 {...requestsState}>
        <Centered>
          <Table payments={payments} />
        </Centered>
      </HandleHTTPErrors2>
    </section>
  );
}
