import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import React, { useCallback, useState } from "react";
import {
  CheckboxLeft,
  FullButton,
  PhoneInput,
  Title,
} from "components/DesignComponents";

import { Title3 } from "components/DesignComponents";
import useApi from "utils/Api";
import ErrorMessages from "components/ErrorMessages";
import {
  FindJobContextProvider,
  useFindJobContext,
} from "../context/JobInterviewContext";
import PrivacyCheckbox from "components/PrivacyCheckbox";
import { AuthRequired } from "auth/Login";
import { useGameContext } from "debate/GameContext";
import ReCAPTCHA from "react-google-recaptcha";

function Step1_DoJobInterview() {
  const { apiAIRecruiterDoInterview } = useApi();
  const { currentStep, setCurrentStep, email, setDiscussionUUID } =
    useFindJobContext();
  const [requestResponse, setRequestResponse] = useState(null);
  const { job_uuid } = useParams();
  const { phoneNumber, setPhoneNumber } = useGameContext();
  const [captcha, setCaptcha] = useState(null);
  const captchaEnabled = false;

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (phoneNumber.length < 4) {
        toast.error("Please enter a valid phone number");
        return;
      }

      if (!captcha && captchaEnabled) {
        setRequestResponse({
          status: "error",
          message: "Please complete the captcha.",
        });
        return;
      }
      const applicationName = "AIRecruiter - candidate to one company";
      const language = "English (US)";
      const is_public = false;
      const requiredArgs = {
        job_uuid,
        phone_number: phoneNumber,
      };
      const {
        status,
        game_uuid: uuid,
        message,
      } = await apiAIRecruiterDoInterview(
        applicationName,
        email,
        language,
        is_public,
        captcha,
        requiredArgs
      );

      setRequestResponse({ status, message });
      if (status === "ok") {
        setDiscussionUUID(uuid);
        setCurrentStep(currentStep + 1);
      } else {
        console.log("createDiscussion error", message);
      }
    },
    [
      email,
      job_uuid,
      setCurrentStep,
      currentStep,
      setDiscussionUUID,
      apiAIRecruiterDoInterview,
      setRequestResponse,
      captcha,
      phoneNumber,
    ]
  );

  return (
    <ErrorMessages
      requestResponse={requestResponse}
      successMessage="Continue to the next step"
    >
      <Title3>Be ready</Title3>
      <p className="m-3">Do not stress, the AI Recruiter will not judge you.</p>
      <form className="grid mx-auto place-items-center" onSubmit={handleSubmit}>
        <div className="grid">
          <PhoneInput
            country={"us"}
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
          <CheckboxLeft
            label="You are in a quiet place?"
            setValue={() => {}}
            required
          />
          <CheckboxLeft
            label="You are ready for the job interview?"
            setValue={() => {}}
            required
          />
          <CheckboxLeft
            name="accept_call"
            label="I accept to receive a call from AI Cartel to discuss the job."
            setValue={() => {}}
            required
          />
          <PrivacyCheckbox />
        </div>
        {captchaEnabled && (
          <ReCAPTCHA
            sitekey="6LffKdYpAAAAADpO-b53XttWWS-EJlbQbTjp21Sh"
            theme="light"
            type="image"
            size="normal"
            tabIndex={0}
            onChange={setCaptcha}
          />
        )}
        <div className="flex justify-center">
          <FullButton className="text-center" type="submit" propagate>
            Start the interview
          </FullButton>
        </div>
      </form>
    </ErrorMessages>
  );
}

function Step2_DoJobInterview() {
  return (
    <div className="grid justify-items-center">
      <Title className="text-green-600">
        SUCCESS: You will receive a screening call within 5 minutes from our AI
        Recruiter.
      </Title>
    </div>
  );
}

function DoJobInterviewWithContext() {
  const { currentStep } = useFindJobContext();

  return (
    <div className="flex flex-col place-content-center">
      {currentStep == 1 && <Step1_DoJobInterview />}
      {currentStep == 2 && <Step2_DoJobInterview />}
    </div>
  );
}

export function DoJobInterviewPhone() {
  return (
    <AuthRequired>
      <FindJobContextProvider>
        <DoJobInterviewWithContext />
      </FindJobContextProvider>
    </AuthRequired>
  );
}
