import {
  Centered,
  DesignedLink,
  FullButton,
  FullButtonLink,
  Input,
  Title,
  Title3,
} from "components/DesignComponents";
import ErrorMessages, { HandleHTTPErrors2 } from "components/ErrorMessages";
import { useCallback, useState } from "react";
import useViralTiktokApi from "./ViralTikTokApi";
import notifications from "utils/Notifications";
import VideoPlayer from "components/VideoPlayer";
import { useViralTikTokContext } from "./ViralTiktokContext";
import {
  ClockIcon,
  PlayIcon,
  HeartIcon,
  ChatBubbleLeftIcon as CommentIcon,
} from "@heroicons/react/24/outline";
import { CopyToClipboardButton } from "components/IconButtons";
import { useCallbackOnceAtATime } from "utils/useCallbackOnceAtATime";
export function ReuseVideo() {
  return (
    <Centered>
      <SearchAndDownload />
      <CreateTikTok />
    </Centered>
  );
}
// <ListTikTok />

function TikTokCard({ tiktok, createTikTok }) {
  console.log("TikTokCard", tiktok);
  return (
    <div>
      <FullButton onClick={() => createTikTok(tiktok)}> {tiktok} </FullButton>
    </div>
  );
}

// <VideoPlayer
//   src={`/api/apps/viral-tiktok/download/TikTokVideos-${tiktok}`}
// ></VideoPlayer>

function ListTikTok() {
  const { useListTikTok, apiCreateViralTikTok } = useViralTiktokApi();
  const requestsState = useListTikTok();
  const tiktoks = requestsState.data?.output;
  const [requestResponse, setRequestResponse] = useState(null);
  const [currentTikTok, setCurrentTikTok] = useState(null);

  const createTikTok = useCallback(
    async (tiktok) => {
      console.log("processTikTok", tiktok);
      const { status, ...remaining } = await apiCreateViralTikTok(tiktok);
      setRequestResponse({ status, ...remaining });
      setCurrentTikTok(tiktok);
      if (status === "ok") {
        notifications.success("TikTok was created");
      }
    },
    [apiCreateViralTikTok, setRequestResponse, setCurrentTikTok]
  );

  // url="https://www.tiktok.com/@kefyalewtufayadete1/video/7333204102970707205"
  // <video src="https://v19-web.romaniat.com/23c3d1cef933ed9b587735675cd9bf9b/65c5dad3/video/tos/useast2a/tos-useast2a-ve-0068c001-euttp/oE1ZmEgJ1IvmpC7LAqYeImQxeoIViEGeZGfjcL/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C&cv=1&br=1646&bt=823&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=piJEeMFx8Zmo0Zkj894jV5bbrpWrKsd.&mime_type=video_mp4&qs=0&rc=OGc0aGg2NDgzOjtnNzdkZUBpamZkaXk5cjlmcDMzZjczM0A2My0xXmMzNV8xYi9iYjRgYSNuMWNuMmRrbnBgLS1kMWNzcw%3D%3D&l=20240209015659E5D291E1038965FDE6FC&btag=e00088000&ww=19" />
  return (
    <HandleHTTPErrors2 {...requestsState}>
      <div className="grid grid-cols-3 gap-4">
        {tiktoks &&
          tiktoks.map((tiktok) => (
            <TikTokCard
              key={tiktok}
              tiktok={tiktok}
              createTikTok={createTikTok}
            />
          ))}
      </div>
      <ErrorMessages
        requestResponse={requestResponse}
        successMessage="TikTok was created"
      ></ErrorMessages>
      {requestResponse && (
        <div>
          <Title3>{currentTikTok}</Title3>
          <div>AI Output:{requestResponse.ai_output}</div>
          <Title3>Before</Title3>
          <pre className="border p-2">{requestResponse.before}</pre>
          <Title3>After</Title3>
          <pre className="border p-2">{requestResponse.after}</pre>
        </div>
      )}
    </HandleHTTPErrors2>
  );
}

function CreateTikTok() {}

function ShowTikTokStats({ stats, ...props }) {
  return (
    <div {...props}>
      <div className="absolute right-0">
        <div className="font-bold bg-opacity-70 bg-dark-300">
          <div className="flex items-center mb-1">
            <ClockIcon className="w-7 h-7" /> {stats[0]}
          </div>
          <div className="flex items-center mb-1">
            <PlayIcon className="w-7 h-7" /> {stats[1]}
          </div>
          <div className="flex items-center mb-1">
            <HeartIcon className="w-7 h-7" /> {stats[2]}
          </div>
          <div className="flex items-center mb-1">
            <CommentIcon className="w-7 h-7" /> {stats[3]}
          </div>
        </div>
      </div>
      <div className="absolute bottom-10 m-3 p-1 bg-white bg-opacity-30">
        {stats[4]}
      </div>
    </div>
  );
}

export function ShowTikTok({ tiktok }) {
  console.log("ShowTikTok", tiktok.video_cdn_url);
  return (
    <>
      {tiktok.video_cdn_url && (
        <div>
          <div className="relative">
            <VideoPlayer src={tiktok.video_cdn_url} controls />
            <ShowTikTokStats
              stats={tiktok.stats}
              className="absolute top-0 left-0 right-0 bottom-0 flex pointer-events-none"
            />
          </div>

          <div className="p-2">
            <DesignedLink to={tiktok.tiktok_url}>
              {tiktok.username_video_id}
            </DesignedLink>
            <CopyToClipboardButton text={tiktok.video_cdn_url} />
          </div>
        </div>
      )}
    </>
  );
}

function BackgroundVideoURL({ backgroundVideo, setBackgroundVideo }) {
  const [tmpBackgroundVideo, setTmpBackgroundVideo] = useState("");

  const [setBackgroundVideoOnce, isProcessing] = useCallbackOnceAtATime(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setBackgroundVideo({ tiktok_url: tmpBackgroundVideo });
      // setBackgroundVideo({});
    },
    [tmpBackgroundVideo, setBackgroundVideo]
  );

  return (
    <div className="border">
      <Title3>Set Background Video</Title3>
      <form onSubmit={setBackgroundVideoOnce}>
        <div className="flex justify-center">
          <Input
            type="text"
            value={tmpBackgroundVideo}
            onChange={setTmpBackgroundVideo}
            label="Background Video URL"
          />
          <FullButton className="text-center" type="submit" propagate>
            Set Background Video
          </FullButton>
        </div>
      </form>
    </div>
  );
}

function SearchAndDownload() {
  const { apiSearchViralTikTok } = useViralTiktokApi();
  const [requestResponse, setRequestResponse] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [tiktoks, setTikToks] = useState([]);
  const { backgroundVideo, setBackgroundVideo } = useViralTikTokContext();

  const [handleSubmit, isProcessing] = useCallbackOnceAtATime(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!searchParam) {
        notifications.error("Search Param is required");
        return;
      }
      const { status, message, output } = await apiSearchViralTikTok(
        searchParam
      );
      setRequestResponse({ status });
      if (status === "ok") {
        notifications.success("Search was successful");
        setTikToks(output);
      } else {
        console.log("createDiscussion error", message);
      }
    },
    [searchParam, apiSearchViralTikTok, setRequestResponse]
  );

  return (
    <Centered>
      <div>
        <Title>
          <h1>TikTok-19</h1>
        </Title>
        <div className="pb-3">
          Create videos that makes your business go viral on TikTok. <br />
          Your customer need to engage with your brand in a fun and creative
          way.
        </div>
        {!isProcessing && (
          <form
            className="grid mx-auto place-items-center"
            onSubmit={handleSubmit}
          >
            <Input
              label="Search Param"
              type="text"
              name="oneline"
              value={searchParam}
              onChange={setSearchParam}
              placeholder="Search Param"
            />
            <div className="flex justify-center">
              <FullButton className="text-center" type="submit" propagate>
                Search TikToks
              </FullButton>
            </div>
          </form>
        )}
        {backgroundVideo.value && (
          <div className="grid grid-cols-3 gap-4 border m-3">
            <div className="relative"></div>
            <div>
              <Title3>Background Video</Title3>
              <ShowTikTok tiktok={backgroundVideo.value} />
            </div>
          </div>
        )}
        <BackgroundVideoURL
          backgroundVideo={backgroundVideo}
          setBackgroundVideo={setBackgroundVideo}
        />
        <div className="grid grid-cols-3 gap-4 border m-3">
          {tiktoks.map((tiktok, index) => (
            <div key={index} className="m-4 border border-dotted">
              <ShowTikTok tiktok={tiktok} />
              <div className="flex place-content-center">
                <FullButton
                  onClick={() => setBackgroundVideo(tiktok)}
                  className="p-24"
                >
                  Use as background
                </FullButton>
              </div>
            </div>
          ))}
        </div>
      </div>
      <OurProcess />
    </Centered>
  );
}

function OurProcess() {
  return (
    <div className="mt-24 p-3 border">
      <div className="p-6">
        Create videos that makes your business go viral on TikTok. <br />
        Your customer need to engage with your brand in a fun and creative way.
        <h2 className="text-2xl font-bold mb-4">Our process:</h2>
        <ul className="list-disc list-inside">
          <li>Human and AI generated video ideas;</li>
          <li>Our spokesperson team creates daily UGC videos for you;</li>
          <li>We analyze trending TikToks to create your content;</li>
          <li>
            Our process is 30% human, 40% automation, 30% AI for quality and
            consistency;
          </li>
          <li>We limit AI usage to build trust with your audience.</li>
        </ul>
      </div>
    </div>
  );
}
