// notifications.js
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifications = {
  success: (message, options = {}) => {
    toast.success(message, {
      // Default options for success toasts
      autoClose: 3000,
      ...options,
    });
  },
  error: (message, options = {}) => {
    toast.error(message, {
      // Longer duration for error toasts
      autoClose: 8000,
      ...options,
    });
  },
  info: (message, options = {}) => {
    toast.info(message, {
      autoClose: 3000,
      ...options,
    });
  },
  // Add more types as needed
};

export default notifications;
