import { useEffect, useRef } from "react";
import AudioMotionAnalyzer from "audiomotion-analyzer";
import { MicrophoneIcon } from "@heroicons/react/24/outline";

function MicIcon({ className = "" }) {
  return (
    <MicrophoneIcon
      className={`block h-12 w-12 rounded-full ${className}`}
      aria-hidden="true"
    />
  );
}

function InteractiveMicIcon({
  isRecording,
  isAISpeaking,
  isHumanSpeaking,
  ...props
}) {
  return (
    <div className="" {...props}>
      {isRecording && !isAISpeaking && (
        <MicIcon className="animate-greenPulseEffect" />
      )}
      {isAISpeaking && <MicIcon className="text-red-600" />}
    </div>
  );
}

export function AudioMotionContainers(props) {
  return (
    <div className="grid place-items-center ">
      <div className="pointer-events-none  w-64 h-64 ">
        <div className="relative pointer-events-none">
          <div
            id="audio-visualizer-container-human"
            className="absolute w-64 h-64"
          ></div>
          <div
            id="audio-visualizer-container-ai"
            className="absolute w-64 h-64"
          ></div>
        </div>
        <div className="flex items-center justify-center h-64 w-64">
          <InteractiveMicIcon {...props} />
        </div>
      </div>
    </div>
  );
}

export function useAudioMotionAnalyzer(useMic, start) {
  const audioMotionRef = useRef(null);

  async function connectToMic(audioMotion) {
    if (navigator.mediaDevices) {
      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          // create stream using audioMotion audio context
          const micStream =
            audioMotion.audioCtx.createMediaStreamSource(stream);
          // connect microphone stream to analyzer
          audioMotion.connectInput(micStream);
          // mute output to prevent feedback loops from the speakers
          audioMotion.volume = 0;
        })
        .catch((err) => {
          alert("Microphone access denied by user");
        });
    } else {
      alert("User mediaDevices not available");
    }
  }

  async function getMicAudioNode(audioContext) {
    try {
      // Check if the browser supports audio input
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.error("getUserMedia not supported on your browser!");
        return null;
      }

      // Ask the user for permission to access the microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Create an audio node from the stream
      const micAudioNode = audioContext.createMediaStreamSource(stream);

      return micAudioNode;
    } catch (error) {
      // Handle the error here
      console.error("The following error occurred: " + error);
      return null;
    }
  }

  const releaseMicrophone = () => {
    if (audioMotionRef.current) {
      // alert("releaseMicrophone");
      audioMotionRef.current.disconnectInput(false, true);
      audioMotionRef.current.destroy();
      audioMotionRef.current = null;
      // stopMediaTracks();
    }
  };

  const initializeAudioMotion = (container) => {
    return new AudioMotionAnalyzer(container, {
      maxFPS: 20,
      radial: !useMic,
      overlay: true,
      bgAlpha: 0,
      ledBars: true,
      colorMode: "gradient",
      showScaleX: false,
    });
  };

  const registerGradients = (audioMotion) => {
    audioMotion.registerGradient("humanSpeaking", {
      bgColor: "#f00",
      colorStops: [{ color: "#00f", pos: 1 }],
    });

    audioMotion.registerGradient("AISpeaking", {
      bgColor: "#f00",
      colorStops: [{ color: "#fff", pos: 1 }],
    });
  };

  const getContainer = (useMic) => {
    if (useMic) {
      return document.getElementById("audio-visualizer-container-human");
    } else {
      return document.getElementById("audio-visualizer-container-ai");
    }
  };

  const attach = () => {
    if (audioMotionRef.current) {
      return;
    }
    const container = getContainer(useMic);
    console.log("container", container);
    if (container && !audioMotionRef.current) {
      const audioMotion = initializeAudioMotion(container);
      console.log("audioMotion", audioMotion);
      registerGradients(audioMotion);
      audioMotion.setCanvasSize(500, 500);

      if (useMic) {
        connectToMic(audioMotion);
        audioMotion.gradient = "humanSpeaking";
        // window.addEventListener("beforeunload", releaseMicrophone);
        // window.addEventListener("unload", releaseMicrophone);
      } else {
        audioMotion.connectInput(document.getElementById("audio"));
        audioMotion.gradient = "AISpeaking";
      }
      audioMotionRef.current = audioMotion;
    }
  };

  const resume = () => {
    if (audioMotionRef.current) {
      audioMotionRef.current.resume();
    }
  };

  const detach = () => {
    if (audioMotionRef.current) {
      // audioMotionRef.current.pause();
      audioMotionRef.current.disconnectInput(false, true);
      // audioMotionRef.current.disconnectInput(false, true);
      // audioMotionRef.current.destroy();
      // audioMotionRef.current = null;
    }
    if (useMic) {
      window.removeEventListener("beforeunload", releaseMicrophone);
      window.addEventListener("unload", releaseMicrophone);
      releaseMicrophone();
    }
  };

  useEffect(() => {
    attach();
    return () => {
      detach();
    };
  }, []);

  const setSpeaker = (speaker) => {
    if (speaker != audioMotionRef.current.gradient) {
      audioMotionRef.current.gradient = "humanSpeaking";
    }
  };

  const humanSpeaking = () => {
    setSpeaker("humanSpeaking");
  };

  const aiSpeaking = () => {
    setSpeaker("AISpeaking");
  };

  return { humanSpeaking, aiSpeaking, attach, detach, resume };
}

// export function useAudioMotionAnalyzer(useMic, start) {
//   const audioMotionRef = useRef(null);
//
//   async function connectToMic(audioMotion) {
//     try {
//       if (navigator.mediaDevices) {
//         const stream = await navigator.mediaDevices.getUserMedia({
//           audio: true,
//           video: false,
//         });
//         const micStream = audioMotion.audioCtx.createMediaStreamSource(stream);
//         audioMotion.connectInput(micStream);
//         audioMotion.volume = 0;
//       } else {
//         alert("User mediaDevices not available");
//       }
//     } catch (err) {
//       alert("Microphone access denied by user");
//     }
//   }
//
//   const initializeAudioMotion = (container) => {
//     return new AudioMotionAnalyzer(container, {
//       // maxFPS: 20,
//       radial: false,
//     });
//   };
//
//   const getContainer = (useMic) => {
//     return document.getElementById(
//       useMic
//         ? "audio-visualizer-container-human"
//         : "audio-visualizer-container-ai"
//     );
//   };
//
//   const attach = () => {
//     if (audioMotionRef.current) {
//       return;
//     }
//     const container = getContainer(useMic);
//     console.log("container", container);
//     if (container && !audioMotionRef.current) {
//       const audioMotion = initializeAudioMotion(container);
//       console.log("audioMotion", audioMotion);
//
//       audioMotion.setCanvasSize(500, 500);
//
//       if (useMic) {
//         connectToMic(audioMotion);
//       } else {
//         audioMotion.connectInput(document.getElementById("audio"));
//         audioMotion.gradient = "AISpeaking";
//       }
//       audioMotionRef.current = audioMotion;
//     }
//   };
//
//   // useEffect(() => {
//   //   attach();
//   //   // audioMotionRef.current.radial = true;
//   //   console.log(audioMotionRef.current);
//   //   return () => {};
//   // }, []);
//
//   const radial = () => {
//     if (audioMotionRef.current) {
//       audioMotionRef.current.radial = !audioMotionRef.current.radial;
//     }
//   };
//
//   return { attach, radial };
// }
