import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { FullButton, GoBack } from "components/DesignComponents";
import useApi from "utils/Api";
import { HandleHTTPErrors2 } from "components/ErrorMessages";
import { formatDayMonth } from "utils/DateUtils";
import {
  CandidateFitScore,
  CandidateStatusBadge,
  ExperienceLevelIndicator,
} from "components/RatingComponent";
import { CellTD, TitleTD } from "components/TableComponents";
import XS from "components/XS";
import DiscussionHistory from "../DiscussionHistory";
import { CopyToClipboardButton } from "components/IconButtons";
import ConfirmedEmail from "features/ConfirmedEmail";
import useCompanyApi from "../CompanyApi";
import notifications from "utils/Notifications";

function CandidateContactInfo({ candidate, requestsState }) {
  const { apiHireCandidate, apiRejectCandidate } = useCompanyApi();
  const doHire = async () => {
    const { status } = await apiHireCandidate(candidate.uuid);
    if (status === "ok") {
      notifications.success(`Successfully hired candidate ${candidate.title}`);
      requestsState.refetch();
    }
  };

  const doReject = async () => {
    const { status } = await apiRejectCandidate(candidate.uuid);
    if (status === "ok") {
      notifications.success(
        `Successfully rejected candidate ${candidate.title}`
      );
      requestsState.refetch();
    }
  };

  return (
    <tr>
      <TitleTD>Contact</TitleTD>
      <CellTD>
        <div>
          {candidate.email}
          <CopyToClipboardButton text={candidate.email} />
        </div>
        <div>
          {candidate.phone_number}
          <CopyToClipboardButton text={candidate.phone_number} />
        </div>

        <CandidateStatusBadge status={candidate.candidate_status} />
        {candidate.candidate_status == "Waiting decision" && (
          <div className="flex flex-row">
            <FullButton onClick={doHire} className="md:p-1 md:m-1 bg-green-400">
              Hire
            </FullButton>
            <FullButton
              onClick={doReject}
              className="md:p-1 md:m-1 bg-orange-400"
            >
              Reject
            </FullButton>
          </div>
        )}
      </CellTD>
    </tr>
  );
}

export function CompanyViewCandidate() {
  const { candidate_uuid } = useParams();
  const { useViewCandidate } = useCompanyApi();

  const requestsState = useViewCandidate(candidate_uuid);
  const candidate = requestsState.data?.candidate;
  const country = candidate?.country;
  const city = candidate?.city;

  return (
    <HandleHTTPErrors2 {...requestsState}>
      <GoBack />
      {candidate && (
        <>
          <table className="table-auto mb-4">
            <tbody>
              <tr>
                <TitleTD className="w-1/3">Title</TitleTD>
                <CellTD className="font-bold text-center s-text-xl w-2/3">
                  {candidate.title}
                </CellTD>
              </tr>
              <tr>
                <TitleTD className="w-1/3">Name</TitleTD>
                <CellTD className="font-bold text-center s-text-xl w-2/3">
                  <div className="flex">
                    <CandidateStatusBadge status={candidate.candidate_status} />
                    <div className="flex-grow">{candidate.username}</div>
                  </div>
                </CellTD>
              </tr>
              <tr>
                <TitleTD>Informations</TitleTD>
                <CellTD className="grid grid-cols-2 justify-items-stretch">
                  <XS k="Date">{formatDayMonth(candidate.created_at)}</XS>{" "}
                  <XS k="Interview duration">
                    {candidate.interview_duration} minutes
                  </XS>
                  <XS k="Speaking">{candidate.languages}</XS>
                  <br />
                  <XS k="Location">
                    {country}
                    {country && city && " . "}
                    {city}
                  </XS>
                  <XS k="Remote desire">{candidate.remote}</XS>
                  <XS k="Desired contract">{candidate.contract_type}</XS>
                  <XS k="Desired salary">{candidate.desired_salary}</XS>
                  {candidate.email && (
                    <XS k="Email">
                      <ConfirmedEmail
                        email={candidate.email}
                        mail_confirmed={candidate.mail_confirmed}
                      />
                    </XS>
                  )}
                  {candidate.referral_code && (
                    <XS k="Referral code">{candidate.referral_code}</XS>
                  )}
                </CellTD>
              </tr>
              <tr>
                <TitleTD>AI Score</TitleTD>
                <CellTD className="grid grid-cols-2 gap-2">
                  <CandidateFitScore rating={candidate.ai_score} />
                  <ExperienceLevelIndicator
                    experience_level={candidate.experience_level}
                  />
                </CellTD>
              </tr>
              <tr>
                <TitleTD className="bg-green-600">Positive</TitleTD>
                <CellTD>{candidate.positive_points}</CellTD>
              </tr>
              <tr>
                <TitleTD className="bg-orange-600">Negative</TitleTD>
                <CellTD>{candidate.negative_points}</CellTD>
              </tr>
              <tr>
                <TitleTD>Major experience</TitleTD>
                <CellTD>{candidate.major_experience}</CellTD>
              </tr>
              <tr>
                <TitleTD>Special skillz</TitleTD>
                <CellTD>{candidate.special_skillz}</CellTD>
              </tr>
              <tr>
                <TitleTD>Summary</TitleTD>
                <CellTD>
                  <pre className="whitespace-pre-wrap overflow-x-auto ">
                    {candidate.job_interview_summary}
                  </pre>
                </CellTD>
              </tr>
              <CandidateContactInfo
                candidate={candidate}
                requestsState={requestsState}
              />
              {candidate.referral_code && (
                <tr>
                  <TitleTD>Referral code</TitleTD>
                  <CellTD>
                    <div>referred by: {candidate.referral_code}</div>
                  </CellTD>
                </tr>
              )}
            </tbody>
          </table>

          {candidate.discussion_history && (
            <DiscussionHistory job={candidate} />
          )}
        </>
      )}
    </HandleHTTPErrors2>
  );
}

// <FullButton
//   onClick={doPostpone}
//   className="md:p-1 md:m-1 bg-yellow-400"
// >
//   Postpone
// </FullButton>

//     Entry-Level / Beginner:
//         No professional experience to less than 2 years.
//         May include recent graduates or individuals transitioning to a new field.
//         Requires training and close supervision.
//
//     Junior / Intermediate:
//         2 to 5 years of professional experience.
//         Developing proficiency in their field but may still require some guidance.
//         Begins to take on more responsibility and handle more complex tasks.
//
//     Mid-Level / Experienced:
//         5 to 8 years of professional experience.
//         Can work independently and may lead small projects or teams.
//         Has a solid understanding of their industry and area of expertise.
//
//     Senior / Advanced:
//         8 to 15 years of professional experience.
//         Highly skilled and can manage larger projects or teams.
//         Often has specialized knowledge and can mentor less experienced colleagues.
//
//     Expert / Leader:
//         15+ years of professional experience.
//         Recognized authority in their field, capable of strategic planning and high-level decision-making.
//         May include top-level managers, directors, or executives who have a significant impact on business direction.
//
// These levels are broad and can be adapted to the specific needs of your industry or company. When assessing candidates, it's also important to consider the quality of their experience, not just the quantity. For example, a candidate with fewer years of experience but with a highly relevant and impactful background might be rated higher than someone with more years but less relevant experience.
