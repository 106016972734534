import axios from "axios";
import { useContext } from "react";
import { GameContext } from "../debate/GameContext"; // THIS
import { useNavigate } from "react-router-dom";
import { supportEmail } from "../staticValues";
import notifications from "./Notifications";
import { useDynamicQuery } from "./NetworkUtils";

const BACKEND_API_KEY =
  "5a51e83607021d8b3d8b2064572a84ef454f369cf79ffee8c2d09e920a209045";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Authorization"] = BACKEND_API_KEY;

export default function useAuthenticatedApi() {
  const navigate = useNavigate();
  const {
    isAdmin,
    adminSecret: admin_secret,
    authToken: auth_token,
    setAuthToken,
    email,
    setEmail,
    gameUUID: uuid,
  } = useContext(GameContext);

  async function showFetchHttpError(response) {
    let errorMessage = (
      <div>
        Unexpected error: ERROR-0001
        <br /> contact us at {supportEmail}
      </div>
    );
    if (response.headers.get("content-type")?.includes("application/json")) {
      try {
        const errorResponse = await response.json();
        errorMessage = errorResponse.message || errorMessage;
      } catch (error) {
        // Handle JSON parsing error, if any.
        errorMessage = (
          <div>
            Unexpected error: ERROR-0002
            <br /> contact us at {supportEmail}
          </div>
        );
      }
    }
    console.log(errorMessage);
    notifications.error(errorMessage);
    // throw new Error(errorMessage);
    return { status: "error", message: errorMessage };
  }

  async function showHttpError(error) {
    var error_message = "No error message found";
    if (error.response) {
      // console.error("catch HTTP error axios: " + error.response.data.message);
      if (error.response.status == 500) {
        if (error.response.data && error.response.data.message) {
          error_message = error.response.data.message;
        } else {
          error_message = "Internal Server Error";
        }
      } else if (error.response.data.message) {
        error_message = error.response.data.message;
        if (error_message.startsWith("Token expired")) {
          // await apiLoginUser(email, auth_token);
          setAuthToken("");
          navigate("/login/");
        }
      } else {
        error_message = error.response.data;
      }
    } else {
      console.log(error);
      console.error("catch HTTP error fetch: " + error.message);
      error_message = error.message;
    }
    if (error_message.startsWith("Invalid Auth Token")) {
      setAuthToken("");
      setEmail("");
      navigate("/login/");
    }
    notifications.error(error_message);
    throw new Error(error_message);
    // return { status: "error", message: error_message };
  }

  async function axiosPost(url, data) {
    try {
      if (isAdmin) {
        data.admin_secret = admin_secret;
      }
      if (auth_token && !data.auth_token) {
        data.auth_token = auth_token;
      }
      if (email && !data.auth_email) {
        data.auth_email = email;
      }
      const response = await axios.post(url, JSON.stringify(data));
      console.log(response.data);
      return response.data;
    } catch (error) {
      return await showHttpError(error);
    }
  }

  async function fetchPost(url, formData) {
    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: BACKEND_API_KEY,
        },
      });
      if (response?.status === 200 || response?.status === 201) {
        const contentType = response.headers.get("content-type");
        if (contentType?.includes("audio")) {
          const mp3Blob = await response.blob();
          return { mp3Blob };
        } else if (contentType?.includes("json")) {
          return await response.json();
        } else {
          return await showFetchHttpError(response);
        }
      } else {
        return await showFetchHttpError(response);
      }
    } catch (error) {
      return await showHttpError(error);
    }
  }

  async function axiosPostSilence(url, data) {
    try {
      return await axiosPost(url, data);
    } catch (error) {
      console.log(error);
      return { status: "error", message: error.message };
    }
  }

  function useAxiosQuery(key, url, data) {
    const requestsState = useDynamicQuery(
      key,
      async () => await axiosPost(url, data)
    );
    return requestsState;
  }

  return { axiosPostSilence, axiosPost, fetchPost, useAxiosQuery };
}
