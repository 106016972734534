import useAuthenticatedApi from "utils/AuthenticatedApi.js";

export default function useCompanyApi() {
  const { useAxiosQuery, axiosPostSilence } = useAuthenticatedApi();
  async function apiHireCandidate(uuid) {
    return await axiosPostSilence(
      "/api/apps/ai-recruiter/company/candidate/hire",
      { uuid }
    );
  }
  async function apiRejectCandidate(uuid) {
    return await axiosPostSilence(
      "/api/apps/ai-recruiter/company/candidate/reject",
      { uuid }
    );
  }

  function useViewCandidate(uuid) {
    return useAxiosQuery(
      "company-view-candidate" + uuid,
      "/api/apps/ai-recruiter/company/candidate/view",
      { uuid }
    );
  }
  return { apiHireCandidate, apiRejectCandidate, useViewCandidate };
}
