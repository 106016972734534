import React, { useEffect } from "react";
import About from "./About";
import Contact from "./Contact";
import VideoPlayer from "../components/VideoPlayer.js";
import {
  FullButtonLink,
  BorderButtonLink,
  BottomBanner,
  Title2,
  Section,
  Gradient,
} from "../components/DesignComponents.js";
import {
  HeaderDesignV2,
  HeaderShortMessages,
  HeaderShortMessagesItem,
} from "../components/HeaderDesigner.js";
import FAQ from "../pages/FAQ";
import useTwitterPixel from "utils/useTwitterPixel";
import Pricing from "./Pricing";
import StepCards from "components/StepCard";
import { ReferencesLogo } from "./References";
import { HowItWorks } from "./VirtualSpeedRecruitingLandingPage";
//
// used template
// https://www.tailwindawesome.com/resources/astrolus/demo
//

// <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
function Header(props) {
  return (
    <div
      className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      {...props}
    >
      <div className="mb-16 md:mb-0 md:max-w-xl sm:mx-auto md:text-center">
        <VideoPlayer
          src="/assets/video/ai_recruiter_demo_07_11_2023_en.mp4"
          poster="/assets/img/ai_recruiter_2.webp"
        ></VideoPlayer>
        <HeaderDesignV2>
          <h1 className="my-5 font-sans s-text-4xl font-bold tracking-tight text-light-100 sm:leading-none">
            Hiring solution
            <br />
            for
            <br />
            <div className="relative">
              <span className="absolute inset-0 animate-text-loop">
                <Gradient>Freelancers</Gradient>
              </span>
              <span className="absolute inset-0 animate-text-loop">
                <Gradient>Founders</Gradient>
              </span>
            </div>
            <br />
          </h1>
          <h2 className="mb-5 text-light-300 s-text-base">
            As founders, we don't have time to interview 3 or 10 candidates.
            Delegate all your interviews to AI Cartel, so you can focus more on
            business-sensitive tasks.
          </h2>
        </HeaderDesignV2>
        <CallToAction />
      </div>
    </div>
  );
}

// <h1 className="my-5 font-sans s-text-4xl font-bold tracking-tight text-light-100 sm:leading-none">
//   Find candidates
//   <br />
//   <Gradient>10 x faster</Gradient>
//   <br />
//   with <Gradient>AI</Gradient>
// </h1>
// <h2 className="mb-5 text-light-300 s-text-base">
//   Find and interview candidates using voice automation to save you 20
//   billable hours for each hire.
// </h2>

function ShortHeader3(props) {
  return (
    <HeaderShortMessages {...props}>
      <HeaderShortMessagesItem title="For companies">
        This AI interviews and hires humans to do tasks for you. With great AI
        comes great responsibility.
      </HeaderShortMessagesItem>

      <HeaderShortMessagesItem title="For candidates">
        No more excuses; AI will find a job for you. No resume is needed.
      </HeaderShortMessagesItem>
    </HeaderShortMessages>
  );
}

//
function CallToAction({ className = "", reverse = false }) {
  const ButtonPostJob = reverse ? BorderButtonLink : FullButtonLink;
  const ButtonFindJob = reverse ? FullButtonLink : BorderButtonLink;

  return (
    <div className="grid justify-items-center">
      <div className={`flex place-content-center gap-3 ${className}`}>
        <ButtonPostJob
          to="/apps/ai-recruiter/company/post-job"
          className="w-64 justify-center"
        >
          <h2>
            Post job
            <span className="text-xs -translate-y-3"> Free</span>
          </h2>
        </ButtonPostJob>
        <ButtonFindJob
          to="/apps/ai-recruiter/candidate/find-job"
          className="w-64 justify-center"
        >
          <h2>Find job</h2>
        </ButtonFindJob>
        <br />
      </div>
      <FullButtonLink
        to="/apps/ai-recruiter/referrer/find-bounty"
        className="bg-pink-600 text-light-100 w-64 justify-center"
      >
        Refer $$$
      </FullButtonLink>
    </div>
  );
}

function DoYouNeedAiRecruiterDiagram() {
  return (
    <div className="flex items-center justify-center">
      <img
        src="/assets/img/ai_recruiter_do_you_use_diagram.webp"
        alt="Decision diagram to show if you need AI Recruiter?"
        className="max-w-[90vw] md:max-w-screen-md max-h-[90vh] w-auto h-auto"
      />
    </div>
  );
}

const stepsData = [
  {
    step: "01",
    description: "Talk briefly with our AI to describe your ideal candidate.",
    title: "Talk to AI",
  },
  {
    step: "02",
    description:
      "Candidates who visit your job offer will be interviewed instantly.",
    title: "Wait and relax",
  },
  {
    step: "03",
    description:
      "Receive a list of candidates ranked by how well they match your job description, along with AI-conducted interviews audio recordings.",
    title: "Receive candidates",
    success: true,
  },
];

export const AIRecruiterProcess = () => {
  return (
    <StepCards
      title="HOW IT WORKS?"
      description="3 steps to find ready-to-hire candidates"
      stepsData={stepsData}
    />
  );
};

const stepsDataForWho = [
  {
    title: "Small business",
    description:
      "As a small business, you don't have time to interview 10 candidates for one position. Let the AI pre-interview candidates for you.",
  },
  {
    title: "High volume High Turnover",
    description:
      "If you are hiring 100 candidates within 6 months, then using AI-voice for pre-interviews is key. Contact us to integrate our white label AI-voice into your product at a competitive price.",
  },
];

// const stepsDataForWho = [
//   {
//     title: "Headhunters",
//     description: "Find Elite talent for leadership and niche roles.",
//   },
//   {
//     title: "Talent Acquisition Teams",
//     description: "Prefect for Companies with hiring difficulties.",
//   },
//   {
//     title: "Recruiters",
//     description: "Effective solutions for high turnover challenges / interims.",
//   },
// ];

const AIRecruiterForWho = () => {
  return (
    <StepCards
      title="For whom is this AI?"
      description=""
      stepsData={stepsDataForWho}
    />
  );
};

function AIRecruiterReport() {
  return (
    <div className="flex flex-col items-center justify-center">
      <Title2>AI Recruiter report example</Title2>
      <img
        src="/assets/img/ai_recruiter_candidate_report.webp"
        alt="AI Recruiter report"
        className="max-w-[90vw] md:max-w-screen-md max-h-[90vh] w-auto h-auto"
      />
    </div>
  );
}

function OurStory() {
  return (
    <div className="flex flex-col items-center justify-center">
      <Title2>Story telling</Title2>
      As the solo founder of my previous project that was acquired by
      Offensive360, I found myself constantly hiring and interviewing candidates
      to keep the business running smoothly. On average, it took me five
      meetings to find the right freelancers for each new task. This process was
      not only time-consuming but also drained my energy. Determined to
      streamline my hiring process, I conceived AI Cartel — a solution designed
      to automate candidate sourcing and interviewing. Just talk for 5 minutes
      to AI Cartel, describe your tasks and ideal candidate, and while AI Cartel
      finds and interviews candidates, you can focus directly on onboarding the
      best candidate and more business-sensitive tasks.
    </div>
  );
}

// <Section id="pricing" children={<Pricing />} />
// <Section children={<AIRecruiterProcess />} />
// <ShortHeader3 className="py-10" />
export default function AIRecruiterLandingPage() {
  return (
    <div className="max-w-screen-2xl mx-auto px-3">
      <Header id="ai-recruiter-landing-page-header" />
      <Section children={<ReferencesLogo />} />
      <Section children={<OurStory />} />
      <HowItWorks />
      <Section children={<AIRecruiterForWho />} />
      <Section id="pricing" children={<Pricing />} />
      <Section children={<DoYouNeedAiRecruiterDiagram />} />
      <Section children={<AIRecruiterReport />} />
      <Section id="faq" children={<FAQ />} />
      <Section id="about" children={<About />} />
      <Section id="contact" children={<Contact />} />
      <div className="pb-24 sm:pb-64"></div>
      <BottomBanner neighborID="ai-recruiter-landing-page-header">
        <CallToAction className="text-dark-300" reverse />
      </BottomBanner>
    </div>
  );
}
