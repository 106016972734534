// import React from "react";
// import { Accordeon, Title2 } from "../components/DesignComponents";
//
// const faqData = [
//   {
//     question: "What is the price of using AI Recruiter?",
//     answer:
//       "AI Recruiter is mostly free of charge. We only ask you to promote our platform on social media.",
//   },
//   {
//     question: "How are we making money?",
//     answer: (
//       <div>
//         While our core services is free, we will introduce paid features to
//         support our platform's growth and sustainability. For candidates, we
//         will be offering in-depth interview feedback to help them refine their
//         interview skills. Companies can also benefit from special features
//         designed to enhance their job offer visibility. These bonus features not
//         only help our users get the best out of our platform but also allow us
//         to continue offering our primary services at no cost.
//       </div>
//     ),
//   },
// ];
//
// export default function Pricing() {
//   return (
//     <div>
//       <Title2>
//         <h2># Pricing for companies and candidates</h2>
//       </Title2>
//
//       <Accordeon data={faqData} />
//     </div>
//   );
// }

import { FullButtonLink, Title2 } from "components/DesignComponents";
import React from "react";

// Individual Price Card Component
const PriceCard = ({
  plan,
  price,
  features,
  buttonText,
  isHighlighted,
  discount,
  to,
}) => {
  const highlightStyles = isHighlighted
    ? "bg-dark-300 text-white"
    : "bg-white text-gray-800";

  return (
    <div className={`p-4 shadow-md ${highlightStyles}`}>
      <h3 className="s-text-xl font-semibold">{plan}</h3>
      {discount ? (
        <div>
          <p className="text-2xl font-bold line-through">
            {price}
            <span className="text-lg"></span>
          </p>
          <div className="text-4xl font-bold">{discount}</div>
        </div>
      ) : (
        <p className="text-4xl font-bold">
          {price}
          <span className="text-lg"></span>
        </p>
      )}
      <ul>
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <span className="text-green-500 mr-2">✔</span> {feature}
          </li>
        ))}
      </ul>
      <FullButtonLink to={to}>{buttonText}</FullButtonLink>
    </div>
  );
};

export const VSR_job_posting_price = "126.00";

// const pricingPlans = [
//   {
//     plan: "Candidate",
//     // price: "10.00",
//     // discount: "Free",
//     price: "Free",
//     to: "/apps/ai-recruiter/candidate/find-job",
//     features: [
//       "Access to all jobs",
//       "multiple job interview",
//       "AI provided feedbacks",
//     ],
//     buttonText: "Find a job",
//     trial: true,
//     isHighlighted: false,
//   },
//   {
//     plan: "Recruiter Silver",
//     // price: VSR_job_posting_price,
//     // discount: "Free",
//     price: "Free",
//     to: "/apps/ai-recruiter/company/post-job",
//     features: [
//       "Illimited job post",
//       "Illimited AI-conducted interviews",
//       "Access to candidate full interview",
//     ],
//     buttonText: "Post a job",
//     trial: false,
//     isHighlighted: true,
//   },
//   {
//     plan: "Recruiter Gold",
//     price: "320.00",
//     discount: "1.00 €",
//     to: "/apps/ai-recruiter/company/post-job",
//     features: [
//       "Everything in Recruiter silver",
//       "Option to add a referral bounty",
//     ],
//     buttonText: "Post a job",
//     trial: false,
//     isHighlighted: true,
//   },
// ];

// Main Pricing Component
const Pricing = () => {
  const pricingPlans = [
    {
      plan: "Solo-Founder",
      price: "219€ - one time",
      discount: "Free",
      to: "/apps/ai-recruiter/company/post-job",
      features: [
        "10 job post.",
        "100 AI-conducted interviews.",
        "Access to AI report.",
        "Access to full interview transcript.",
        <div className="text-orange-600 font-bold">
          Early bird - free until 30 July 2024.
        </div>,
      ],
      buttonText: "Post a job",
      trial: false,
      isHighlighted: false,
    },
    {
      plan: "Freelancer",
      price: "419€ - one time",
      // discount: "Free",
      // price: "Free",
      to: "/apps/ai-recruiter/company/post-job",
      features: [
        "30 job post.",
        "1000 AI-conducted interviews.",
        "Access to AI report.",
        "Access to full interview transcript.",
        <span className="font-bold text-orange-600">
          Referral bounty feature.
        </span>,
      ],
      buttonText: "Post a job",
      trial: true,
      isHighlighted: true,
    },

    // {
    //   plan: "Founder",
    //   price: "42€ / month",
    //   // discount: "1.00 €",
    //   to: "/apps/ai-recruiter/company/post-job",
    //   features: ["Everything in Solo-Founder.", "Referral bounty feature."],
    //   buttonText: "Post a job",
    //   trial: false,
    //   isHighlighted: true,
    // },
    {
      plan: "High volume hiring",
      price: "10 000€ one-time",
      // discount: "1.00 €",
      to: "/ai-recruiter/#contact",
      features: [
        "White label AI-voice.",
        "Integrate our AI in your product.",
        "Dedicated support.",
        "Virtual speed recruiting.",
        "Access to candidate audio interviews.",
        "Unlimited job post.",
        "Unlimited AI-conducted interviews.",
      ],
      buttonText: "Contact US",
      trial: false,
      isHighlighted: true,
    },
  ];

  return (
    <div>
      <Title2>
        <h2>Pricing</h2>
      </Title2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
        {pricingPlans.map((plan, index) => (
          <PriceCard key={index} {...plan} />
        ))}
      </div>
      A referral bounty is an amount defined by the hiring company. We recommend
      a 1000€ bounty but this depends on your industry. Our team of headhunters
      will focus on finding candidates for jobs with the highest referral
      bounties.
    </div>
  );
};

export default Pricing;
