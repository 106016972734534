import { useEffect, useRef, useState } from "react";

function Svg() {
  return (
    <svg className="w-20" fill="currentColor" viewBox="0 0 24 24">
      <path d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z" />
    </svg>
  );
}

export default function VideoPlayer(props) {
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    const videoElem = videoRef.current;
    const pauseOtherVideos = () => {
      document.querySelectorAll("video").forEach((vid) => {
        if (vid !== videoElem) {
          vid.pause();
        }
      });
    };

    videoElem.addEventListener("play", pauseOtherVideos);

    // Clean up the event listener when the component unmounts
    return () => {
      videoElem.removeEventListener("play", pauseOtherVideos);
    };
  }, [videoRef]);

  const handleVideoPlay = (event) => {
    event.preventDefault();
    setIsVideoPlaying(true); // Update state when video is played
  };

  const handleVideoPause = (event) => {
    event.preventDefault();
    setIsVideoPlaying(false); // Update state when video is paused
  };

  const handlePlayClick = (event) => {
    event.preventDefault();
    // is click released
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.play();
    } else if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  // <div className="relative mb-6 sm:mx-auto md:mb-10 md:max-w-md lg:max-w-3xl">
  return (
    <div className="relative sm:mx-auto md:max-w-md lg:max-w-3xl">
      <video
        ref={videoRef}
        className="object-cover rounded shadow-lg"
        onPlay={handleVideoPlay}
        onPause={handleVideoPause}
        {...props}
      ></video>
      <div
        className="absolute inset-0 flex items-center justify-center w-full h-full group"
        onClick={handlePlayClick}
      >
        {!isVideoPlaying && (
          <div className="text-indigo-500 flex items-center justify-center w-32 h-32 transition duration-300 transform bg-gray-100 rounded-full shadow-2xl group-hover:scale-125">
            <Svg />
          </div>
        )}
      </div>
    </div>
  );
}
