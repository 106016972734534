import { toast } from "react-toastify";
import useApi from "../utils/Api";
import {
  Centered,
  Title,
  FullButton,
  MyTD,
  Table,
} from "../components/DesignComponents";
import useAsyncData from "../utils/NetworkUtils";
import { useState } from "react";
import { formatDayMonth } from "../utils/DateUtils";
import ConfirmedEmail from "../features/ConfirmedEmail";
import { DeleteButton } from "components/IconButtons";

function WaitlistEntryWithCallback(setRefreshWaitlistCallback) {
  const WaitlistEntry = ({ entry, index, ...props }) => {
    const { apiDeleteWaitlist } = useApi();
    const { created_at, mail_confirmed, application_name, email } = entry;

    const deleteWaitlist = async () => {
      const { status } = await apiDeleteWaitlist(email);
      if (status === "ok") {
        toast.success(`Successfully deleted ${email}`);
        setRefreshWaitlistCallback((prev) => !prev);
      }
    };

    return (
      <tr {...props}>
        <MyTD>{index}</MyTD>
        <MyTD>{formatDayMonth(created_at)}</MyTD>
        <MyTD>{application_name}</MyTD>
        <MyTD>
          <ConfirmedEmail email={email} mail_confirmed={mail_confirmed} />
        </MyTD>
        <MyTD>
          <DeleteButton onClick={deleteWaitlist} />
        </MyTD>
      </tr>
    );
  };
  return WaitlistEntry;
}

export default function WaitlistAdmin() {
  const [refreshWaitlist, setRefreshWaitlist] = useState(true);
  const { apiGetAllWaitlist } = useApi();
  const waitlist = useAsyncData(apiGetAllWaitlist, refreshWaitlist);
  const WaitlistEntry = WaitlistEntryWithCallback(setRefreshWaitlist);
  const headers = ["Index", "Created At", "Application Name", "Email", ""];

  if (!waitlist) {
    return <div>Loading...</div>;
  }
  return (
    <section className="border-t border-gray-800 py-10">
      <Centered>
        <Title>
          <h3>Admin Waitlist</h3>
        </Title>
        <Table
          headers={headers}
          data={waitlist}
          EntryViewer={WaitlistEntry}
          keyName={(x) => x["email"]}
        />
      </Centered>
    </section>
  );
}
