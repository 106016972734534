import { useState } from "react";
import {
  DesignedLink,
  FullButton,
  Input,
  Title,
} from "../components/DesignComponents";
import useApi from "../utils/Api";
import ErrorMessages from "../components/ErrorMessages";
import PrivacyCheckbox from "../components/PrivacyCheckbox";

const Form = ({ handleSubmit, tmpEmail, setTmpEmail }) => (
  <form className="grid justify-items-center p-4 m-4" onSubmit={handleSubmit}>
    {/* PUT LOGO HERE */}
    <br />
    <Input
      label="Email"
      placeholder="Enter your email"
      value={tmpEmail}
      onChange={setTmpEmail}
    />

    <PrivacyCheckbox />
    <FullButton type="submit" propagate>
      Register
    </FullButton>
    <DesignedLink to="/login">Login</DesignedLink>
  </form>
);

function SuccessMessage() {
  return (
    <div>
      Registration successful !
      <br />
      Please check your email to login.
    </div>
  );
}

export function Register() {
  const [tmpEmail, setTmpEmail] = useState("");
  const { apiRegisterUser } = useApi();
  const [requestResponse, setRequestResponse] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (tmpEmail) {
      const { status, message } = await apiRegisterUser(tmpEmail);
      setRequestResponse({ status, message });
    }
    console.log("Register email", tmpEmail);
  };

  return (
    <div className="flex items-center justify-center min-h-[50vh]">
      <div className="border border-light-300 p-8">
        <Title className="pb-4">
          <h1>Register</h1>
        </Title>
        <ErrorMessages
          requestResponse={requestResponse}
          successMessage={<SuccessMessage />}
        >
          <Form
            tmpEmail={tmpEmail}
            setTmpEmail={setTmpEmail}
            handleSubmit={handleSubmit}
          />
        </ErrorMessages>
      </div>
    </div>
  );
}

export function Logout() {
  return <div>Logout</div>;
}
