import React, { createContext, useContext } from "react";
import { useSearchParamsInteger } from "utils/StorageUtils";
import { useGameContext } from "debate/GameContext";

// -----------------------------------------------
//               Job Interview Context
// -----------------------------------------------
export const FindJobContext = createContext();

export function useFindJobContext() {
  return useContext(FindJobContext);
}

export const FindJobContextProvider = (props) => {
  const { gameUUID, setGameUUID, email, setEmail, saveEmail } =
    useGameContext();
  const [currentStep, setCurrentStep] = useSearchParamsInteger(
    "step",
    1,
    (v) => {
      if (v < 1) return 1;
      if (v > 3) return 3;
      return v;
    }
  );

  return (
    <FindJobContext.Provider
      value={{
        discussionUUID: gameUUID,
        setDiscussionUUID: setGameUUID,
        currentStep,
        setCurrentStep,
        email,
        setEmail,
        saveEmail,
      }}
    >
      {props.children}
    </FindJobContext.Provider>
  );
};
