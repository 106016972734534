const CompanyLogo = ({ src, alt }) => {
  return (
    <div className="flex items-center justify-center h-24 w-24 md:h-32 md:w-32 lg:h-40 lg:w-40 m-4">
      <img src={src} alt={alt} className="max-h-full max-w-full" />
    </div>
  );
};

export const ReferencesLogo = () => {
  const logos = [
    {
      src: "/assets/img/references/marie/microsoft-logo.svg",
      alt: "Microsoft",
    },
    { src: "/assets/img/references/sanson/capgemini.png", alt: "Capgemini" },
    { src: "/assets/img/references/marie/expedia logo.png", alt: "Expedia" },
    {
      src: "/assets/img/references/marie/European-Commission.png",
      alt: "European Commission",
    },
    {
      src: "/assets/img/references/sanson/alstom.svg",
      alt: "Alstom",
    },
    { src: "/assets/img/references/sanson/justmining.png", alt: "Just-Mining" },
    { src: "/assets/img/references/sanson/gosport.png", alt: "Gosport" },
    { src: "/assets/img/references/sanson/riskeco.png", alt: "Riskeco" },

    { src: "/assets/img/references/marie/ubisoft-logo.png", alt: "Ubisoft" },

    { src: "/assets/img/references/marie/Cloudbeds.png", alt: "Cloudbeds" },
    {
      src: "/assets/img/references/marie/full-polygon-logo.png",
      alt: "Full Polygon",
    },
    { src: "/assets/img/references/marie/Solana-SOL.png", alt: "Solana" },
  ];

  return (
    <div className="bg-light-100 m-2 p-6 border border-dark-200">
      <h2 className="text-xl md:text-2xl font-bold text-center mb-6 text-dark-300">
        Our experts were trusted by
      </h2>
      <div className="flex flex-wrap justify-center items-center">
        {logos.map((logo, index) => (
          <CompanyLogo key={index} src={logo.src} alt={logo.alt} />
        ))}
      </div>
    </div>
  );
};
