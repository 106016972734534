import { CopyToClipboardButton } from "components/IconButtons";

export default function ConfirmedEmail({ email, mail_confirmed }) {
  return (
    <div className="flex">
      <div
        className={
          mail_confirmed
            ? "border-b-2 border-green-400"
            : "border-b-2 border-orange-400"
        }
      >
        {email}
      </div>
      <CopyToClipboardButton text={email} />
    </div>
  );
}
