import { useContext } from "react";
import { GameContext } from "../debate/GameContext"; // THIS
import notifications from "./Notifications";
import useAuthenticatedApi from "./AuthenticatedApi";

function useApi() {
  const {
    adminSecret: admin_secret,
    authToken: auth_token,
    setAuthToken,
    email,
    setEmail,
    isConnected,
    gameUUID: uuid,
    referralCode,
    userSource,
  } = useContext(GameContext);
  const { axiosPostSilence, axiosPost, fetchPost } = useAuthenticatedApi();
  // -------------------------------------------------------------------------
  //                       Discussion API
  // -------------------------------------------------------------------------
  // async function apiWarmup() {
  //   return await axiosPost("/api/discussion/warmup", {});
  // }

  async function apiCreateDiscussion(
    application_name,
    email,
    language,
    is_public,
    requiredArgs
  ) {
    const url = "/api/discussion/create_discussion";
    var data = {
      email,
      language,
      is_public,
      application_name,
      ...requiredArgs,
    };
    console.log("apiCreateDiscussion", data);
    console.log("apiCreateDiscussion requiredArgs", requiredArgs);
    return await axiosPostSilence(url, data);
  }
  async function apiStartDiscussion(uuid) {
    const url = "/api/discussion/start_discussion";
    console.log("RC", referralCode.value);
    return await axiosPostSilence(url, {
      uuid,
      referral_code: referralCode.value,
      user_source: userSource,
    });
  }
  async function apiDeleteGame(uuid) {
    const url = "/api/discussion/game/delete";
    return await axiosPost(url, { uuid });
  }
  async function apiGetAudioInfo(uuid) {
    const url = "/api/discussion/game/get_audio_info";
    return await axiosPost(url, { uuid });
  }
  async function apiGetLanguageList() {
    const url = "/api/languages_list";
    return (await axiosPost(url, {})).languages;
  }
  async function apiGetStatus(uuid) {
    const url = "/api/discussion/get_status";
    return await axiosPost(url, { uuid });
  }
  async function apiReplay() {
    return await axiosPost("/api/discussion/replay", {});
  }

  function addAuthToFormData(formData, gameUUID) {
    formData.append("uuid", gameUUID);
    formData.append("auth_token", auth_token);
    formData.append("auth_email", email);
    formData.append("admin_secret", admin_secret);
  }

  async function apiGetAudio(gameUUID) {
    const formData = new FormData();
    addAuthToFormData(formData, gameUUID);
    return await fetchPost("/api/discussion/game/get_audio", formData);
  }

  async function apiSendAudioData(
    userAnswer,
    audioData,
    gameUUID,
    email,
    authToken
  ) {
    const formData = new FormData();
    addUserAnswerToFormData(formData, userAnswer, audioData);
    addAuthToFormData(formData, gameUUID);
    return await fetchPost("/api/discussion/speak", formData);
  }

  function addUserAnswerToFormData(formData, userAnswer, audioData) {
    if (audioData) {
      formData.append("audio_data", audioData, "audio.wav");
    }
    if (userAnswer) {
      formData.append("user_answer_text", userAnswer);
    }
  }

  async function apiSendAudioBeforeEnd(
    userAnswer,
    audioData,
    gameUUID,
    email,
    authToken
  ) {
    const formData = new FormData();
    addAuthToFormData(formData, gameUUID);
    addUserAnswerToFormData(formData, userAnswer, audioData);
    return await fetchPost("/api/discussion/speak/before", formData);
  }

  async function apiContinueReceiveAIAudio(gameUUID, email, authToken) {
    const formData = new FormData();
    addAuthToFormData(formData, gameUUID);
    return await fetchPost("/api/discussion/continue_speak", formData);
  }

  // -------------------------------------------------------------------------
  //                      Application API
  // -------------------------------------------------------------------------
  async function apiListApplication() {
    const url = "/api/application/list";
    const response = await axiosPost(url, {});
    return response.applications;
  }
  async function apiCreateApplication(
    application_name,
    discussion_prompt,
    discussion_start_prompt
  ) {
    const url = "/api/application/create";
    var data = {
      application_name,
      discussion_prompt,
      discussion_start_prompt,
      admin_secret,
    };
    return await axiosPost(url, data);
  }
  async function apiUpdateApplication(
    application_name,
    discussion_prompt,
    discussion_start_prompt
  ) {
    const url = "/api/application/update";
    var data = {
      application_name,
      discussion_prompt,
      discussion_start_prompt,
      admin_secret,
    };
    return await axiosPost(url, data);
  }
  async function apiDeleteApplication(application_name) {
    const url = "/api/application/delete";
    var data = { application_name, admin_secret };
    return await axiosPost(url, data);
  }
  async function apiGetApplication(application_name) {
    const url = "/api/application/get";
    var data = { application_name, admin_secret };
    return await axiosPost(url, data);
  }
  async function apiChangeAIVoice(uuid, game_secret) {
    const url = "/api/discussion/change_ai_voice";
    return await axiosPost(url, { uuid });
  }

  // -------------------------------------------------------------------------
  //                       Waitlist API
  // -------------------------------------------------------------------------
  async function apiJoinWaitlist(email, application_name) {
    const url = "/api/waitlist/join";
    var data = { application_name, email };
    return await axiosPost(url, data);
  }
  async function apiConfirmEmail(email, auth_token) {
    return await axiosPost("/api/confirm_email", { email, auth_token });
  }
  async function apiGetAllWaitlist() {
    return (await axiosPost("/api/waitlist/get_all", {})).waitlist;
  }
  async function apiDeleteWaitlist(email) {
    return await axiosPost("/api/waitlist/delete", { email });
  }

  // -------------------------------------------------------------------------
  //                       Users API
  // -------------------------------------------------------------------------
  function encodeRedirectUrl(redirect_url) {
    if (redirect_url) {
      return encodeURIComponent(btoa(redirect_url));
    } else {
      return "";
    }
  }

  async function apiUserStatusOK() {
    if (isConnected) {
      return (await axiosPost("/api/user/status", {}).status) === "ok";
    }
    return false;
  }
  async function apiLoginOrRegisterUser(auth_email, redirect_url) {
    redirect_url = encodeRedirectUrl(redirect_url);
    return await axiosPostSilence("/api/user/login_or_register", {
      auth_email,
      redirect_url,
      referral_code: referralCode.value,
    });
  }
  async function apiRegisterUser(auth_email, redirect_url) {
    redirect_url = encodeRedirectUrl(redirect_url);
    return await axiosPostSilence("/api/user/register", {
      auth_email,
      redirect_url,
      referral_code: referralCode.value,
    });
  }
  async function apiLoginUser(auth_email, redirect_url) {
    redirect_url = encodeRedirectUrl(redirect_url);
    setAuthToken("");
    return await axiosPostSilence("/api/user/login", {
      auth_email,
      redirect_url,
    });
  }
  async function apiGetAuthToken(auth_email, auth_token) {
    const { status, ...response } = await axiosPostSilence(
      "/api/user/get_auth_token",
      {
        auth_email,
        auth_token,
      }
    );
    if (status === "ok") {
      setEmail(auth_email);
      setAuthToken(auth_token);
      notifications.success("Logged in successfully!");
    } else {
      setEmail("");
      setAuthToken("");
      // notifications.error("Login failed!");
    }
    return { status, ...response };
  }
  async function apiGetAllUsers() {
    return (await axiosPost("/api/users/get_all", {})).users;
  }
  async function apiDeleteUser(email) {
    return await axiosPost("/api/user/delete", { email });
  }

  async function apiIncreaseUserCredit(email) {
    return await axiosPost("/api/user/increase-credit", { email });
  }

  // -------------------------------------------------------------------------
  //                       AI Recruiter - jobs API
  // -------------------------------------------------------------------------
  //
  async function apiAIRecruiterCreateJob(
    application_name,
    email,
    language,
    is_public,
    captcha,
    requiredArgs
  ) {
    const url = "/api/apps/ai-recruiter/company/create-job";
    var data = {
      email,
      language,
      is_public,
      application_name,
      referral_code: referralCode.value,
      "g-recaptcha-response": captcha,
      ...requiredArgs,
    };
    console.log("apiCreateJob", data);
    console.log("apiCreateJob requiredArgs", requiredArgs);
    return await axiosPostSilence(url, data);
  }
  async function apiAIRecruiterViewJob(uuid) {
    return (
      await axiosPost("/api/apps/ai-recruiter/job/view", {
        uuid,
      })
    ).job;
  }
  async function apiAIRecruiterSaveJob({ uuid, forced_by_admin = undefined }) {
    return await axiosPost("/api/apps/ai-recruiter/company/job/save", {
      uuid,
      forced_by_admin,
    });
  }

  async function apiAIRecruiterToggleJob(uuid) {
    return await axiosPostSilence("/api/apps/ai-recruiter/job/toggle", {
      uuid,
    });
  }
  async function apiAIRecruiterDeleteJob(uuid) {
    return await axiosPostSilence("/api/apps/ai-recruiter/job/delete", {
      uuid,
    });
  }
  async function apiAIRecruiterAllJobs({
    virtual_speed_recruiting,
    show_bounty_only = false,
  }) {
    console.log(
      "apiAIRecruiterAllJobs show_bounty_only",
      show_bounty_only,
      virtual_speed_recruiting
    );

    var data = { show_bounty_only };
    if (
      virtual_speed_recruiting &&
      virtual_speed_recruiting != "View all jobs"
    ) {
      data.virtual_speed_recruiting = virtual_speed_recruiting;
    }

    return await axiosPost("/api/apps/ai-recruiter/all-jobs", data);
  }
  async function apiAIRecruiterMyJobs() {
    return await axiosPost("/api/apps/ai-recruiter/my-jobs", {});
  }
  async function apiAIRecruiterAllJobsAdmin() {
    return (
      await axiosPost("/api/apps/ai-recruiter/all-jobs/admin", {
        is_public: true,
        is_available: true,
      })
    ).jobs;
  }
  async function apiAIRecruiterEditJob(uuid, job_description, title) {
    return await axiosPost("/api/apps/ai-recruiter/job/edit", {
      uuid,
      job_description,
      title,
    });
  }

  // -------------------------------------------------------------------------
  //                       AI Recruiter - candidates API
  // -------------------------------------------------------------------------
  //
  async function apiAIRecruiterDoInterview(
    application_name,
    email,
    language,
    is_public,
    captcha,
    requiredArgs
  ) {
    const url = "/api/apps/ai-recruiter/candidate/create-interview";
    console.log("RC", referralCode.value);
    var data = {
      email,
      language,
      is_public,
      application_name,
      "g-recaptcha-response": captcha,
      referral_code: referralCode.value,
      ...requiredArgs,
    };
    console.log("apiAIRecruiterDoInterview", data);
    console.log("apiAIRecruiterDoInterview requiredArgs", requiredArgs);
    return await axiosPostSilence(url, data);
  }

  async function apiAIRecruiterViewCandidate(uuid) {
    return (
      await axiosPost("/api/apps/ai-recruiter/company/candidate/view", { uuid })
    ).candidate;
  }

  async function apiAIRecruiterSaveCandidate(
    uuid,
    forced_by_admin = undefined
  ) {
    return await axiosPost("/api/apps/ai-recruiter/candidate/save", {
      uuid,
      forced_by_admin,
    });
  }

  async function apiAIRecruiterAllCandidates({ job_uuid }) {
    return await axiosPost("/api/apps/ai-recruiter/company/all-candidates", {
      uuid: job_uuid,
    });
  }

  async function apiAIRecruiterCandidateApplications() {
    return await axiosPost("/api/apps/ai-recruiter/candidate/applications", {});
  }

  async function apiAIRecruiterAllCandidatesAdmin(
    is_public = undefined,
    is_available = undefined
  ) {
    return (
      await axiosPost("/api/apps/ai-recruiter/all-candidates/admin", {
        is_public,
        is_available,
      })
    ).candidates;
  }

  async function apiAIRecruiterDeleteCandidateAdmin(uuid) {
    return await axiosPost("/api/apps/ai-recruiter/admin/delete", { uuid });
  }

  // -------------------------------------------------------------------------
  //                       PitchMe
  // -------------------------------------------------------------------------
  async function apiPitchMeSavePitch(uuid, forced_by_admin = undefined) {
    return await axiosPost("/api/apps/pitchme/founder/save", {
      uuid,
      forced_by_admin,
    });
  }

  async function apiPitchMeAdminDeletePitch(uuid) {
    return await axiosPost("/api/apps/pitchme/admin/pitch/delete", { uuid });
  }

  async function apiPitchMeAdminAllPitches(
    is_public = undefined,
    is_available = undefined
  ) {
    return (
      await axiosPost("/api/apps/pitchme/admin/all-pitches", {
        is_public,
        is_available,
      })
    ).founders;
  }

  // -------------------------------------------------------------------------
  //                       Speech To text
  // -------------------------------------------------------------------------
  async function apiGetAssemblyToken() {
    return (await axiosPost("/api/speech_to_text/assemblyai/get_token", {}))
      .token;
  }

  // -------------------------------------------------------------------------
  //                       List data API
  // -------------------------------------------------------------------------
  async function apiDebugFlush() {
    return await axiosPost("/api/debug/flush", {});
  }

  async function apiAIRecruiterJobPay({ uuid }) {
    return await axiosPost("/api/apps/ai-recruiter/job/pay", { uuid });
  }

  async function apiStripeIsPaymentSuccess({ stripe_checkout_session_id }) {
    return await axiosPost("/api/stripe/is_payment_success", {
      stripe_checkout_session_id,
    });
  }

  // async function apiScoreboard() {
  //   const url = "/api/discussion/scoreboard";
  //   return (await axiosPost(url, {})).games;
  // }
  // -------------------------------------------------------------------------
  //                       Progres bar api
  // -------------------------------------------------------------------------

  async function apiGetTaskProgress(task_id) {
    const url = "/api/get-task-progress/";
    return await axiosPostSilence(url, { task_id });
  }

  return {
    apiDebugFlush,

    // apiWarmup,
    apiCreateDiscussion,
    apiStartDiscussion,
    apiGetStatus,
    apiReplay,
    apiSendAudioData,
    apiSendAudioBeforeEnd,
    apiContinueReceiveAIAudio,
    apiGetLanguageList,
    apiGetAudio,
    // apiScoreboard,
    apiDeleteGame,
    apiGetAudioInfo,

    apiListApplication,
    apiCreateApplication,
    apiGetApplication,
    apiUpdateApplication,
    apiDeleteApplication,

    apiChangeAIVoice,

    apiJoinWaitlist,
    apiConfirmEmail,
    apiGetAllWaitlist,
    apiDeleteWaitlist,

    apiUserStatusOK,
    apiLoginUser,
    apiRegisterUser,
    apiGetAllUsers,
    apiDeleteUser,
    apiGetAuthToken,
    apiLoginOrRegisterUser,
    apiIncreaseUserCredit,

    apiAIRecruiterCreateJob,
    apiAIRecruiterViewJob,
    apiAIRecruiterSaveJob,
    apiAIRecruiterToggleJob,
    apiAIRecruiterDeleteJob,
    apiAIRecruiterAllJobs,
    apiAIRecruiterMyJobs,
    apiAIRecruiterAllJobsAdmin,
    apiAIRecruiterEditJob,

    apiAIRecruiterDoInterview,
    apiAIRecruiterViewCandidate,
    apiAIRecruiterSaveCandidate,
    apiAIRecruiterAllCandidates,
    apiAIRecruiterCandidateApplications,
    apiAIRecruiterAllCandidatesAdmin,
    apiAIRecruiterDeleteCandidateAdmin,

    apiPitchMeSavePitch,
    apiPitchMeAdminDeletePitch,
    apiPitchMeAdminAllPitches,

    apiGetAssemblyToken,

    apiAIRecruiterJobPay,
    apiStripeIsPaymentSuccess,

    apiGetTaskProgress,
  };
}

export default useApi;
