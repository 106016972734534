import React from "react";

export const CandidateFitScore = ({ rating }) => {
  let bgColorClass;

  switch (rating) {
    case "Excellent Fit":
      bgColorClass = "bg-blue-500";
      break;
    case "Strong Fit":
      bgColorClass = "bg-green-500";
      break;
    case "Good Fit":
      bgColorClass = "bg-yellow-500";
      break;
    case "Fair Fit":
      bgColorClass = "bg-orange-500";
      break;
    case "Poor Fit":
      bgColorClass = "bg-red-500";
      break;
    default:
      bgColorClass = "bg-gray-500";
  }

  return (
    <div
      className={`m-1 p-1 md:p-2 ${bgColorClass} font-bold text-white text-center s-text-xs`}
    >
      {rating}
    </div>
  );
};

export const ExperienceLevelIndicator = ({ experience_level }) => {
  let bgColorClass;

  switch (experience_level) {
    case "Entry-Level":
      bgColorClass = "bg-blue-100"; // Light blue for beginners
      break;
    case "Junior":
      bgColorClass = "bg-blue-300"; // A slightly darker shade of blue
      break;
    case "Mid-Level":
      bgColorClass = "bg-blue-500"; // Standard blue for intermediate
      break;
    case "Senior":
      bgColorClass = "bg-blue-700"; // Dark blue for advanced
      break;
    case "Expert/Leader":
      bgColorClass = "bg-blue-900"; // Very dark blue for top-level expertise
      break;
    default:
      bgColorClass = "bg-gray-500"; // Gray for unspecified or other levels
  }

  if (!experience_level) {
    experience_level = "N/A";
  }
  return (
    <div
      className={`m-1 p-1 md:p-2 ${bgColorClass} font-bold text-dark text-center s-text-xs`}
    >
      {experience_level}
    </div>
  );
};

// Enum-like object for candidate status
const CandidateStatus = {
  INTERVIEW_IN_PROGRESS: "Interview in progress",
  INTERVIEW_ABORTED: "Interview aborted",
  WAITING_DECISION: "Waiting company decision",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  EXPIRED: "Expired",
};

export const CandidateStatusBadge = ({ status }) => {
  let bgColorClass;

  switch (status) {
    case CandidateStatus.INTERVIEW_IN_PROGRESS:
      bgColorClass = "bg-yellow-800";
      break;
    case CandidateStatus.INTERVIEW_ABORTED:
      bgColorClass = "bg-red-600";
      break;
    case CandidateStatus.WAITING_DECISION:
      bgColorClass = "bg-blue-800";
      break;
    case CandidateStatus.ACCEPTED:
      bgColorClass = "bg-green-500";
      break;
    case CandidateStatus.REJECTED:
      bgColorClass = "bg-gray-500";
      break;
    case CandidateStatus.EXPIRED:
      bgColorClass = "bg-black";
      break;
    default:
      bgColorClass = "bg-gray-400"; // Default color
  }

  return (
    <div
      className={`m-1 p-1 ${bgColorClass} text-white text-center s-text-xs font-semibold inline-flex`}
    >
      {status}
    </div>
  );
  // const classNames = `p-2 ${bgColorClass} text-white rounded inline-flex items-center text-xs font-semibold text-center`;
  //
  // return <span className={classNames}>{status}</span>;
};
