const StatisticCard = ({ title, number }) => {
  return (
    <div className="flex flex-col items-center justify-center bg-light-100 shadow-md p-4 m-2">
      <span className="text-dark font-semibold">{title}</span>
      <span className="text-xl md:text-2xl font-bold text-blue-600">
        {number}
      </span>
    </div>
  );
};

const Statistics = ({ stats }) => {
  return (
    <div className="flex flex-wrap justify-center items-center">
      {stats.map((stat, index) => (
        <StatisticCard key={index} title={stat.title} number={stat.number} />
      ))}
    </div>
  );
};

export default Statistics;
