import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import React, { useCallback, useState } from "react";
import { CheckboxLeft, FullButton, Input } from "components/DesignComponents";

import { Centered, Title3 } from "components/DesignComponents";
import AICartelPlayer from "AICartelPlayer";
import useApi from "utils/Api";
import ErrorMessages, { HandleHTTPErrors2 } from "components/ErrorMessages";
import {
  FindJobContextProvider,
  useFindJobContext,
} from "../context/JobInterviewContext";
import PrivacyCheckbox from "components/PrivacyCheckbox";
import { AuthRequired } from "auth/Login";
import { useGameContext } from "debate/GameContext";

// const find_job_step = [
//   {
//     title: "Be ready",
//   },
//   {
//     title: "Do the interview",
//   },
//   {
//     title: "Get feedbacks",
//   },
// ];
//
// function FindAJobProcess({ currentStep, setCurrentStep, ...props }) {
//   return (
//     <div {...props}>
//       <div className="flex place-content-center">
//         <Step2 steps={find_job_step} currentStep={currentStep} />
//       </div>
//     </div>
//   );
// }

function LoadedDoJobInterview({ job, job_uuid }) {
  const { currentStep, setCurrentStep } = useFindJobContext();
  const [AiAnswer, setAiAnswer] = useState(null);
  const [UserAnswer, setUserAnswer] = useState(null);

  function onAIAnswerFinished(ai_answer, user_answer) {
    setAiAnswer(ai_answer);
    setUserAnswer(user_answer);
  }

  const onAbortDiscussion = async (totalMinutes) => {
    if (totalMinutes < 2) {
      toast.warning(`Please answer at least 2 minutes of questions`);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onDiscussionFinished = async () => {
    setCurrentStep(currentStep + 1);
    toast.success("Discussion finished");
  };

  return (
    <Centered>
      <div>
        <Title3>Position: {job.job_title}</Title3>
        <AICartelPlayer
          end_button_text="Abort interview"
          onAbortDiscussion={onAbortDiscussion}
          onDiscussionFinished={onDiscussionFinished}
          onAIAnswerFinished={onAIAnswerFinished}
        ></AICartelPlayer>
      </div>
    </Centered>
  );
}

function Step1_DoJobInterview() {
  const { apiCreateDiscussion } = useApi();
  const { currentStep, setCurrentStep, email, setDiscussionUUID } =
    useFindJobContext();
  const [requestResponse, setRequestResponse] = useState(null);
  const { job_uuid } = useParams();
  const { phoneNumber, setPhoneNumber } = useGameContext();
  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();
      const applicationName = "AIRecruiter - candidate to one company";
      const language = "English (US)";
      const is_public = false;
      const requiredArgs = {
        job_uuid,
        phone_number: phoneNumber,
      };
      const {
        status,
        game_uuid: uuid,
        message,
      } = await apiCreateDiscussion(
        applicationName,
        email,
        language,
        is_public,
        requiredArgs
      );
      setRequestResponse({ status, message });
      if (status === "ok") {
        setDiscussionUUID(uuid);
        setCurrentStep(currentStep + 1);
      } else {
        console.log("createDiscussion error", message);
      }
    },
    [
      email,
      job_uuid,
      setCurrentStep,
      currentStep,
      setDiscussionUUID,
      apiCreateDiscussion,
      setRequestResponse,
    ]
  );

  return (
    <ErrorMessages
      requestResponse={requestResponse}
      successMessage="Continue to the next step"
    >
      <Title3>Be ready</Title3>
      <p className="m-3">Do not stress, the AI Recruiter will not judge you.</p>
      <form className="grid mx-auto place-items-center" onSubmit={handleSubmit}>
        <div className="grid">
          <Input
            label="Phone number"
            name="PhoneNumber"
            value={phoneNumber}
            onChange={setPhoneNumber}
            required
          />
          <CheckboxLeft
            label="You are in a quiet place?"
            setValue={() => {}}
            required
          />
          <CheckboxLeft
            label="You are ready for the job interview?"
            setValue={() => {}}
            required
          />
          <CheckboxLeft
            label="Your microphone is enabled?"
            setValue={() => {}}
          />
          <CheckboxLeft label="Don't cheat." setValue={() => {}} />
          <PrivacyCheckbox />
        </div>
        <div className="flex justify-center">
          <FullButton className="text-center" type="submit" propagate>
            Start the interview
          </FullButton>
        </div>
      </form>
    </ErrorMessages>
  );
}

function Step2_DoJobInterview() {
  const { currentStep } = useFindJobContext();
  const { job_uuid } = useParams();
  const { apiAIRecruiterViewJob } = useApi();
  const requestsState = useQuery(
    "viewjob",
    async () => {
      return await apiAIRecruiterViewJob(job_uuid);
    },
    {
      staleTime: 50000, // 50 seconds
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <HandleHTTPErrors2 {...requestsState}>
      <LoadedDoJobInterview job={requestsState.data} job_uuid={job_uuid} />
    </HandleHTTPErrors2>
  );
}

function Step3_DoJobInterview() {
  const { apiAIRecruiterSaveCandidate } = useApi();

  const { discussionUUID } = useFindJobContext();

  const requestsState = useQuery(
    "save-candidate",
    async () => {
      return await apiAIRecruiterSaveCandidate(discussionUUID);
    },
    {
      staleTime: 50000, // 50 seconds
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const mailConfirmed = requestsState.data?.mail_confirmed;

  return (
    <Centered>
      <Title3>Interview Finished</Title3>
      <HandleHTTPErrors2
        loadingMessage="Please wait : AI in progress.."
        {...requestsState}
      >
        <div className="flex flex-col place-items-center">
          Well done you finished the job interview. Expect an answer within a
          week.
          {!mailConfirmed && (
            <div className="s-text-xl text-orange-600">
              Please confirm your mail to finish the process.
            </div>
          )}
          <FullButton
            onClick={() =>
              toast.success(
                "Feedbacks are not enabled yet. Contact us on discord."
              )
            }
          >
            AI feedback (optional).
          </FullButton>
        </div>
      </HandleHTTPErrors2>
    </Centered>
  );
}

function DoJobInterviewWithContext() {
  const { currentStep } = useFindJobContext();

  // <FindAJobProcess currentStep={currentStep} />
  return (
    <div className="flex flex-col place-content-center">
      {currentStep == 1 && <Step1_DoJobInterview />}
      {currentStep == 2 && <Step2_DoJobInterview />}
      {currentStep == 3 && <Step3_DoJobInterview />}
    </div>
  );
}

export function DoJobInterview() {
  return (
    <AuthRequired>
      <FindJobContextProvider>
        <DoJobInterviewWithContext />
      </FindJobContextProvider>
    </AuthRequired>
  );
}
