import React from "react";
import { Accordeon, Title2 } from "../components/DesignComponents";

const faqData = [
  // {
  //   question: "What is the price of using AI Recruiter?",
  //   answer: "AI Recruiter is completely free of charge.",
  // },
  {
    question: "How is AI Recruiter different from other AI recruiting methods?",
    answer:
      "AI Recruiter not only searches for suitable candidates but also calls them and conducts job interviews with technical questions, all without human intervention. This ensures a faster and more efficient hiring process compared to other alternatives.",
  },
  {
    question: "How long does it take to configure AI Recruiter?",
    answer:
      "No configuration is required; it takes a maximum of 3 clicks to post a job. You'll need your microphone to answer questions from our AI for about 10 minutes. This process tailors the candidate search to your needs.",
  },
  {
    question:
      "How does AI Recruiter ensure the quality of candidates it interviews?",
    answer:
      "Think of teaching a junior HR to interview candidates. AI Recruiter is similar to a junior HR; it learns from you and improves over time. As a result, you can focus on top candidates only.",
  },
  {
    question: "I'm a candidate, does this mean I'm not interviewed by a human?",
    answer:
      "Don't worry, the final step of the interview is done by the recruiting company. Good news: you don't need a resume! Also, you don't need to find a job. Once AI Recruiter has interviewed you, it will find the best company for you depending on your values and requirements. For FREE!",
  },
  {
    question: "How does the referral work?",
    answer:
      "Head Hunters/referrers are hired on a success-based bounty defined by the hiring company. This allows for a highly efficient search for candidates operated by AI and the community.",
  },
  {
    question: "How big a bounty should I post as a company?",
    answer:
      "This is entirely up to you. A minimum of 1000 euros or 4% of Annual salary is the starting point however the bigger the bounties you post, the more interest you will get from referrers.",
  },
  {
    question: "How do I get paid as a referrer?",
    answer:
      "Once the company requests the candidate's contact information, they pay and you receive your part of the bounty.",
  },
  // {
  //   question: "What is ‘Reputation’?",
  //   answer:
  //     "Every referrer starts with a reputation of 100. For each recommendation that you make that is rejected as ‘Not Suitable’ your reputation will decrease. Each recommendation you make that is suitable your reputation increases by 1, if your recommendation is interviewed this increases to 5 and for a successful hire you will get a reputation enhancement of 10.",
  // },
  // {
  //   question: "How are we making money?",
  //   answer: (
  //     <div>
  //       While our core services is free, we will introduce paid features to
  //       support our platform's growth and sustainability. For candidates, we
  //       will be offering in-depth interview feedback to help them refine their
  //       interview skills. Companies can also benefit from special features
  //       designed to enhance their job offer visibility. These bonus features not
  //       only help our users get the best out of our platform but also allow us
  //       to continue offering our primary services at no cost.
  //     </div>
  //   ),
  // },
];

export default function FAQ() {
  return (
    <div>
      <Title2>
        <h2># FAQ?</h2>
      </Title2>

      <Accordeon data={faqData} />
    </div>
  );
}
