export default function Demo() {
  return <></>;
}

// import { FullButton } from "./components/DesignComponents";
// import {
//   AudioMotionContainers,
//   useAudioMotionAnalyzer,
// } from "./utils/AudioVizualizer";
// export default function Demo() {
//   // const audioMotionAI = useAudioMotionAnalyzer();
//   // const audioMotionHuman = useAudioMotionAnalyzer(true);
//   //
//   const { attach, detach, resume, radial } = useAudioMotionAnalyzer(true);
//   // console.log("audioMotionHuman", audioMotionHuman);
//
//   return (
//     <>
//       <AudioMotionContainers />
//       <FullButton onClick={radial}>radial</FullButton>
//       <FullButton onClick={attach}>attach</FullButton>
//       <FullButton onClick={detach}>detach</FullButton>
//       <FullButton onClick={resume}>resume</FullButton>
//       <audio
//         id="audio"
//         crossOrigin="anonymous"
//         src="/assets/audio/elevator_speech.mp3"
//         controls
//         autoPlay={true}
//       />
//     </>
//   );
// }
