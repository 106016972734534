import { createContext, useContext } from "react";
import { Input, Checkbox } from "../components/DesignComponents";
import DesignHelper from "../components/DesignHelper";

import {
  useFromParamThenStorage,
  useLocalStorageBool,
  useLocalStorageString,
  useLocalStorageStringV2,
} from "../utils/StorageUtils";
export const GameContext = createContext();

export function useGameContext() {
  return useContext(GameContext);
}

export const GameContextProvider = (props) => {
  const [showDesignHelper, setShowDesignHelper] =
    useLocalStorageBool("showDesignHelper");

  const [companyName, setCompanyName] = useLocalStorageString("companyName");
  const [phoneNumber, setPhoneNumber] = useLocalStorageString("phoneNumber");

  const [adminSecret, setAdminSecret] = useLocalStorageString("adminSecret");
  const [enableAdmin, setEnableAdmin] = useLocalStorageBool("enableAdmin");
  const [showAdminPanel, setShowAdminPanel] =
    useLocalStorageBool("showAdminPanel");

  const referralCode = useFromParamThenStorage("rc");
  const userSource = useFromParamThenStorage("src");

  const [authToken, setAuthToken] = useLocalStorageString("authToken");
  const [email, setEmail, saveEmail] = useLocalStorageStringV2("email");

  const [playerName, setPlayerName] = useLocalStorageString("playerName");
  const [language, setLanguage, saveLanguage] =
    useLocalStorageStringV2("language");

  const [gameUUID, setGameUUID] = useLocalStorageString("gameUUID");

  const [twitter, setTwitter] = useLocalStorageString("twitter");

  // const [vsrToken, setVsrToken] = useLocalStorageString("vsr_token");
  // const [vsr, setVsr] = useLocalStorageString("VirtualSpeedRecruiting");

  const isFirstCharacterZero = (input: string): boolean => {
    // Check if the string is not empty and its first character is "0"
    return input.length > 0 && input.charAt(0) === "0";
  };

  const isConnected = authToken.length > 0;
  // const isConnected = email.value.length > 0;

  const isAdmin = isFirstCharacterZero(adminSecret) && enableAdmin;

  const stopGame = () => {
    setGameUUID("");
  };

  const disconnect = () => {
    setGameUUID("");
    setPlayerName("");
    setEmail("");
    setTwitter("");
    setLanguage("");
    setAuthToken("");
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <GameContext.Provider
      value={{
        referralCode,
        userSource,
        discussionUUID: gameUUID,
        setDiscussionUUID: setGameUUID,
        gameUUID,
        setGameUUID,
        isConnected,
        disconnect,
        email,
        setEmail,
        saveEmail,
        playerName,
        setPlayerName,
        language,
        setLanguage,
        saveLanguage,
        twitter,
        setTwitter,
        stopGame,
        isAdmin,
        adminSecret,
        setAdminSecret,
        showAdminPanel,
        setShowAdminPanel,
        enableAdmin,
        setEnableAdmin,
        companyName,
        setCompanyName,
        phoneNumber,
        setPhoneNumber,
        authToken,
        setAuthToken,
        // vsrToken,
        // setVsrToken,
        // vsr,
        // setVsr,
      }}
    >
      {showAdminPanel && (
        <div className="grid grid-cols-2 gap-4 place-content-stretch h-20 text-center pt-32">
          <div className="flex flex-col">
            <Checkbox
              value={showAdminPanel}
              setValue={setShowAdminPanel}
              label="Show Admin Panel"
            />
            <Checkbox
              value={enableAdmin}
              setValue={setEnableAdmin}
              label="Enable Admin"
            />
            <Checkbox
              value={showDesignHelper}
              setValue={setShowDesignHelper}
              label="Show Design Helper"
            />
          </div>
          <div>
            <Input
              label="Admin secret"
              placeholder="Admin secret"
              value={adminSecret}
              onChange={setAdminSecret}
              type="password"
            />
          </div>
        </div>
      )}

      {props.children}

      {showDesignHelper && <DesignHelper />}
    </GameContext.Provider>
  );
};

// export default GameContextProvider;
