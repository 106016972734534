import {
  Centered,
  DesignedLink,
  FullButton,
} from "components/DesignComponents";
import { HandleHTTPErrors2 } from "components/ErrorMessages";
import useReferrerApi from "../ReferrerApi";
import { useBaseURL } from "utils/NetworkUtils";
import { CopyToClipboardButton } from "components/IconButtons";
import { AuthRequired } from "auth/Login";
import { useGameContext } from "debate/GameContext";
import { ToggleButton } from "components/input/ToggleButton";
import { useLocationMatch } from "utils/useLocationMatch";

function ShowReferrerStats({ data }) {
  const nestedStats = {
    companyStats: {
      title: "Referred Companies",
      stats: [
        {
          title: "Jobs posted",
          number: data?.job_posted_by_referred_company,
          color: "text-yellow-500",
        },
        {
          title: "Successful hires",
          number: data?.success_hiring_by_referred_company,
          color: "text-yellow-500",
        },
      ],
    },
    candidateStats: {
      title: "Referred Candidates",
      stats: [
        {
          title: "Interviewed candidates",
          number: data?.interviewed_referred_candidates,
          color: "text-orange-500",
        },
        {
          title: "Successful hires",
          number: data?.hired_referred_candidates,
          color: "text-orange-500",
        },
      ],
    },
    referrerStats: {
      title: "Referred Referrers",
      stats: [
        {
          title: "Referred referrers count",
          number: data?.referred_referrer_count,
          color: "text-green-500",
        },
      ],
    },
  };

  return (
    <div className="p-4">
      {Object.entries(nestedStats).map(([key, group]) => (
        <div key={key} className="mb-6 border border-dotted p-2">
          <h3 className="s-text-xl font-bold mb-4">{group.title}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {group.stats.map((stat, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center bg-dark shadow p-4 border rounded"
              >
                <span className="s-text-base font-semibold">{stat.title}</span>
                <span className={`s-text-4xl font-bold ${stat.color}`}>
                  {stat.number ?? "N/A"}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function ReferrerStatsAuthenticated() {
  const { useApiGetCode, apiEnableReferrer, apiToogleNewBountyEmails } =
    useReferrerApi();
  const requestsState = useApiGetCode();
  const baseURL = useBaseURL();
  const referral_code = requestsState.data?.referral_code;
  const is_referrer = requestsState.data?.is_referrer;
  const new_bounty_emails = requestsState.data?.new_bounty_emails;
  const link = `${baseURL}/?rc=${referral_code}`;
  const { isAdmin } = useGameContext();

  const enableReferrer = async () => {
    await apiEnableReferrer();
    requestsState.refetch();
  };

  return (
    <div className="pb-4">
      <HandleHTTPErrors2 {...requestsState}>
        {is_referrer ? (
          <>
            Your referral link.
            <div className="flex flex-row-2">
              <DesignedLink to={link}>{link}</DesignedLink>
              <CopyToClipboardButton text={link} />
            </div>
            <ToggleButton
              isEnabled={new_bounty_emails}
              onToggle={() => apiToogleNewBountyEmails(requestsState.refetch)}
              label="Receive emails when new bounties are added"
            />
            <ShowReferrerStats data={requestsState.data} />
          </>
        ) : (
          <>
            To enable your referral code click this button.
            <FullButton onClick={enableReferrer}>Enable Referrer</FullButton>
          </>
        )}
      </HandleHTTPErrors2>
      {isAdmin && (
        <FullButton onClick={enableReferrer}>Debug Toogle Referrer</FullButton>
      )}
    </div>
  );
}

export function ReferrerExplanation() {
  const isFindBountyPage = useLocationMatch("find-bounty");
  return (
    <>
      You will earn referral bounty for:
      <ul className="list-disc ml-4">
        <li>
          referred{" "}
          {isFindBountyPage ? (
            "candidates"
          ) : (
            <DesignedLink to="/apps/ai-recruiter/referrer/find-bounty">
              candidates
            </DesignedLink>
          )}{" "}
          that come through{" "}
          {isFindBountyPage ? (
            <DesignedLink to="/apps/ai-recruiter/referrer/my-stats">
              your link
            </DesignedLink>
          ) : (
            "your link."
          )}
        </li>
        <li>
          referred employers that come through your link. (Employer defined
          bounty)
        </li>
      </ul>
      Additionally, you will win 10% of the earnings of all the referrers that
      come through your link for 1 year.
    </>
  );
}

export default function ReferrerStats() {
  return (
    <Centered>
      <AuthRequired>
        <ReferrerStatsAuthenticated />
      </AuthRequired>
      <ReferrerExplanation />
    </Centered>
  );
}
// <JobListing
//   jobs={data}
//   to={(job) => `../../candidate/view-job/${job.uuid}`}
//   isReferrerView
// />
//
