import React from "react";
import { Outlet, Routes, Route, Navigate } from "react-router-dom";
import { ViewAJob } from "./ViewJob";
import { Centered } from "../../components/DesignComponents";

import {
  MagnifyingGlassIcon as SearchIcon,
  DocumentMagnifyingGlassIcon as MyApplicationsIcon,
  UserIcon as CandidateIcon,
  BuildingOfficeIcon as CompanyIcon,
  PaperAirplaneIcon as PostAJobIcon,
  BriefcaseIcon as MyJobsIcon,
  UserGroupIcon as MyCandidatesIcon,
} from "@heroicons/react/24/outline";

import { PostAJob } from "./company/CompanyPostAJob";
import Tab from "../../components/Tab";
import CompanyMyJobs from "./company/CompanyMyJobs";
import { CompanyViewCandidate } from "./company/CompanyViewCandidate";
import CompanyMyCandidates from "./company/CompanyMyCandidates";
import CandidateMyApplications from "./candidate/CandidateMyApplications";
import { DoJobInterview } from "./candidate/CandidateDoInterview";
import CandidateFindJob from "./candidate/CandidateFindJob";
import { Discord, DiscordWithText } from "pages/Contact";
// import { PostAJobVSR } from "./company/CompanyPostAJobVSR";
import ReferrerFindBounty from "./referrer/ReferrerFindBounty";
import ReferrerStats from "./referrer/ReferrerStats";
import { DoJobInterviewPhone } from "./candidate/CandidateDoInterviewPhone";

const tabs = [
  {
    icon: <CompanyIcon />,
    name: <h2>Company</h2>,
    to: "/apps/ai-recruiter/company/",
  },
  {
    icon: <CandidateIcon />,
    name: <h2>Candidate</h2>,
    to: "/apps/ai-recruiter/candidate/",
  },
  {
    icon: <CandidateIcon />,
    name: <h2>Referrer</h2>,
    to: "/apps/ai-recruiter/referrer/",
  },
];

const tabs_company = [
  {
    icon: <PostAJobIcon />,
    name: <h3>Post a job</h3>,
    to: "/apps/ai-recruiter/company/post-job",
  },
  // {
  //   icon: <PostAJobIcon />,
  //   name: <h3>Virtual Speed Recruiting</h3>,
  //   to: "/apps/ai-recruiter/company/virtual-speed-recruiting",
  // },
  {
    icon: <MyJobsIcon />,
    name: <h3>My jobs</h3>,
    to: "/apps/ai-recruiter/company/my-jobs",
    auth_required: true,
  },
  {
    icon: <MyCandidatesIcon />,
    name: <h3>My candidates</h3>,
    to: "/apps/ai-recruiter/company/my-candidates",
    auth_required: true,
  },
];

const tabs_candidate = [
  {
    icon: <SearchIcon />,
    name: <h3>Find a job</h3>,
    to: "/apps/ai-recruiter/candidate/find-job",
  },
  {
    icon: <MyApplicationsIcon />,
    name: "My applications",
    to: "/apps/ai-recruiter/candidate/my-applications",
    auth_required: true,
  },
];

const tabs_referrer = [
  {
    icon: <SearchIcon />,
    name: <h3>Find bounty</h3>,
    to: "/apps/ai-recruiter/referrer/find-bounty",
  },
  {
    icon: <MyApplicationsIcon />,
    name: "My stats",
    to: "/apps/ai-recruiter/referrer/my-stats",
  },
];

function AIRecruiterCompany() {
  return (
    <Centered>
      <nav>
        <Tab className="s-text-base" tabs={tabs_company} />
      </nav>
      <div className="py-5"> </div>
      <Outlet />
    </Centered>
  );
}

function AIRecruiterCandidate() {
  return (
    <Centered>
      <nav>
        <Tab className="s-text-base" tabs={tabs_candidate} />
      </nav>
      <div className="py-5"> </div>
      <Outlet />
    </Centered>
  );
}

function AIRecruiterReferrer() {
  return (
    <Centered>
      <nav>
        <Tab className="s-text-base" tabs={tabs_referrer} />
      </nav>
      <div className="py-5"> </div>
      <Outlet />
    </Centered>
  );
}

function AIRecruiterMain() {
  return (
    <Centered>
      <div className="text-orange-600 font-bold s-text-xs text-center pb-3">
        <DiscordWithText text="Give us feedbacks on Discord!" />
      </div>
      <nav>
        <Tab className="s-text-xl" tabs={tabs} />
      </nav>
      <div className="py-2"> </div>
      <Outlet />
    </Centered>
  );
}

// <Route path="virtual-speed-recruiting" element={<PostAJobVSR />} />

export function AIRecruiterRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AIRecruiterMain />}>
        <Route path="/company/" element={<AIRecruiterCompany />}>
          <Route path="post-job" element={<PostAJob />} />
          <Route path="my-jobs" element={<CompanyMyJobs />} />
          <Route path="my-candidates" element={<CompanyMyCandidates />} />
          <Route
            path="my-jobs/view-job/:job_uuid"
            element={<ViewAJob isCompanyView />}
          />
          <Route
            path="view-candidate/:candidate_uuid"
            element={<CompanyViewCandidate />}
          />
          <Route path="" element={<Navigate to="./post-job" replace />} />
          <Route path="*" element={<Navigate to="./post-job" replace />} />
        </Route>
        <Route path="candidate/" element={<AIRecruiterCandidate />}>
          <Route
            path="view-job/:job_uuid"
            element={<ViewAJob isCandidateView />}
          />
          <Route
            path="do-job-interview/:job_uuid"
            element={<DoJobInterview />}
          />
          <Route
            path="do-job-interview-phone/:job_uuid"
            element={<DoJobInterviewPhone />}
          />
          <Route path="find-job" element={<CandidateFindJob />} />
          <Route path="my-applications" element={<CandidateMyApplications />} />
          <Route path="" element={<Navigate to="./find-job" replace />} />
        </Route>

        <Route path="referrer/" element={<AIRecruiterReferrer />}>
          <Route path="find-bounty" element={<ReferrerFindBounty />} />
          <Route
            path="view-job/:job_uuid"
            element={<ViewAJob isReferrerView />}
          />
          <Route path="my-stats" element={<ReferrerStats />} />
          <Route path="" element={<Navigate to="./find-bounty" replace />} />
        </Route>

        <Route path="" element={<Navigate to="./company" replace />} />
        <Route path="*" element={<Navigate to="./company" replace />} />
      </Route>
    </Routes>
  );
}
