// const TWITTER_PIXEL_ID = "ohaa5"; // Replace with your actual Twitter pixel ID
const TWITTER_EVENT_ID = "tw-ohaa5-ohaa9"; // Replace with your actual Twitter event ID

// Hook to initialize Twitter tracking
const useTwitterPixel = () => {
  function config() {
    const addTwitterScript = () => {
      const head = document.querySelector("head");
      const twitterScript = document.createElement("script");
      twitterScript.type = "text/javascript";
      twitterScript.async = true;
      twitterScript.src = "https://static.ads-twitter.com/uwt.js";
      head.appendChild(twitterScript);
    };

    const initializeTwq = (windowObj, documentObj, elementType) => {
      if (!windowObj.twq) {
        const twitterEventTracker = (windowObj.twq = function () {
          twitterEventTracker.exe
            ? twitterEventTracker.exe.apply(twitterEventTracker, arguments)
            : twitterEventTracker.queue.push(arguments);
        });
        twitterEventTracker.version = "1.1";
        twitterEventTracker.queue = [];
        const twitterScriptElement = documentObj.createElement(elementType);
        twitterScriptElement.async = true;
        twitterScriptElement.src = "https://static.ads-twitter.com/uwt.js";
        const firstScriptElement =
          documentObj.getElementsByTagName(elementType)[0];
        firstScriptElement.parentNode.insertBefore(
          twitterScriptElement,
          firstScriptElement
        );
      }
    };

    if (!window.twq && !window.twitter_event) {
      addTwitterScript();
      initializeTwq(window, document, "script");
      window.twitter_event = true;
      // window.twq && window.twq("config", TWITTER_PIXEL_ID);
    }
  }

  // Function to send Twitter conversion event
  const sendTwitterConversion = () => {
    if (!window.twitter_event) {
      config();
      window.twq("event", TWITTER_EVENT_ID, {});
      window.twitter_event = true;
    }
  };

  return { config, sendTwitterConversion };
};

export default useTwitterPixel;
