import useAuthenticatedApi from "utils/AuthenticatedApi.js";

export default function useViralTiktokApi() {
  const { useAxiosQuery, axiosPostSilence } = useAuthenticatedApi();
  // ----------------------------------------------------------------
  // Video Reuse
  // ----------------------------------------------------------------
  function useListTikTok() {
    return useAxiosQuery("list-tiktok", "/api/apps/viral-tiktok/list", {});
  }

  function apiSearchViralTikTok(one_line) {
    return axiosPostSilence("/api/apps/viral-tiktok/search", {
      one_line,
    });
  }

  function apiCreateViralTikTok(one_line) {
    return axiosPostSilence("/api/apps/viral-tiktok/create", {
      one_line,
    });
  }

  // ----------------------------------------------------------------
  // Quiz Video
  // ----------------------------------------------------------------

  function useListQuizVideo() {
    return useAxiosQuery(
      "list-quiz-video",
      "/api/apps/viral-tiktok/quiz-video/list",
      {}
    );
  }

  function apiCreateQuizVideo(
    nbr_quiz,
    export_image_only,
    full_video_export,
    quiz_theme,
    background_link
  ) {
    return axiosPostSilence("/api/apps/viral-tiktok/quiz-video/create", {
      nbr_quiz,
      export_image_only,
      quiz_theme,
      full_video_export,
      background_link,
    });
  }

  function apiCreateBulkQuizVideo(nbr_quiz, quiz_theme, background_link) {
    return axiosPostSilence("/api/apps/viral-tiktok/quiz-video/create-bulk", {
      nbr_quiz,
      quiz_theme,
      background_link,
    });
  }

  function apiUploadTiktok(filename) {
    return axiosPostSilence("/api/apps/viral-tiktok/upload-tiktok", {
      filename,
    });
  }

  return {
    apiSearchViralTikTok,
    apiCreateViralTikTok,
    apiCreateBulkQuizVideo,
    useListTikTok,
    apiCreateQuizVideo,
    useListQuizVideo,
    apiUploadTiktok,
  };
}
