import useAuthenticatedApi from "utils/AuthenticatedApi.js";

export default function useMotivatorApi() {
  const { useAxiosQuery, axiosPostSilence } = useAuthenticatedApi();
  function apiCreateMotivator(playerName, phone_number, email, captcha) {
    return axiosPostSilence("/api/apps/motivator/create", {
      email,
      phone_number,
      playerName,
      "g-recaptcha-response": captcha,
    });
  }

  // function apiEnableReferrer() {
  //   return axiosPostSilence("/api/apps/ai-recruiter/referrer/enable", {});
  // }

  // function apiToogleNewBountyEmails(refetch = () => {}) {
  //   const result = axiosPostSilence(
  //     "/api/apps/ai-recruiter/referrer/toogle-new-bounty-emails",
  //     {}
  //   );
  //   refetch();
  //   return result;
  // }
  return { apiCreateMotivator };
}
