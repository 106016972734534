import { FullButton, Input, Title3 } from "components/DesignComponents";
import { useCallback, useState } from "react";
import useViralTiktokApi from "./ViralTikTokApi";
import notifications from "utils/Notifications";
import ErrorMessages from "components/ErrorMessages";
import { ListTikTok } from "./QuizVideo";

export function UploadTikTok() {
  const { apiUploadTiktok } = useViralTiktokApi();
  const [requestResponse, setRequestResponse] = useState(null);
  const [tiktokFilename, setTiktokFilename] = useState("");

  const postTikTok = useCallback(
    async (filename) => {
      console.log("processTikTok", filename);
      const { status, ...remaining } = await apiUploadTiktok(filename);
      setRequestResponse({ status, ...remaining });
      if (status === "ok") {
        notifications.success("TikTok was created");
      }
    },
    [apiUploadTiktok, setRequestResponse]
  );

  return (
    <div>
      <Title3>Upload TikTok</Title3>
      <ErrorMessages
        requestResponse={requestResponse}
        successMessage="TikTok was uploaded"
      ></ErrorMessages>
      <form onSubmit={postTikTok}>
        <div className="flex justify-center">
          <Input
            type="text"
            value={tiktokFilename}
            onChange={setTiktokFilename}
            label="TikTok"
          />
          <FullButton className="text-center" type="submit" propagate>
            Post TikTok
          </FullButton>
        </div>
      </form>
      <ListTikTok />
    </div>
  );
}

// function ListTikTok() {
//   const { useListTikTok, apiCreateViralTikTok } = useViralTiktokApi();
//   const requestsState = useListTikTok();
//   const tiktoks = requestsState.data?.output;
//   const [requestResponse, setRequestResponse] = useState(null);
//   const [currentTikTok, setCurrentTikTok] = useState(null);
//
//   const createTikTok = useCallback(
//     async (tiktok) => {
//       console.log("processTikTok", tiktok);
//       const { status, ...remaining } = await apiCreateViralTikTok(tiktok);
//       setRequestResponse({ status, ...remaining });
//       setCurrentTikTok(tiktok);
//       if (status === "ok") {
//         notifications.success("TikTok was created");
//       }
//     },
//     [apiCreateViralTikTok, setRequestResponse, setCurrentTikTok]
//   );
//
//   return (
//     <HandleHTTPErrors2 {...requestsState}>
//       <div className="grid grid-cols-3 gap-4">
//         {tiktoks &&
//           tiktoks.map((tiktok) => (
//             <TikTokCard
//               key={tiktok}
//               tiktok={tiktok}
//               createTikTok={createTikTok}
//             />
//           ))}
//       </div>
//       <ErrorMessages
//         requestResponse={requestResponse}
//         successMessage="TikTok was created"
//       ></ErrorMessages>
//       {requestResponse && (
//         <div>
//           <Title3>{currentTikTok}</Title3>
//           <div>AI Output:{requestResponse.ai_output}</div>
//           <Title3>Before</Title3>
//           <pre className="border p-2">{requestResponse.before}</pre>
//           <Title3>After</Title3>
//           <pre className="border p-2">{requestResponse.after}</pre>
//         </div>
//       )}
//     </HandleHTTPErrors2>
//   );
// }
