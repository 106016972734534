const StepCard = ({ step, title, description }) => {
  return (
    <div className="text-dark flex-1 bg-white w-full h-full rounded-lg">
      <div className="p-4 lg:p-8 w-full h-full">
        <div className="text-sm lg:text-xl">{step}</div>
        <div className="text-3xl my-6 lg:text-4xl xl:text-5xl lg:mt-8" id="gt">
          {title}
        </div>
        <div className="text-sm lg:text-xl mt-1">{description}</div>
      </div>
    </div>
  );
};

const StepCards = ({ stepsData, title, description }) => {
  // lenght of stepsData
  const size = stepsData.length;
  return (
    <div className="text-light p-6 md:p-12">
      <h1 className="text-2xl md:text-4xl font-bold" id="gt">
        {title}
      </h1>
      <h2 className="text-light-300 text-xl md:text-2xl mt-2">{description}</h2>
      <div
        className={`mt-6 md:mt-12 grid md:grid-cols-${size} justify-evently items-center gap-8`}
      >
        {stepsData.map((step, index) => (
          <StepCard
            key={index}
            step={step.step}
            title={step.title}
            description={step.description}
          />
        ))}
      </div>
    </div>
  );
};

export default StepCards;
