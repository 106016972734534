import { useSignal } from "@preact/signals-react";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

export function useLocalStorageString(name) {
  const [value, setValue] = useState(() => localStorage.getItem(name) || "");

  useEffect(() => {
    localStorage.setItem(name, value);
  }, [value, name]);

  return [value, setValue];
}

export function useLocalStorageStringV2(key, initialValue = "") {
  // Create a signal with the initial value from localStorage.
  const value = useSignal(localStorage.getItem(key) || initialValue);

  // A save function that can be called to explicitly save the value
  const save = () => {
    localStorage.setItem(key, value.value); // Save the current value to localStorage
  };

  // A setValue function that updates the value signal
  const setValue = (newValue) => {
    value.value = newValue;
  };

  // Expose the value, the setValue function, and the save function
  return [value, setValue, save];
}

export function useLocalStorageObject(key, initialValue = null) {
  // Create a signal with the initial value from localStorage.
  const value = useSignal(
    JSON.parse(localStorage.getItem(key) || initialValue)
  );

  // A setValue function that updates the value signal
  const setValue = (newValue) => {
    value.value = newValue;
    localStorage.setItem(key, JSON.stringify(newValue));
    // Save the current value to localStorage
  };

  // Expose the value, the setValue function, and the save function
  return [value, setValue];
}

export function useLocalStorageBool(name) {
  const [value, setValue] = useState(
    () => localStorage.getItem(name) === "true"
  );

  useEffect(() => {
    localStorage.setItem(name, value);
  }, [value, name]);

  return [value, setValue];
}

export function useSearchParamsInteger(key, defaultValue, limits) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    const queryValue = searchParams.get(key);
    const parsedValue = parseInt(queryValue, 10);
    setValue(isNaN(parsedValue) ? defaultValue : limits(parsedValue));
  }, [searchParams, key, defaultValue, limits]);

  const updateQueryParam = useCallback(
    (newValue) => {
      setSearchParams((prev) => {
        prev.set(key, newValue);
        return prev;
      });

      if (value !== newValue) {
        setValue(newValue);
      }
    },
    [key, value, setSearchParams]
  );

  return [value, updateQueryParam];
}

export function useSearchParamsBoolRO(key) {
  const [searchParams] = useSearchParams();
  return !(searchParams.get(key) === "false");
}

// export function useSearchParamsBool(key, defaultValue) {
//   const [searchParams, setSearchParams] = useSearchParams({
//     step: defaultValue,
//   });
//   const value = searchParams.get(key) === "true";
//   const setValue = useCallback(
//     (step) => {
//       setSearchParams(
//         (prev) => {
//           prev.set(key, step);
//           return prev;
//         }
//         // { replace: true }
//       );
//     },
//     [value]
//   );
//
//   useEffect(() => {
//     setValue(value);
//   }, [value]);
//
//   return [value, setValue];
// }

export function useSearchParamsBool(key, defaultValue) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(
    searchParams.get(key) === "true"
      ? true
      : searchParams.get(key) === "false"
      ? false
      : defaultValue
  );

  useEffect(() => {
    const paramValue = searchParams.get(key);
    setValue(
      paramValue === "true"
        ? true
        : paramValue === "false"
        ? false
        : defaultValue
    );
  }, [searchParams, key, defaultValue]);

  const updateQueryParam = useCallback(
    (newValue) => {
      setSearchParams((prev) => {
        prev.set(key, newValue);
        return prev;
      });
    },
    [key, setSearchParams]
  );

  return [value, updateQueryParam];
}

export function useSearchParamsString(key) {
  const [searchParams] = useSearchParams();
  const value = searchParams.get(key);
  return value;
}

export const useFromParamThenStorage = (key) => {
  // We get the value from http GET param first then put it in local storage
  // Next time we load from local storage directly
  const location = useLocation();
  // const navigate = useNavigate();
  const [referralCode, setReferralCode, saveReferralCode] =
    useLocalStorageStringV2(key);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(key)) {
      setReferralCode(searchParams.get(key));
      saveReferralCode();
      // searchParams.delete(key);
      // navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [location, setReferralCode, saveReferralCode]);

  return referralCode;
};
