import { useGameContext } from "debate/GameContext";
import useApi from "utils/Api";
import { useDynamicQuery } from "utils/NetworkUtils";
import { HandleHTTPErrors2 } from "./ErrorMessages";
import { useSearchParamsString } from "utils/StorageUtils";
import { FullButtonLink } from "./DesignComponents";
import { useEffect } from "react";

const LoadingSpinner = () => (
  <svg
    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 0116 0H4z"
    ></path>
  </svg>
);

export default function StripeSuccess() {
  // get param stripe_checkout_session_id?= from url
  const { apiStripeIsPaymentSuccess } = useApi();
  // const { setVsrToken, setVsr } = useGameContext();
  const stripe_checkout_session_id = useSearchParamsString(
    "stripe_checkout_session_id"
  );

  // const paymentStatus = useAsyncData(() =>
  //   apiStripeIsPaymentSuccess(stripe_checkout_session_id)
  // );

  const requestsState = useDynamicQuery(
    "stripe-success",
    apiStripeIsPaymentSuccess,
    {
      stripe_checkout_session_id,
    }
  );

  // useEffect(() => {
  //   if (paymentStatus.status === "ok") {
  //     setVsrToken(paymentStatus.vsr_token);
  //     setVsr("Canada Virtual Speed Recruiting janvier 2024");
  //     console.log("StripeSuccess: setVsrToken", paymentStatus.vsr_token);
  //   }
  // }, [paymentStatus]);
  const is_paid = requestsState.data?.is_paid;
  const payment_message = requestsState.data?.payment_message;
  const textColor = is_paid ? "text-green-500" : "text-red-500";
  const uuid = requestsState.data?.uuid;

  // to={`/apps/ai-recruiter/company/my-jobs/view-job/${uuid}`}
  return (
    <HandleHTTPErrors2 {...requestsState}>
      <div className="grid justify-items-center">
        <div className={`${textColor} text-bold s-text-3xl`}>
          {payment_message}
        </div>
        <FullButtonLink
          to={`/apps/ai-recruiter/company/virtual-speed-recruiting?step=4`}
        >
          Continue
        </FullButtonLink>
      </div>
    </HandleHTTPErrors2>
  );

  // return (
  //   <div>
  //     {paymentStatus && paymentStatus.status === "ok" ? (
  //       <>
  //         <h1>Success</h1>
  //         <div>ID: {stripe_checkout_session_id}</div>
  //         <div>Status: {paymentStatus.status}</div>
  //         <div>Status: {paymentStatus.message}</div>
  //
  //         <FullButtonLink to="/apps/ai-recruiter/company/post-job">
  //           Continue
  //         </FullButtonLink>
  //       </>
  //     ) : (
  //       <>
  //         <div>
  //           <LoadingSpinner />
  //           Loading
  //         </div>
  //       </>
  //     )}
  //   </div>
  // );
}
