import { useContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import useApi from "../utils/Api";
import ErrorMessages from "../components/ErrorMessages";
import Contact from "./Contact";
import { GameContext } from "../debate/GameContext";

function UnknowMagicLink() {
  return <div>Unknow magic link</div>;
}

function ConfirmEmailWaitlist() {
  const { email, auth_token } = useParams();
  const { apiConfirmEmail } = useApi();
  const [requestResponse, setRequestResponse] = useState(null);

  useEffect(() => {
    async function confirmEmail() {
      const { status, message } = await apiConfirmEmail(email, auth_token);
      setRequestResponse({ status, message });
    }
    confirmEmail();
  }, []);

  const SuccessMessage = () => (
    <div>
      Your email has been confirmed. We will send you an email when the
      application is ready. In the meantime, join our discord!
      <Contact className="text-light-100" />
    </div>
  );

  return (
    <div>
      <ErrorMessages
        requestResponse={requestResponse}
        successMessage={<SuccessMessage />}
      ></ErrorMessages>
    </div>
  );
}

function AuthUser() {
  const { email, tmp_token, redirect_url } = useParams();
  const { apiGetAuthToken } = useApi();
  const [requestResponse, setRequestResponse] = useState(null);
  const { setAuthToken, setEmail, saveEmail } = useContext(GameContext);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { status, message, ...response } = await apiGetAuthToken(
        email,
        tmp_token
      );
      setRequestResponse({ status, message });
      if (status === "ok") {
        setAuthToken(response.auth_token);
        // alert(email);
        setEmail(email);
        saveEmail(email);
        // localStorage.setItem("token", message);
        console.log("redirect_url", redirect_url);
        if (redirect_url) {
          navigate(atob(redirect_url));
        } else {
          navigate("/apps/");
        }
      }
    })();
  }, []);

  const SuccessMessage = () => (
    <div>
      Authentication successful.
      <Contact />
    </div>
  );

  return (
    <div>
      <ErrorMessages
        requestResponse={requestResponse}
        successMessage={<SuccessMessage />}
      ></ErrorMessages>
    </div>
  );
}

function GenericDiscussion() {
  const { game_uuid, email } = useParams();
  const navigate = useNavigate();

  const { setGameUUID, setEmail } = useContext(GameContext);

  useEffect(() => {
    setGameUUID(game_uuid);
    setEmail(email);
    navigate("/apps/gd/dodiscussion");
  }, [game_uuid, email]);

  return (
    <div>
      <div>{game_uuid}</div>
      <div>{email}</div>
    </div>
  );
}

export default function MagicLinkRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route path="/" element={<UnknowMagicLink />} />
        <Route
          path="/confirm_email/:email/:auth_token"
          element={<ConfirmEmailWaitlist />}
        />
        <Route
          path="/auth_user/:email/:tmp_token/:redirect_url?"
          element={<AuthUser />}
        />
        <Route path="/gd/:game_uuid/:email" element={<GenericDiscussion />} />
        <Route path="*" element={<UnknowMagicLink />} />
      </Route>
    </Routes>
  );
}
