import { useEffect, useState } from "react";
import useApi from "../../utils/Api";
import { Centered, Title, FullButton } from "../../components/DesignComponents";
import { DropdownFromHTTP } from "../../components/Dropdown";
import { Input } from "../../components/DesignComponents";
import { toast } from "react-toastify";

const ApplicationDesign = "border-2 p-2 m-2";

function ListApplication() {
  const { apiListApplication } = useApi();
  const [applications, setApplications] = useState([]);

  const handleListApplication = async () => {
    try {
      const response = await apiListApplication();
      setApplications(response);
    } catch (error) {}
  };

  useEffect(() => {
    async function fetchData() {
      await handleListApplication();
    }
    fetchData();
  }, []);
  if (!applications) {
    return <div>Loading...</div>;
  }
  return (
    <div className={`${ApplicationDesign}`}>
      <Title>
        <h4>Application List : {applications.length} </h4>
      </Title>
      <ul>
        {applications.map((app, index) => (
          <li key={index}>
            {index + 1}: {app}
          </li>
        ))}
      </ul>

      <FullButton onClick={handleListApplication}>List Application</FullButton>
    </div>
  );
}

function CreateApplication() {
  const {
    apiCreateApplication,
    apiUpdateApplication,
    apiDeleteApplication,
    apiGetApplication,
  } = useApi();

  const [discussionPrompt, setDiscussionPrompt] = useState("");
  const [discussionStartPrompt, setDiscussionStartPrompt] = useState("");
  const [discussionStarts, setDiscussionStarts] = useState([]);
  const [status, setStatus] = useState("");
  const { apiListApplication } = useApi();
  const [applicationName, setApplicationName] = useState("");

  const handleCreateApplication = async () => {
    try {
      const response = await apiCreateApplication(
        applicationName,
        discussionPrompt,
        discussionStartPrompt
      );
      setStatus(response.status);
      if (response.status === "ok") {
        toast.success("Application created");
      }
    } catch (error) {
      setStatus("error");
    }
  };

  const handleReset = async () => {
    setDiscussionStartPrompt("");
    setDiscussionPrompt("");
    setDiscussionStarts([]);
    setStatus("");
  };

  const handleGetApplication = async () => {
    try {
      await handleReset();
      const response = await apiGetApplication(applicationName);
      setStatus(response.status);
      if (response.status === "ok") {
        setDiscussionStartPrompt(response.discussion_start_prompt);
        setDiscussionPrompt(response.discussion_prompt);
        setDiscussionStarts(response.discussion_starts);
        toast.success("Application loaded");
      }
    } catch (error) {
      setStatus("error");
    }
  };

  const handleUpdateApplication = async () => {
    try {
      // await handleReset();
      const response = await apiUpdateApplication(
        applicationName,
        discussionPrompt,
        discussionStartPrompt
      );
      if (
        response.status === "ok" ||
        response.status === "started in background"
      ) {
        toast.success("Application updated");
      }
      setStatus(response.status);
    } catch (error) {
      setStatus("error");
    }
  };

  const handleDeleteApplication = async () => {
    try {
      await handleReset();
      const response = await apiDeleteApplication(applicationName);
      setStatus(response.status);
      if (response.status === "ok") {
        toast.success("Application deleted");
      }
    } catch (error) {
      setStatus("error");
    }
  };

  return (
    <div className={`${ApplicationDesign}`}>
      <Title>
        <h4>Create New Application</h4>
      </Title>
      <label>
        Application Name:
        <DropdownFromHTTP
          label="Language"
          api={apiListApplication}
          onSelect={setApplicationName}
          className="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        />
        <Input
          label="Application Name"
          placeholder="application name"
          value={applicationName}
          onChange={setApplicationName}
        />
        <div>Application name : "{applicationName}"</div>
      </label>

      <br />
      <label>
        Discussion prompt:
        <textarea
          className="border border-gray-300 h-80 p-3 p-5 mx-5 my-1 max-w-[90%] w-full text-sm text-black resize-y"
          type="text"
          value={discussionPrompt}
          onChange={(e) => setDiscussionPrompt(e.target.value)}
        />
      </label>

      <br />
      <label>
        Discussion start prompt:
        <pre
          className="border border-gray-300 p-3 p-5 mx-5 my-1 text-sm resize-y"
          type="text"
        >
          {discussionStartPrompt}
        </pre>
      </label>

      <br />

      <FullButton onClick={handleCreateApplication}>
        Create Application
      </FullButton>

      <FullButton onClick={handleGetApplication}>Get Application</FullButton>

      <FullButton onClick={handleUpdateApplication}>
        Update Application
      </FullButton>

      <FullButton onClick={handleDeleteApplication}>
        Delete Application
      </FullButton>

      <p className="text-5xl p-5 text-center border">Status: {status}</p>

      <ul>
        {discussionStarts.map((app, index) => (
          <li key={index}>
            {index + 1}: {app.language} - {app.message}
          </li>
        ))}
      </ul>
    </div>
  );
}

function AdminApplication() {
  // return <> </>;
  return (
    <section className="border-t border-gray-800 py-10">
      <Centered>
        <Title>
          <h3>Admin Applications</h3>
        </Title>
        <ListApplication />
        <CreateApplication />
      </Centered>
    </section>
  );
}

export default AdminApplication;
