import React from "react";
import { Outlet, Routes, Route, Navigate } from "react-router-dom";
import { Centered } from "components/DesignComponents";

import {
  MagnifyingGlassIcon as SearchIcon,
  DocumentMagnifyingGlassIcon as MyApplicationsIcon,
  UserIcon as CandidateIcon,
  BuildingOfficeIcon as CompanyIcon,
  PaperAirplaneIcon as PostAJobIcon,
  BriefcaseIcon as MyJobsIcon,
  UserGroupIcon as MyCandidatesIcon,
} from "@heroicons/react/24/outline";

import Tab from "components/Tab";
import { ReuseVideo } from "./ReuseVideo";
import { QuizVideo } from "./QuizVideo";
import { ViralTikTokContextProvider } from "./ViralTiktokContext";
import { UploadTikTok } from "./UploadTikTok";

const BaseURI = "/apps/viral-tiktok";

const tabs = [
  {
    icon: <CompanyIcon />,
    name: <h2>Video creator</h2>,
    to: BaseURI + "/video-creator",
  },
];

const video_creator_tab = [
  {
    icon: <PostAJobIcon />,
    name: <h3>Reuse Video</h3>,
    to: BaseURI + "/video-creator/reuse-video",
    // auth_required: true,
  },
  {
    icon: <MyCandidatesIcon />,
    name: <h3>Quiz video</h3>,
    to: BaseURI + "/video-creator/quiz-video",
  },
  {
    icon: <MyCandidatesIcon />,
    name: <h3>Upload TikTok</h3>,
    to: BaseURI + "/video-creator/upload-tiktok",
  },
];

function VideoCreatorTab() {
  return (
    <Centered>
      <nav>
        <Tab className="s-text-base" tabs={video_creator_tab} />
      </nav>
      <div className="py-5"> </div>
      <Outlet />
    </Centered>
  );
}

function Main() {
  return (
    <ViralTikTokContextProvider>
      <div>
        <div className="text-orange-600 font-bold s-text-xs text-center pb-3">
          Give us feedbacks on Discord! <br />
        </div>
        <nav>
          <Tab className="s-text-xl" tabs={tabs} />
        </nav>
        <div className="py-2"> </div>
        <Outlet />
      </div>
    </ViralTikTokContextProvider>
  );
}

function NoRouteMatch() {
  return <div>404: No route match</div>;
}

export function ViralTikTokRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route path="/" element={<VideoCreatorTab />} />
        <Route path="/video-creator/" element={<VideoCreatorTab />}>
          <Route path="reuse-video" element={<ReuseVideo />} />
          <Route path="quiz-video" element={<QuizVideo />} />
          <Route path="upload-tiktok" element={<UploadTikTok />} />
        </Route>
        <Route path="" element={<Navigate to="/video-creator/" replace />} />
        <Route path="*" element={<NoRouteMatch />} />
      </Route>
    </Routes>
  );
}
