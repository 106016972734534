import React, { useEffect } from "react";
import { useSignal } from "@preact/signals-react";
import useApi from "utils/Api";

const ProgressBar = ({ taskId }) => {
  const displayedProgress = useSignal(null);
  const { apiGetTaskProgress } = useApi();
  const intervalId = useSignal(null);

  useEffect(() => {
    if (displayedProgress.value?.progress !== 100) {
      intervalId.value = setInterval(async () => {
        if (displayedProgress.value?.percent !== 100) {
          const { status, progress } = await apiGetTaskProgress(taskId);
          if (status === "ok") {
            displayedProgress.value = progress;
            if (progress?.percent === 100) {
              clearInterval(intervalId.value);
            }
          } else {
            clearInterval(intervalId.value);
          }
        }
      }, 10000);
    }
    return () => clearInterval(intervalId.value);
  }, []);
  // }, [setProgress, apiGetTaskProgress, taskId]);

  const percent = displayedProgress.value?.percent;
  console.log("progress", displayedProgress);
  return (
    <div>
      {displayedProgress && (
        <div>
          <div className="w-full h-16 bg-light-200 overflow-hidden border border-white">
            <div
              className="h-full bg-dark-300 text-center place-items-center justify-center flex"
              style={{ width: `${percent}%` }}
            >
              <span className="text-white text-3xl font-bold p-2 m-2">
                {percent}%
              </span>
            </div>
          </div>
          <div className="flex grid-3 justify-evenly">
            <div className="font-normal">
              Elapsed:{" "}
              <span className="font-bold">
                {displayedProgress.value?.elapsed_time}
              </span>
            </div>
            <div>{displayedProgress.value?.message}</div>
            <div className="font-normal">
              Remaining:{" "}
              <span className="font-bold">
                {displayedProgress.value?.remaining_time}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
