import { useCallback } from "react";
import { toast } from "react-toastify";

/**
 * Creates a callback that handles an API action and automatically
 * shows a toast notification based on the result, and triggers a refresh.
 *
 * @param {function} apiCall The API call function that takes a uuid and returns a promise.
 * @param {function} successCallback A state setter function to trigger a refresh.
 * @param {string} successMessage The message to show on success.
 * @param {string} errorMessage The message to show on error.
 * @returns {function} The callback function.
 */
function useApiCallback(
  apiCall,
  successCallback,
  successMessage,
  errorMessage
) {
  return useCallback(
    async (...args) => {
      console.log("useApiCallback", args);
      try {
        const { status } = await apiCall(...args);
        console.log("Status", status);
        if (status === "ok") {
          toast.success(successMessage);
          successCallback();
        }
      } catch (error) {
        console.error(error);
        toast.error(
          errorMessage || error.message || "An unexpected error occurred"
        );
      }
    },
    [apiCall, successCallback, successMessage, errorMessage]
  );
}

export default useApiCallback;
