import React from "react";
import { toast } from "react-toastify";
import useApi from "utils/Api";
import { Centered } from "components/DesignComponents";
import { useDynamicQuery } from "utils/NetworkUtils";
import { formatDayMonth } from "utils/DateUtils";
import {
  CandidateFitScore,
  CandidateStatusBadge,
  ExperienceLevelIndicator,
} from "components/RatingComponent";
import { Link } from "react-router-dom";
import XS from "components/XS";
import { HandleHTTPErrors2 } from "components/ErrorMessages";
import ConfirmedEmail from "features/ConfirmedEmail";

const CandidateCard = ({ candidate, setRefresh }) => {
  const { apiAIRecruiterDeleteCandidateAdmin } = useApi();
  const {
    uuid,
    created_at,
    contract_type,
    remote,
    candidate_title,
    ai_score,
    username,
    experience_level,
    candidate_status,
    discussion_duration,
  } = candidate;

  const createdAtFormatted = formatDayMonth(created_at);

  const doDelete = async () => {
    const { status } = await apiAIRecruiterDeleteCandidateAdmin(uuid);
    if (status === "ok") {
      toast.success(`Successfully deleted candidate ${candidate_title}`);
      setRefresh((prev) => !prev);
    } else {
      toast.error(`Failed to delete candidate ${candidate_title}`);
    }
  };

  // <DeleteButton onClick={doDelete} />
  return (
    <Link className="h-full" to={`../view-candidate/${uuid}`}>
      <div className="h-full bg-dark hover:bg-dark-300 border border-dark-300 shadow overflow-hidden text-light">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="s-text-lg leading-6 font-bold text-light-300">
            {username} - {candidate.job_title}
          </h3>
          <div>{candidate.major_experience}</div>
          <div className="grid grid-cols-2 justify-items-stretch mt-3 gap-3">
            <div>
              <XS k="Date">
                {`${createdAtFormatted} (${discussion_duration}min)`}
              </XS>
              <XS k="">
                {remote} {remote && contract_type && "."} {contract_type}
              </XS>
              <XS k="Salary">{candidate.desired_salary}</XS>
              {candidate.email && (
                <XS k="Email">
                  <ConfirmedEmail
                    email={candidate.email}
                    mail_confirmed={candidate.mail_confirmed}
                  />
                </XS>
              )}
              {candidate.phone_number && (
                <XS k="Phone">{candidate.phone_number}</XS>
              )}
              {candidate.referral_code && (
                <XS k="Referral code">{candidate.referral_code}</XS>
              )}
            </div>
            <div className="grid grid-cols-3 gap-4">
              <CandidateFitScore rating={ai_score} />
              <ExperienceLevelIndicator experience_level={experience_level} />
              <CandidateStatusBadge status={candidate_status} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default function MyCandidates() {
  const { apiAIRecruiterAllCandidates } = useApi();

  const requestsState = useDynamicQuery(
    "company-my-candidates",
    apiAIRecruiterAllCandidates
  );
  const candidates = requestsState.data?.candidates;

  return (
    <Centered>
      <HandleHTTPErrors2 {...requestsState}>
        <div className="grid grid-cols-1 gap-4 p-4">
          {candidates?.map((candidate) => (
            <CandidateCard
              key={candidate.uuid}
              candidate={candidate}
              setRefresh={requestsState.refetch}
            />
          ))}
        </div>
      </HandleHTTPErrors2>
    </Centered>
  );
}
