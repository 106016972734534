import { FullButton } from "components/DesignComponents";
import React, { useState } from "react";

function CollapsibleDiv({ isCollapsed, title, children }) {
  const [collapsed, setCollapsed] = useState(isCollapsed);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="relative max-w-2xl mx-auto border border-dark-200 shadow-sm overflow-hidden m-2 flex flex justify-center">
      <FullButton
        onClick={toggleCollapse}
        className={`${collapsed ? "block" : "hidden"}`}
      >
        {title}
      </FullButton>
      <div className={`${collapsed ? "hidden" : "block"} w-full`}>
        {children}
      </div>
    </div>
  );
}

export default CollapsibleDiv;
