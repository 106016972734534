export default function PrivacyCheckbox() {
  return (
    <label className="block mt-4">
      <input type="checkbox" name="accept_terms" required className="mr-2" />
      {" I accept the "}
      <a
        href="/assets/service_policy_en.html"
        target="_blank"
        className="text-indigo-500"
      >
        Privacy Policy and Terms of Use
      </a>
      .
    </label>
  );
}
