import { useState } from "react";
// import { useSignal } from "@preact/signals-react";

// InputSignal Component
export const InputSignal = ({
  label,
  placeholder,
  value,
  onBlur,
  ...props
}) => {
  const [tempValue, setTempValue] = useState(value);

  const handleChange = (event) => {
    setTempValue(event.target.value);
  };

  const handleBlur = (event) => {
    onBlur(event.target.value);
    setTempValue(value.value);
  };

  return (
    <div className="relative w-full max-w-full">
      <label className="absolute bg-light rounded px-1 text-md font-bold block text-gray-800 bottom-7 left-2">
        {label}
      </label>
      <div className="mt-1">
        <input
          type="text"
          placeholder={placeholder}
          className="border border-light focus:outline-blue-400 rounded w-full h-10 p-3 pt-5 text-sm text-black"
          value={tempValue}
          onChange={handleChange}
          onBlur={handleBlur}
          {...props}
        />
      </div>
    </div>
  );
};

// // ParentComponent
// export const ParentComponent = () => {
//   const referralBounty = useSignal(100);
//
//   const updateReferralBounty = (newValue) => {
//     let tmp = parseInt(newValue, 10);
//     if (isNaN(tmp) || tmp < 100) {
//       tmp = 100;
//     }
//     referralBounty.value = tmp;
//   };
//
//   return (
//     <div>
//       <InputSignal
//         label="Referral Bounty"
//         placeholder="Enter bounty value"
//         initialValue={referralBounty}
//         onCommit={updateReferralBounty}
//       />
//       <p>Current Bounty: {referralBounty.value}</p>
//     </div>
//   );
// };
