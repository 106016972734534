import AdminApplication from "./application/AdminApplication";
import AdminWaitlist from "./AdminWaitlist";
import AdminUsers from "./AdminUsers";
import AdminJobs from "./AdminJobs";
import AdminCandidates from "./AdminCandidates";
import { Outlet, Routes, Route, Navigate } from "react-router-dom";
import { Centered, FullButtonLink } from "../components/DesignComponents";
import { useContext } from "react";
import { GameContext } from "../debate/GameContext";
import AdminPitches from "./AdminPitch";
import AdminPayments from "./AdminPayments";

function TabButtons() {
  const { showAdminPanel, setShowAdminPanel, isAdmin } =
    useContext(GameContext);
  return (
    <>
      <Centered>
        {isAdmin && (
          <nav>
            <FullButtonLink to="/admin/applications/">
              Applications
            </FullButtonLink>
            <FullButtonLink to="/admin/waitlist/">Waitlist</FullButtonLink>
            <FullButtonLink to="/admin/users/">Users</FullButtonLink>
            <FullButtonLink to="/admin/jobs/">Jobs</FullButtonLink>
            <FullButtonLink to="/admin/candidates/">Candidates</FullButtonLink>
            <FullButtonLink to="/admin/pitches/">Pitches</FullButtonLink>
            <FullButtonLink to="/admin/payments/">Payments</FullButtonLink>
          </nav>
        )}
        <div className="py-5"> </div>
        <label>
          Show Admin Panel:
          <input
            className="mx-10"
            type="checkbox"
            checked={showAdminPanel}
            onChange={(e) => setShowAdminPanel(e.target.checked)}
          />
        </label>
        <div className="py-5"> </div>
        {isAdmin && <Outlet />}
      </Centered>
    </>
  );
}

export default function AdminRoutes() {
  const DefaultComponent = AdminApplication;
  return (
    <Routes>
      <Route path="/" element={<TabButtons />}>
        <Route path="/applications" element={<DefaultComponent />} />
        <Route path="/waitlist" element={<AdminWaitlist />} />
        <Route path="/users" element={<AdminUsers />} />
        <Route path="/jobs" element={<AdminJobs />} />
        <Route path="/candidates" element={<AdminCandidates />} />
        <Route path="/pitches" element={<AdminPitches />} />
        <Route path="/payments" element={<AdminPayments />} />
        <Route path="" element={<Navigate to="./applications" replace />} />
        <Route path="*" element={<Navigate to="./applications" replace />} />
      </Route>
    </Routes>
  );
}
