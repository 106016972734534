import React from "react";
import StepCards from "components/StepCard";

const PitchMeProcess = () => {
  return (
    <StepCards
      title="HOW IT WORKS?"
      description="3 steps to connect with investors through PitchMe:"
      stepsData={stepsData}
    />
  );
};

const stepsData = [
  {
    step: "01",
    title: "PITCH ME",
    description: "Orally pitch this AI for 30 seconds and answer questions.",
  },
  {
    step: "02",
    title: "DELEGATE",
    description:
      "PitchMe AI voice will pitch your startup to investors and answer questions.",
  },
  {
    step: "03",
    title: "INVESTORS",
    description: "Investors will send you invitations to connect.",
  },
];

export default PitchMeProcess;
