import { useState, useRef, useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import useApi from "../../utils/Api";
import { FullButton } from "../DesignComponents";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useRecordRTC } from "../../utils/AudioRecordingUtil";

// https://www.assemblyai.com/docs/guides/real-time-streaming-transcription#closing-and-status-codes
const useToken = () => {
  const { apiGetAssemblyToken } = useApi();
  const {
    data: token,
    error,
    isLoading,
  } = useQuery("assemblyai_token", apiGetAssemblyToken, {
    refetchOnWindowFocus: false,
  });

  if (isLoading || error) {
    return null;
  }

  return token;
};

// --------------------------------------------------------
// --------------------------------------------------------
// --------------------------------------------------------

const useSocket = (url, onMessageCallback, onOpenCallback = () => {}) => {
  const { sendJsonMessage, readyState } = useWebSocket(url, {
    onMessage: onMessageCallback,
    onOpen: onOpenCallback,
  });

  // Send audio as base64
  const sendAudio = useCallback(
    (blob) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64data = reader.result;
        sendJsonMessage({ audio_data: base64data.split("base64,")[1] });
      };
      reader.readAsDataURL(blob);
    },
    [sendJsonMessage]
  );

  // Send audio as base64
  const sendAsJson = useCallback(
    (key, value) => {
      sendJsonMessage({ key: value });
    },
    [sendJsonMessage]
  );

  return {
    sendAsJson,
    sendAudio,
    readyState,
  };
};

// --------------------------------------------------------
// --------------------------------------------------------
// --------------------------------------------------------
// language_code https://airtable.com/appA9Dihb1Bq18Sr7/shr53TWU5reXkAmt2/tblf7O4cffFndmsCH?backgroundColor=green
// English, en
// Australian English, en_au
// British English, en_uk
// US English, en_us
// Spanish, es
// French, fr
// German, de
// Italian, it
// Portuguese, pt
// Dutch, nl
// Hindi, hi
// Japanese, ja
// Chinese, zh
// Finnish, fi
// Korean, ko
// Polish, pl
// Russian, ru
// Turkish, tr
// Ukrainian, uk
// Vietnamese, vi

const useAssemblyAI = (onNewTranscriptCallback, onAssemblyAIReady) => {
  const token = useToken();

  // https://www.assemblyai.com/docs/api-reference/transcript#create-a-transcript
  // filter_profanity=false
  const url =
    token &&
    `wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`;
  // `wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}&language_code=en-US&dual_channel=false`;

  const onNewTranscript = async (messageEvent) => {
    const res = JSON.parse(messageEvent.data);
    await onNewTranscriptCallback(res);
  };

  const assemblyAI = useSocket(url, onNewTranscript, onAssemblyAIReady);

  assemblyAI.terminateSession = () => {
    assemblyAI.sendAsJson("terminate_session", true);
  };

  assemblyAI.isReady = () => {
    return assemblyAI.readyState === ReadyState.OPEN;
  };

  return assemblyAI;
};

// --------------------------------------------------------
// --------------------------------------------------------
// --------------------------------------------------------
function countWords(sentence) {
  const words = sentence.split(" ");
  const filteredWords = words.filter((word) => word.trim() !== "");
  return filteredWords.length;
}

function GetEndTime(transcript) {
  if (transcript.words.length > 0) {
    return transcript.words[transcript.words.length - 1].end;
  } else {
    return transcript.audio_end;
  }
}

export default function useSpeechToTextAssemblyAI({
  onLiveTranscript = (text) => {},
  onBeforeSpeaked = async () => {},
  onSpeaked = async () => {},
}) {
  // const [isRecording, setIsRecording] = useState(false);
  const [fullMessage, setFullMessage] = useState("");
  const bufferedTranscript = useRef({});
  const lastFinalTranscriptEndTime = useRef(0);
  const lastPartialTranscriptEndTime = useRef(0);
  const previousPartialMessageID = useRef(0);

  const onNewDataFromAudioRecorder = (blob) => {
    // if (assemblyAI.isReady() && !window.AISpeaking) {
    if (assemblyAI.isReady()) {
      assemblyAI.sendAudio(blob);
    }
  };

  const { startRecording, stopRecording, isRecording, hasMicPermission } =
    useRecordRTC(onNewDataFromAudioRecorder);

  function partialMessageID(sentence, now = false) {
    // at 5 words, 15 words and 30 words we invoke callBeforeSpeak
    const words_count = countWords(sentence);
    if (now && words_count < 10) {
      return words_count;
    }
    if (words_count >= 30) {
      return 30;
    } else if (words_count >= 20) {
      return 20;
    } else if (words_count >= 10) {
      return 10;
    } else if (words_count >= 2) {
      return 2;
    }
    return 0;
  }
  // called when we received only a part of the sentence
  // We invoke onBeforeSpeaked at 5 words, 15 words and 30 words
  const onPartialMessage = async (bufferedTranscript, now = false) => {
    const currentPartialMessageID = partialMessageID(bufferedTranscript, now);
    if (currentPartialMessageID != previousPartialMessageID.current) {
      previousPartialMessageID.current = currentPartialMessageID;
      console.log("recordAndTranscribe onBeforeSpeaked", bufferedTranscript);
      await onBeforeSpeaked(bufferedTranscript);
      // setPartialMessage(bufferedTranscript);
    }
  };

  const addToBufferedTranscript = (transcript) => {
    bufferedTranscript.current[transcript.audio_start] = transcript.text;
  };

  const getBufferedTranscript = () => {
    let msg = "";
    const keys = Object.keys(bufferedTranscript.current).sort((a, b) => a - b);
    for (const key of keys) {
      if (bufferedTranscript.current[key]) {
        msg += ` ${bufferedTranscript.current[key]}`;
      }
    }
    return msg;
  };

  function resetState() {
    setFullMessage("");
    bufferedTranscript.current = {};
    lastFinalTranscriptEndTime.current = 0;
    lastPartialTranscriptEndTime.current = 0;
    onLiveTranscript(getBufferedTranscript());
  }

  const onTranscriptFromAssemblyAI = async (transcript) => {
    onLiveTranscript(getBufferedTranscript());
    if (window.AISpeaking) {
      return; // skip transcript while the AI is speaking
    }
    if (transcript.text !== "") {
      Object.assign(window, { userSpeaking: true });
      addToBufferedTranscript(transcript);
    } else {
      Object.assign(window, { userSpeaking: false });
    }

    if (transcript.message_type === "PartialTranscript") {
      if (transcript.text !== "") {
        lastPartialTranscriptEndTime.current = GetEndTime(transcript);
        await onPartialMessage(getBufferedTranscript());
      } else {
        if (
          lastPartialTranscriptEndTime.current > 0 &&
          lastFinalTranscriptEndTime.current > 0
        ) {
          const silenceDuration = Math.min(
            transcript.audio_end - lastFinalTranscriptEndTime.current,
            transcript.audio_end - lastPartialTranscriptEndTime.current
          );
          const nWords = countWords(fullMessage);
          if (
            (silenceDuration > 1500 && 25 < nWords) ||
            (silenceDuration > 1500 && 15 < nWords && nWords <= 25) ||
            (silenceDuration > 2200 && 3 < nWords && nWords <= 15) ||
            (silenceDuration > 2200 && 0 < nWords && nWords <= 3)
          ) {
            // setIsRecording(false);
            // stopRecording();
            console.log(
              "silienceDuration",
              silenceDuration,
              "recordAndTranscribe onSpeaked",
              getBufferedTranscript()
            );
            await onSpeaked(getBufferedTranscript());
            resetState();
            // Object.assign(window, { AISpeaking: true });
          } else if (silenceDuration > 500) {
            await onPartialMessage(getBufferedTranscript(), true);
          }
          // else if (silenceDuration > 500) { // send this with "complete sentence" generation
          //   await onBeforeSpeaked(fullMessage); // on fail speak should start a thread.
          // }
          // nbrSilenceRef.current += 1;
        }
      }
    } else if (transcript.message_type === "FinalTranscript") {
      // await onBeforeSpeaked(getBufferedTranscript());
      const newFullMessage = fullMessage + " " + transcript.text;
      setFullMessage(newFullMessage);
      lastFinalTranscriptEndTime.current = GetEndTime(transcript);
      const silenceDuration =
        transcript.audio_end - lastFinalTranscriptEndTime.current;
      console.log(
        "FinalTranscript newFullMessage: ",
        newFullMessage,
        silenceDuration
      );
    }
  };

  const onAssemblyAIReady = () => {
    // startRecording();
    // setIsRecording(true);
  };

  const assemblyAI = useAssemblyAI(
    onTranscriptFromAssemblyAI,
    onAssemblyAIReady
  );

  const doStartRecording = () => {
    if (assemblyAI.isReady() === false) {
      console.log("AssemblyAI websocket is not ready");
      return;
    }
    if (!isRecording) {
      startRecording();
      // setIsRecording(true);
    }
  };

  const doStopRecording = () => {
    if (isRecording) {
      stopRecording();
      // setIsRecording(false);
    }
  };

  const toggleRecordState = () => {
    if (isRecording) {
      doStopRecording();
    } else {
      doStartRecording();
    }
  };

  return {
    startRecording: doStartRecording,
    stopRecording: doStopRecording,
    toggleRecordState,
    isRecording,
    hasMicPermission,
  };
  // <div className="text-orange-300">Partial ref: {partialRefMessage}</div>
  // <div className="text-orange-600">Partial: {partialMessage}</div>
  // return (
  //   <div>
  //     <FullButton id="button" onClick={toggleRecordState}>
  //       {isRecording ? "Stop" : "Record"}
  //     </FullButton>
  //     <div className="text-green-600">Full: {fullMessage}</div>
  //   </div>
  // );
}
