import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { twJoin } from "tailwind-merge";
import XS from "components/XS";
import React, { useCallback, useState } from "react";
import {
  DesignedLink,
  GoBack,
  Input,
  TextArea,
} from "components/DesignComponents";

import { FullButtonLink } from "components/DesignComponents";
import useApi from "../../utils/Api";
import { HandleHTTPErrors2 } from "components/ErrorMessages";
import { formatDayMonth } from "utils/DateUtils";
import DiscussionHistory from "./DiscussionHistory";
import { CopyToClipboardButton, EditButton } from "components/IconButtons";
import useReferrerApi from "./ReferrerApi";
import { useBaseURL } from "utils/NetworkUtils";
import ConfirmedEmail from "features/ConfirmedEmail";
import { useGameContext } from "debate/GameContext";

function TitleTD({ children }) {
  return (
    <td className="font-bold s-text-xl p-2 border border-dark text-dark bg-light">
      {children}
    </td>
  );
}

function CellTD({ children, className = "" }) {
  return <td className={twJoin("p-2 border", className)}>{children}</td>;
}

export function IfConnectedReferrerLinkJob({ job_uuid }) {
  const { isConnected } = useGameContext();
  return (
    <>
      {isConnected ? (
        <ReferrerLinkJob job_uuid={job_uuid} />
      ) : (
        <div className="text-red-600 font-bold">
          Please login to get your referrer the job link.
        </div>
      )}
    </>
  );
}

function ReferrerLinkJob({ job_uuid }) {
  const { useApiGetCode } = useReferrerApi();
  const requestsState = useApiGetCode();
  const baseURL = useBaseURL();
  const referral_code = requestsState.data?.referral_code;
  const link = `${baseURL}/apps/ai-recruiter/candidate/view-job/${job_uuid}?rc=${referral_code}`;
  return (
    <HandleHTTPErrors2 {...requestsState}>
      {referral_code ? (
        <>
          <div className="italic text-green-600">
            This is your referral link for this job.
          </div>
          <div className="flex flex-row-2">
            <DesignedLink to={link}>{link}</DesignedLink>
            <CopyToClipboardButton text={link} />
          </div>
        </>
      ) : (
        <div className="text-red-600 font-bold">
          Go in "My stats" and click "Enable Referrer", and come back here to
          get your referral link.
        </div>
      )}
    </HandleHTTPErrors2>
  );
}

// export function GenericViewJob({ job }) {
//   console.log("GenericViewJob", job.email);
//   return (
//     <div className="pb-4">
//       <table className="table-auto">
//         <tbody>
//           <tr>
//             <TitleTD>Job title</TitleTD>
//             <CellTD className="font-bold text-center s-text-xl">
//               {job.job_title}
//             </CellTD>
//           </tr>
//           <tr>
//             <TitleTD>Location</TitleTD>
//             <CellTD>
//               <span className="font-bold">Country:</span> {job.country}
//               <br />
//               <span className="font-bold">City:</span> {job.city}
//               {job.email && (
//                 <div>
//                   <span className="font-bold">Email:</span> {job.email}
//                 </div>
//               )}
//             </CellTD>
//           </tr>
//           <tr>
//             <TitleTD>Remote</TitleTD>
//             <CellTD>{job.remote}</CellTD>
//           </tr>
//           <tr>
//             <TitleTD>Description</TitleTD>
//             <CellTD>
//               <pre className="whitespace-pre-wrap overflow-x-auto ">
//                 {job.job_description}
//               </pre>
//             </CellTD>
//           </tr>
//           <tr>
//             <TitleTD>Job link</TitleTD>
//             <CellTD className="p-4 flex flew-row gap-4">
//               <div>
//                 <div className="italic text-green-600">
//                   Copy this link on your website, LinkedIn, Indeed and all
//                   online job post!
//                 </div>
//                 <DesignedLink to={job.view_job_url}>
//                   {job.view_job_url}
//                 </DesignedLink>
//               </div>
//               <CopyToClipboardButton text={job?.view_job_url} />
//             </CellTD>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export function CompanyViewAJob() {
//   const { job_uuid } = useParams();
//   const { apiAIRecruiterViewJob } = useApi();
//   const requestsState = useQuery(
//     ["company-view-job", job_uuid],
//     async () => {
//       return await apiAIRecruiterViewJob(job_uuid);
//     },
//     {
//       staleTime: 50000, // 50 seconds
//       retry: false,
//       refetchOnWindowFocus: false,
//       enabled: !!job_uuid, // The query will not run until job_uuid is truthy
//     }
//   );
//
//   return (
//     <HandleHTTPErrors2 {...requestsState}>
//       <GoBack />
//       <GenericViewJob job={requestsState.data} />
//     </HandleHTTPErrors2>
//   );
// }

function Editable({
  initialValue,
  label,
  editCallback,
  inputType,
  allowEdit = true,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const onStartEdit = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  const onEditValidated = useCallback(
    (newValue) => {
      console.log("onEditValidated", newValue);
      setIsEditing(false);
      editCallback(newValue);
    },
    [setIsEditing]
  );

  return (
    <div>
      {isEditing && allowEdit ? (
        inputType === "textarea" ? (
          <TextArea
            label={label}
            placeholder={label}
            value={value}
            onChange={setValue}
            onBlur={onEditValidated}
          />
        ) : (
          <Input
            label={label}
            placeholder={label}
            value={value}
            onChange={setValue}
            onBlur={onEditValidated}
          />
        )
      ) : (
        <div className="flex">
          <div className="flex-grow">{initialValue}</div>
          {allowEdit && (
            <div className="w-24">
              <EditButton onClick={onStartEdit} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function ViewAJob({
  isCandidateView = false,
  isCompanyView = false,
  isReferrerView = false,
}) {
  const { job_uuid } = useParams();
  const { apiAIRecruiterViewJob, apiAIRecruiterEditJob } = useApi();
  const requestsState = useQuery(
    ["candidate-view-job", job_uuid],
    async () => {
      return await apiAIRecruiterViewJob(job_uuid);
    },
    {
      staleTime: 50000, // 50 seconds
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!job_uuid, // The query will not run until job_uuid is truthy
    }
  );

  const job = requestsState.data;
  const job_title = requestsState.data?.job_title;
  const job_description = requestsState.data?.job_description;
  const refreshCallback = requestsState.refetch;

  const editTitle = useCallback(
    async (newTitle) => {
      console.log("editTitle", job_uuid, job_description, newTitle);
      try {
        await apiAIRecruiterEditJob(job_uuid, job_description, newTitle);
      } catch (e) {
        console.log("editTitle error", e);
      }
      requestsState.refetch();
    },
    [job_uuid, job_description, refreshCallback]
  );

  const editDescription = useCallback(
    async (newDescription) => {
      await apiAIRecruiterEditJob(job_uuid, newDescription, job_title);
      requestsState.refetch();
    },
    [job_uuid, job_title, refreshCallback]
  );

  return (
    <HandleHTTPErrors2 {...requestsState}>
      <GoBack />
      {job && (
        <div className="flex flex-col pb-4">
          <table className="table-auto flex-grow">
            <tbody>
              <tr>
                <CellTD className="font-bold text-center s-text-xl p-4">
                  <Editable
                    label="Job title"
                    initialValue={job_title}
                    editCallback={editTitle}
                    inputType={"input"}
                    allowEdit={isCompanyView}
                  />
                </CellTD>
              </tr>

              <tr>
                <CellTD className="grid grid-cols-2 justify-items-stretch p-4">
                  <XS k="Posted date">{formatDayMonth(job.created_at)}</XS>
                  <XS k="Max salary">{job.max_salary}</XS>
                  <XS k="Country">{job.country}</XS>{" "}
                  <XS k="City">{job.city}</XS>
                  <XS k="Remote">{job.remote}</XS>
                  <XS k="Contract">{job.contract_type}</XS>
                  <XS k="Duration">{job.discussion_duration}</XS>
                  {job.email && (
                    <XS k="Email">
                      <ConfirmedEmail
                        email={job.email}
                        mail_confirmed={job.mail_confirmed}
                      />
                    </XS>
                  )}
                  {job.phone_number && <XS k="Phone">{job.phone_number}</XS>}
                  {job.referral_code && <XS k="RC">{job.referral_code}</XS>}
                  {job.referral_code && (
                    <XS k="Referral code">{job.referral_code}</XS>
                  )}
                </CellTD>
              </tr>
              <tr>
                <CellTD>
                  <pre className="whitespace-pre-wrap overflow-x-auto p-4">
                    <Editable
                      label="Job Description"
                      initialValue={job.job_description}
                      editCallback={editDescription}
                      inputType={"textarea"}
                      allowEdit={isCompanyView}
                    />
                  </pre>
                </CellTD>
              </tr>

              {isReferrerView && (
                <tr>
                  <CellTD className="p-4 flex flew-row gap-4">
                    <div>
                      <IfConnectedReferrerLinkJob job_uuid={job_uuid} />
                    </div>
                  </CellTD>
                </tr>
              )}

              {isCompanyView && (
                <tr>
                  <CellTD className="p-4 flex flew-row gap-4">
                    <div>
                      <div className="italic text-green-600">
                        Copy this link on your website, LinkedIn, Indeed and all
                        online job post!
                      </div>
                      <DesignedLink to={job.view_job_url}>
                        {job.view_job_url}
                      </DesignedLink>
                    </div>
                    <CopyToClipboardButton text={job?.view_job_url} />
                  </CellTD>
                </tr>
              )}

              {job.discussion_history && (
                <tr>
                  <CellTD className="p-4 flex flew-row gap-4">
                    <DiscussionHistory job={job} />
                  </CellTD>
                </tr>
              )}
            </tbody>
          </table>
          {isCandidateView && !job.already_applied && (
            <div className="flex justify-center">
              <FullButtonLink to={`../do-job-interview-phone/${job_uuid}`}>
                Start interview now
              </FullButtonLink>
            </div>
          )}
        </div>
      )}
    </HandleHTTPErrors2>
  );
}
