import { useRef, useState, useEffect } from "react";
import { useStopwatch } from "react-timer-hook";
import moment from "moment";
// https://www.npmjs.com/package/react-moment

export function formatDayMonth(date) {
  return moment(date, "D MMM YYYY [at] HH:mm").format("D MMM");
}

// Custom hook to track elapsed time
export function useElapsedTime() {
  const startTimeRef = useRef(Date.now());
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const secondsElapsed = (Date.now() - startTimeRef.current) / 1000;
      setElapsedTime(secondsElapsed);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Calculate minutes and seconds from elapsedTime
  const minutes = Math.floor(elapsedTime / 60);
  const seconds = Math.floor(elapsedTime % 60);
  const formattedSeconds = seconds.toString().padStart(2, "0");

  // The hook returns minutes and seconds
  return { minutes, seconds: formattedSeconds };
}

// https://www.npmjs.com/package/react-timer-hook
export function useMyStopWatch() {
  const { totalSeconds, seconds, minutes, hours, days } = useStopwatch({
    autoStart: true,
  });

  const totalMinutes = Math.floor(totalSeconds / 60);
  const formatedSeconds = seconds.toString().padStart(2, "0");
  // const formatedMinutes = minutes.toString().padStart(2, "0");
  const display = (
    <div style={{ fontSize: "30px" }}>
      {hours && (
        <>
          <span>{hours}</span>:
        </>
      )}
      <span>{minutes}</span>:<span>{formatedSeconds}</span>
    </div>
  );
  return { totalMinutes, display };
}
